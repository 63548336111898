let nameofthefile = "Meetingbackground.";
let tempCanvasId = "canvasqrid";

export function downloadMeetBackAsBase64() {
    var b64Start = "data:image/svg+xml;base64,";
    var image64 = b64Start;
    return image64;
}

export async function downloadMeetBackAsIconPreview(
    height: number,
    width: number
) {
    var image64 = downloadMeetBackAsBase64();
    const image = document.createElement("img");
    image.src = image64;

    var canvasElement = document.createElement("canvas");
    canvasElement.id = tempCanvasId;
    canvasElement.width = width;
    canvasElement.height = height;
    let context = canvasElement.getContext("2d");

    let hadLoaded = false;
    image.onload = function () {
        context?.drawImage(image, 0, 0, width, height);
        hadLoaded = true;
    };
    while (!hadLoaded) await timeout(50);
    return canvasElement.toDataURL("image/" + "jpeg");
}
function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
}

export function downloadMeetBackFromBase64(
    image64: string,
    imageType: string,
    width: number,
    height: number
) {
    const image = document.createElement("img");
    image.src = image64;
    image.width = width;
    image.height = height;
    var canvasElement = document.createElement("canvas");
    canvasElement.id = tempCanvasId;
    canvasElement.width = width;
    canvasElement.height = height;
    let context = canvasElement.getContext("2d");

    image.onload = function () {
        context?.drawImage(image, 0, 0, width, height);
        downLoad(image64, canvasElement, imageType, width, height);
    };
}

function downLoad(
    imagesrc: string,
    canvasElement: HTMLElement,
    type: string,
    width: number,
    height: number
) {
    switch (type.toLowerCase()) {
        default:
            downloadQrCodeImage(canvasElement, type);
            break;
    }
}
function downloadQrCodeImage(canvasElement: HTMLElement, imageType: string) {
    const canvas = canvasElement as unknown as HTMLCanvasElement;
    const anchor = document.createElement("a");
    anchor.href = canvas.toDataURL("image/" + imageType);
    anchor.download = nameofthefile + imageType;
    anchor.click();
}