import {
    DataGrid,
    getGridDateOperators,
    GridColDef,
    GridCsvExportMenuItem,
    GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { showErrorMessage } from "../../Notifications/Message";
import { getStatusWorkFlow } from "../Template/TemplateWorkFlow";
import { GetTemplateGridDateFormat } from "../../Utils/DateDisplay";
import { useTranslation } from "react-i18next";
import React from "react";
import { getMeetingBackdetailsList, getMeetingBackgDetailsById, GetMeetingBackgListByUser, getMeetingTemplateById } from "../../Services/MeetingTemplate";
import { MeetingTemplateProps } from "../../Props/MeetingTemplateProps";
import StatusChangeGeneratedMeetBack from "../../Notifications/StatusChangeGeneratedMeetBack";
import MeetBackDownload from "../QrCodeUtils/DownloadMeetBack";

export default function AdminMeetingBackList({ }): JSX.Element {
    const [items, setItems] = useState<MeetingTemplateProps[]>([]);
    const { t } = useTranslation();
    const [responseQr, setResponseQr] =
        useState<MeetingTemplateProps>({});
    const [pageSize, setPageSize] = useState<number>(5);

    useEffect(() => fetchQrCodes(), []);

    function fetchQrCodes() {
        getMeetingBackdetailsList()
            .then(function (response: any) {
              
                setItems(response.data);
            })
            .catch(function (error: any) {
                showErrorMessage(t("Failedtogetmeetingbackgroundlist"));
            });
    }
    const handleQRDisplay = (QRCode: any) => {
        getMeetingBackgDetailsById(QRCode.MeetingId)
            .then(function (response: any) {
                setResponseQr(response.data);
            })
            .catch(function (error: any) { });
    };
    const columns: GridColDef[] = [

        {
            field: "MeetingName",
            headerName: `${t("TemplateName")}`,
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "ActiveStatus",
            headerName: `${t("ActiveStatus")}`,
            headerAlign: "left",
            align: "left",
            flex: 0.5,
            valueGetter: (params) => getStatusWorkFlow(params.value),
        },
        {
            field: "CreatedBy",
            headerName: `${t("CreatedBy")}`,
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "CreatedDate",
            headerName: `${t("Processedon")}`,
            type: "date",
            headerAlign: "left",
            align: "left",
            flex: 1,
            valueFormatter: (params) =>
                moment(params?.value).format(GetTemplateGridDateFormat()),
            filterOperators: getGridDateOperators().filter(
                    (operator) => operator.value !== 'before' && operator.value !== 'after',
                ), 
        },
        {
            field: "ModifiedBy",
            headerName: `${t("ModifiedBy")}`,
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "ModifiedDate",
            headerName: `${t("ModifiedDate")}`,
            type: "date",
            headerAlign: "left",
            align: "left",
            flex: 1,
            valueFormatter: (params) =>
                params?.value != null
                    ? moment(params?.value).format(GetTemplateGridDateFormat())
                    : "",
            filterOperators: getGridDateOperators().filter(
                        (operator) => operator.value !== 'before' && operator.value !== 'after',
                    ), 
        },
        {
            field: "MBDetailsID",
            headerName: `${t("Action")}`,
            sortable: false,
            filterable: false,
            headerAlign: "left",
            flex: 1,
            align: "left",
            renderCell: (row: any) => {
                return (
                    <StatusChangeGeneratedMeetBack
                        QrMeetBack={row.row}
                        fetchQrCodes={fetchQrCodes}
                    />
                );
            },
        },
        {
            field: "mm",
            headerName: `${t("DownloadQRlist")}`,
            width: 100,
            headerAlign: "left",
            align: "left",
            sortable: false,
            filterable: false,
            flex: 1, 
            renderCell: (row: any) => {
                return (
                    <span
                        onClick={() => {
                            handleQRDisplay(row.row);
                        }}
                    >
                        <MeetBackDownload
                            MeetingId={row.row.MBDetailsID}
                            status={row.row.ActiveStatus}
                        />
                    </span>
                );
            },
        },
    ];

    const GridToolbarExport = ({ csvOptions }: any) => (
        <GridToolbarExportContainer
            sx={{ textTransform: "capitalize" }}
            className="fontfamily"
        >
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 5,
        page: 0,
    });
    return (
        <>
            <DataGrid
                className="vvvvsss"
                getRowId={(row) => row.MBDetailsID}
                rows={items}
                pageSizeOptions={[5, 10, 15]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 5, page: 0 },
                    },
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                columns={columns}
                components={{
                    Toolbar: GridToolbarExport,
                }}
            />
        </>
    );
}
