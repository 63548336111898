import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { StatusWorkFlow } from "./../Template/TemplateWorkFlow";
import { getMeetingBackgDetailsById } from "../../Services/MeetingTemplate";
import { CircularProgress } from "@mui/material";
import { downloadMeetBackFromBase64 } from "../../Utils/MeetBackDownload";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle className="fontfamily downloadqrpopup" {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
type Props = {
    MeetingId: any;
    status: any;
};

export default function MeetBackDownload({ MeetingId, status }: Props) {
    const [open, setOpen] = React.useState(false);
    const [loadings, setLoadings] = React.useState(false)
    const [responseQr, setResponseQr] = React.useState<any>("" as any);
    const { t, i18n } = useTranslation();
    const handleClickOpen = () => {
        setOpen(true);
        setLoadings(true)
        getMeetingBackgDetailsById(MeetingId).then(function (response) {
            setResponseQr(response.data);
            setLoadings(false)
        }).catch(function (response) {
            
        })
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Button
                sx={{ textTransform: "none" }}
                variant="outlined"
                onClick={() => {
                    handleClickOpen();
                }}
                className="fontfamily buttonsize"
                disabled={status === StatusWorkFlow.Expired}
            >
                {t("DownloadQRlist")}
            </Button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth={true}
                open={open}
                sx={{
                    fontFamily:
                        "'Open Sans', Calibri, Arial, Helvetica, sans-serif, sans-serif",
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    {t("DownloadMeetBackQRImage")}
                </BootstrapDialogTitle>
                <div className='gridoverflow'>
                    <hr></hr>
                    {loadings ? <CircularProgress
                        value={50}
                        sx={{
                            position: "relative",

                            left: "20%",
                            height: "100%",
                            marginTop: "20%",
                            marginBottom: "20%",
                            marginLeft: "30%",
                        }}
                        disableShrink
                    /> : <> <DialogContent className="downloadimg"
                        dividers
                        sx={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            src={`data:image/jpeg;base64,${responseQr?.MeetingBgWithQr}`} width="70%"
                        />
                    </DialogContent>
                        <DialogActions>
                            <Button
                                className="fontfamily"
                                sx={{
                                    color: "white",
                                    backgroundColor: "#0076A8",
                                    textTransform: "none",
                                }}
                                variant="contained"
                                onClick={() => {
                                    if (`data:image/jpeg;base64,${responseQr?.MeetingBgWithQr}` != undefined)
                                        downloadMeetBackFromBase64(
                                            `data:image/jpeg;base64,${responseQr?.MeetingBgWithQr}`,
                                            "jpeg",
                                            1920,
                                            1080
                                        );
                                }}
                            >
                                {t("DownloadQRlist")}
                            </Button>
                            <Button
                                className="fontfamily"
                                variant="outlined"
                                sx={{ textTransform: "none" }}
                                autoFocus
                                onClick={handleClose}
                            >
                                {t("Close")}
                            </Button>
                        </DialogActions></>}


                </div>
            </BootstrapDialog>
        </div>
    );
}
