import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import {
    showErrorMessage,
    showSuccessMessage,
} from "../../Notifications/Message";
import { DefaultVCardProps } from "../../Props/VCardProps";
import { getVcardListById, updateVcard } from "../../Services/Vcard";
import "./../../App.css";
import CloseIcon from "@mui/icons-material/Close";
import QrCodeDisplayable from "./../QrCodeUtils/QrCodeDisplayable";
import { downloadQrCodecAsBase64, getTempDivId } from "../../Utils/DownLoad";
import { DefaultQRCodeDetailsDefaultProps } from "../../Props/QRDeatailsProps";
import { updateQrDetail } from "../../Services/QrDetail";
import { getQrTemplateById } from "../../Services/QrTemplate";
import { DefaultTemplateProps } from "../../Props/TemplateProps";
import { useTranslation } from "react-i18next";
import { constructVCard, constructBCard } from "../../Utils/VcfGenerator";
import { getLoggedInUser } from "../Authentication/Auth";
import { updateQr } from "../../Services/Qrcode";
export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
    setChange: React.Dispatch<React.SetStateAction<boolean>>;
}
function BootstrapDialogTitle(props: DialogTitleProps) {
    const { t, i18n } = useTranslation();
    const { children, onClose, setChange, ...other } = props;
    return (
        <DialogTitle className="fontfamily headerViewvcard" {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label={`${t("Close")}`}
                    onClick={() => {
                        onClose(), setChange(false);
                    }}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
type Props = {
    QrCodeId: any;
    rowData: any;
    fetchQrCodes: () => void;
    templateId: any;
};
function VieworEditVcard({
    QrCodeId,
    fetchQrCodes,
    rowData,
    templateId,
}: Props) {
    const [vcard, setVcard] = useState<any>();
    const [LastName, setLastName] = useState("");
    const [UploadImage, setUploadImage] = useState("" as any);
    const [FirstName, setFirstName] = useState("" as any);
    const [MobileNo, setMobileNo] = useState("" as any);
    const [EmailId, setEmailId] = useState("" as any);
    const [Designation, setDesignation] = useState("" as any);
    const [AlternateMobileNo, setAlternateMobileNo] = useState("" as any);
    const [DeloitteEntity, setDeloitteEntity] = useState("" as any);
    const [QrcodeImage, setQrcodeImage] = useState(" " as any);
    const {
        handleSubmit,
        control, reset,
        formState: { errors, isValid, isDirty },
    } = useForm();

    const [op, setOp] = useState(false);
    const [change, setChange] = useState(false);
    const [templateProps, setTemplateProps] = useState<DefaultTemplateProps>({});
    const [expired, setExpired] = useState(false);
    const { t, i18n } = useTranslation();
    const [Userentity, setUserentity] = useState(DeloitteEntity);
    const [isEntityEmpty, setIsEntityEmpty] = useState(false);

    const handleClickOpen1 = async (QrCodeId: string, templateId: any) => {
        getVcardListById(QrCodeId).then(function (response: any) {
            setLastName(response.data.LastName);
            setMobileNo(response.data.MobileNo.replace("+",""));
            setFirstName(response.data.FirstName);
            setUploadImage(response.data.UploadImage);
            setEmailId(response.data.EmailId);
            setAlternateMobileNo(response.data.AlternateMobileNo.replace("+",""));
            setDeloitteEntity(response.data.DeloitteEntity);
            setUserentity(response.data.DeloitteEntity);
            setDesignation(response.data.Designation);
            setQrcodeImage(response.data.QRImage);
            setVcard(response.data)

        }).catch(function (response) {

        });
        getQrTemplateById(templateId).then(function (response) {
            setTemplateProps(response.data);
        });
    };
    const [QRDetails] = useState<DefaultQRCodeDetailsDefaultProps>({
        QRCodeId: "",
        QRName: "",
        QRImage: "",
    });

    function constructQR(props: DefaultVCardProps) {
        return constructVCard(
            FirstName,
            LastName,
            Designation,
            DeloitteEntity,
            EmailId,
            MobileNo,
            AlternateMobileNo,
            undefined,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            ""
        );
    }
    const handleExpired = () => {
        if (rowData == 5) {
            setExpired(true);
        } else {
            setExpired(false);
        }
    };
    const addOpen = () => {
        setOp(true);
    };

    const addClose = () => {
        setOp(false);
    };

    const handleChange = (data: any) => {
        setAlternateMobileNo(data.AlternateMobileNo)
        setMobileNo(data.MobileNo)
        setChange(false);
        setDeloitteEntity(data.DeloitteEntity)
        setFirstName(data.FirstName);
        setLastName(data.LastName);
        setDesignation(data.Designation);        

        vcard.QRCodeId = QrCodeId
        vcard.LastName = LastName;
        vcard.FirstName = data.FirstName;
        vcard.Designation = data.Designation;
        vcard.DeloitteEntity = data.DeloitteEntity;       
        vcard.MobileNo = data.MobileNo;
        vcard.AlternateMobileNo = data.AlternateMobileNo;
        

        const _QrCodes = {
            QrCodeId: QrCodeId,
            modifiedBy: getLoggedInUser()
        }
        updateQr(_QrCodes)
            .then(function (response: any) {
                if (response.status == 200) {
                    updateVcard(vcard).then(function (response) {
                        if (response.status == 200) {
                            QRDetails.QRCodeId = QrCodeId;
                            QRDetails.QRName = vcard.FirstName;
                            QRDetails.QRImage = downloadQrCodecAsBase64(getTempDivId());
                            updateQrDetail(QRDetails)
                                .then(function (response) {
                                    addClose();
                                    showSuccessMessage(`${t("UpdateSuccessfully")}`)
                                    fetchQrCodes();
                                })
                                .catch(function (repsonse) {
                                    showErrorMessage(`${t("FailedtoeditContactcarddetails")}`);
                                });
                        }
                    });
                }
            })
            .catch(function (error: any) {
                showErrorMessage(t("Failedtoupdate"));
            });
    };

    function convertFile(files: FileList | null) {
        if (files) {
            const fileRef = files[0] || "";
            const fileType: string = fileRef.type || "";
            const reader = new FileReader();
            reader.readAsBinaryString(fileRef);
            reader.onload = (ev: any) => {
                setUploadImage(`data:${fileType};base64,${btoa(ev.target.result)}`);
            };
        }
    }
    const handleReset = () => {
        setUploadImage("");
    };
    return (
        <>
            <Button
                className="fontresize buttonsize fontfamily"
                variant="outlined"
                sx={{ textTransform: "none", }}
                onClick={() => {
                    addOpen(), handleExpired(), handleClickOpen1(QrCodeId, templateId);
                }}
            >
                {t("ViewDetails")}
            </Button>
            <Dialog
                className="fontfamily gridoverflow"
                open={op}
                onClose={() => {
                    addClose(), setChange(false);
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={addClose}
                    setChange={setChange}
                >
                    {t("ContactCarddetails")}
                </BootstrapDialogTitle>
                <hr></hr>
                <div className="gridoverflow">
                    {change ? (
                        <DialogTitle>

                            <div className="qrcodeinViewVcard">
                                <QrCodeDisplayable
                                    TargetUrl={constructQR(vcard)}
                                    RenderType={"svg"}
                                    level={"L"}
                                    marginRequired={true}
                                    DivId={getTempDivId()}
                                    ForeColor={templateProps.ForeColor}
                                    BackgroundColor={templateProps.BackgroundColor}
                                    Height={160}
                                    Width={160}
                                    Logo={templateProps.Logo}
                                    CreatedBy={templateProps.CreatedBy}
                                    TemplateName={templateProps.TemplateName}
                                    TemplateId={""}
                                    CreatedDate={new Date()}
                                    ModifiedBy={""}
                                    ModifiedDate={new Date()}
                                    ActiveStatus={""}
                                    TemplatePreview={""}
                                    Shape={templateProps.Shape}
                                />
                            </div>
                        </DialogTitle>
                    ) : (
                        <DialogTitle className="dialogTitltboxvvcard">

                            <div className="viewqrcodeineditvcard">
                                <QrCodeDisplayable
                                    TargetUrl={constructQR(vcard)}
                                    RenderType={"svg"}
                                    level={"L"}
                                    marginRequired={true}
                                    DivId={getTempDivId()}
                                    ForeColor={templateProps.ForeColor}
                                    BackgroundColor={templateProps.BackgroundColor}
                                    Height={160}
                                    Width={160}
                                    Logo={templateProps.Logo}
                                    CreatedBy={templateProps.CreatedBy}
                                    TemplateName={templateProps.TemplateName}
                                    TemplateId={""}
                                    CreatedDate={new Date()}
                                    ModifiedBy={""}
                                    ModifiedDate={new Date()}
                                    ActiveStatus={""}
                                    TemplatePreview={""}
                                    Shape={templateProps.Shape}
                                />
                            </div>
                        </DialogTitle>
                    )}

                    <DialogContent>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid
                                container
                                spacing={{ xs: 3, md: 3 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                {change ? (
                                    <>
                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("FirstName")} *
                                            </Typography>
                                            <Typography>
                                                <Controller
                                                    name="FirstName"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    defaultValue={FirstName}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            className="textareavcard"
                                                            placeholder={`${t("Enter here")}`}
                                                            value={FirstName}
                                                            onChange={(e) => {
                                                                setFirstName(e.target.value);
                                                                onChange(e.target.value)
                                                                //setIsEntityEmpty(e.target.value.trim() === "");
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Typography>
                                            {errors?.FirstName?.type == "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("LastName")} *
                                            </Typography>
                                            <Typography>
                                                <Controller
                                                    name="LastName"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    defaultValue={LastName}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            className="textareavcard"
                                                            placeholder={`${t("Enter here")}`}
                                                            value={LastName}
                                                            onChange={(e) => {
                                                                setLastName(e.target.value);
                                                                onChange(e.target.value)
                                                                //setIsEntityEmpty(e.target.value.trim() === "");
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Typography>
                                            {errors?.LastName?.type == "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("JobTitle")} *
                                            </Typography>
                                            <Typography>
                                                <Controller
                                                    name="Designation"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    defaultValue={Designation}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            className="textareavcard"
                                                            placeholder={`${t("Enter here")}`}
                                                            value={Designation}
                                                            onChange={(e) => {
                                                                setDesignation(e.target.value);
                                                                onChange(e.target.value)
                                                                //setIsEntityEmpty(e.target.value.trim() === "");
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Typography>
                                            {errors?.Designation?.type == "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("DeloitteEntity")} *
                                            </Typography>
                                            <Typography>
                                                <Controller
                                                    name="DeloitteEntity"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    defaultValue={Userentity}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            className="textareavcard"
                                                            placeholder={`${t("Enter here")}`}
                                                            value={Userentity}
                                                            onChange={(e) => {
                                                                setUserentity(e.target.value);
                                                                onChange(e.target.value);
                                                                //setIsEntityEmpty(e.target.value.trim() === "");
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Typography>
                                            {errors?.DeloitteEntity?.type == "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                        </Grid>
                                        {/* <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("EmailId")} *
                                            </Typography>
                                            <Typography>
                                                <Controller
                                                    name="EmailId"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    defaultValue={EmailId}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            className="textareavcard"
                                                            placeholder={`${t("Enter here")}`}
                                                            value={EmailId}
                                                            onChange={(e) => {
                                                                setEmailId(e.target.value);
                                                                onChange(e.target.value)
                                                                //setIsEntityEmpty(e.target.value.trim() === "");
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Typography>
                                            {errors?.EmailId?.type == "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                        </Grid> */}


                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("MobileNo")} *
                                            </Typography>
                                            <Controller
                                                name="MobileNo"
                                                control={control}
                                                rules={{ required: true, minLength: 7 }}
                                                defaultValue={MobileNo}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <PhoneInput
                                                        placeholder={`${t("MobileNoPlaceholder")}`}
                                                        inputProps={{
                                                            name: "MobileNo",


                                                        }}
                                                        dropdownStyle={{
                                                            position: "absolute",
                                                            top: "28px",
                                                            left: "0px",
                                                            height: "110px",
                                                        }}
                                                        enableAreaCodes
                                                        autoFormat
                                                        autocompleteSearch
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors?.MobileNo?.type == "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                            {errors?.MobileNo?.type == "minLength" && (
                                                <span className="alertColor">
                                                    {t("PhoneNumberisnotValid")}
                                                </span>
                                            )}
                                        </Grid>

                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("OfficeNo")}
                                            </Typography>
                                            <Controller
                                                rules={{ minLength: 7 }}
                                                name="AlternateMobileNo"
                                                defaultValue={AlternateMobileNo}
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <PhoneInput
                                                        placeholder={`${t("MobileNoPlaceholder")}`}
                                                        inputProps={{
                                                            name: "AlternateMobileNo",
                                                            default: { AlternateMobileNo }
                                                        }}

                                                        dropdownStyle={{
                                                            position: "absolute",
                                                            top: "-128px",
                                                            left: "0px",
                                                            height: "110px",
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors?.AlternateMobileNo?.type == "minLength" && (
                                                <span className="alertColor">
                                                    {t("PhoneNumberisnotValid")}
                                                </span>
                                            )}
                                        </Grid>
                                        
                                        
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("FirstName")}
                                            </Typography>
                                            <Typography
                                                className="fontfamily"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {FirstName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("LastName")}
                                            </Typography>
                                            <Typography
                                                className="fontfamily"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {LastName}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("JobTitle")}
                                            </Typography>
                                            <Typography
                                                className="fontfamily"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {Designation}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("DeloitteEntity")}
                                            </Typography>
                                            <Typography
                                                className="fontfamily"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {DeloitteEntity}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("EmailId")}
                                            </Typography>
                                            <Typography
                                                className="fontfamily"
                                                variant="caption"
                                                display="block"
                                                sx={{
                                                    color: "#0076A8",
                                                    whiteSspace: "nowrap",
                                                    overflow: "hidden",
                                                    width: "120px",
                                                    textOverflow: "ellipsis",
                                                }}
                                                gutterBottom
                                            >
                                                {EmailId}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("MobileNo")}
                                            </Typography>

                                            <Typography
                                                className="fontfamily"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {MobileNo}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("OfficeNo")}
                                            </Typography>
                                            <Typography
                                                className="fontfamily"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {AlternateMobileNo}
                                            </Typography>
                                        </Grid>                                     
                                        
                                        
                                    </>
                                )}
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {expired ? (
                            <Button
                                className="fontfamily"
                                variant="outlined"
                                sx={{ textTransform: "none" }}
                                onClick={() => {
                                    addClose(), handleExpired();
                                }}
                            >
                                {t("Close")}
                            </Button>
                        ) : (
                            <>
                                {" "}
                                {change ? (
                                    <div className="buttonGrupviewvcard">
                                        <Button
                                            className="fontfamily"
                                            variant="outlined"
                                            sx={{ textTransform: "none" }}
                                            type="submit"
                                            onClick={handleSubmit(handleChange)}
                                        >
                                            {t("SaveBotton")}
                                        </Button>
                                        <Button
                                            className="fontfamily"
                                            variant="outlined"
                                            sx={{ textTransform: "none" }}
                                            onClick={() => {
                                                addClose(), setChange(false), reset();
                                            }}
                                        >
                                            {t("Close")}
                                        </Button>
                                    </div>
                                ) : (
                                    <>
                                        {/* <Button
                                            className="fontfamily"
                                            variant="outlined"
                                            sx={{ textTransform: "none" }}
                                            onClick={() => {
                                                setChange(true), handleExpired();
                                            }}
                                            style={{ marginRight: "10px" }}
                                        >
                                            {t("EditDetails")}
                                        </Button> */}
                                        <Button
                                            className="fontfamily"
                                            variant="outlined"
                                            sx={{ textTransform: "none" }}
                                            onClick={() => {
                                                addClose(), setChange(false), reset();
                                            }}
                                        >
                                            {t("Close")}{" "}
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
}

export default VieworEditVcard;
