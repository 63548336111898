const AZURE_AAD_GRAPH_ENDPOINT = "https://graph.microsoft.com/V1.0/me"
const AZURE_AAD_GRAPH_ENDPOINT_GROUP = "https://graph.microsoft.com/V1.0/me/memberOf/microsoft.graph.group?$count=true&$orderby=displayName&$filter=";
const AZURE_AAD_GRAPH_ENDPOINT_PHOTO = "https://graph.microsoft.com/V1.0/me/photo/$value"
const AZURE_AAD_GRAPH_ENDPOINT_DEPARTMENT = "https://graph.microsoft.com/V1.0/me/department"
const MICROSOFT_LOGIN_ENDPOINT = "https://login.microsoftonline.com/"

var AZURE_AAD_REDIRECT_URI = 'https://qrcodedev.azurewebsites.net/'
var AZURE_AAD_REDIRECT_URI = 'http://localhost:3000'
var AZURE_AAD_CLIENT_ID = '59debd24-3e11-432c-8f3a-76b42be68a89'
var AZURE_AAD_AUTHORITY = '8d9ab560-c57e-4301-87f0-8c562f2587fc'
var AZURE_AAD_QRADMIN_GROUP = 'QRCodeAdministrators'
var CRYPT_KEY = '121314'
var DISCLAIMERURL = process.env.REACT_APP_DISCLAIMERURL
var COOKIEURL = process.env.REACT_APP_COOKIEURL
var isCookieEncryptionRequired = true;
var AZURE_SCAN_URL = 'https://qrscandev.azurewebsites.net/'
//var AZURE_SCAN_URL = 'https://localhost:7125/'

if (process.env.REACT_APP_ENV == "Staging") {
    AZURE_AAD_REDIRECT_URI = process.env.REACT_APP_AAD_REDIRECT_URI_STG
    AZURE_AAD_CLIENT_ID = process.env.REACT_APP_AAD_CLIENT_ID_STG
    AZURE_AAD_AUTHORITY = process.env.REACT_APP_AAD_AUTHORITY_STG
    AZURE_AAD_QRADMIN_GROUP = process.env.REACT_APP_AAD_QRADMIN_GROUP_STG
    CRYPT_KEY = process.env.REACT_APP_CRYPT_KEY_STG
    AZURE_SCAN_URL = process.env.REACT_APP_AZURE_SCAN_URL_STG
}
else if (process.env.REACT_APP_ENV == "Integration") {
    AZURE_AAD_REDIRECT_URI = process.env.REACT_APP_AAD_REDIRECT_URI_INT
    AZURE_AAD_CLIENT_ID = process.env.REACT_APP_AAD_CLIENT_ID_INT
    AZURE_AAD_AUTHORITY = process.env.REACT_APP_AAD_AUTHORITY_INT
    AZURE_AAD_QRADMIN_GROUP = process.env.REACT_APP_AAD_QRADMIN_GROUP_INT
    CRYPT_KEY = process.env.REACT_APP_CRYPT_KEY_INT
    AZURE_SCAN_URL = process.env.REACT_APP_AZURE_SCAN_URL_INT

}
else if (process.env.REACT_APP_ENV == "Production") {
    AZURE_AAD_REDIRECT_URI = process.env.REACT_APP_AAD_REDIRECT_URI_PRD
    AZURE_AAD_CLIENT_ID = process.env.REACT_APP_AAD_CLIENT_ID_PRD
    AZURE_AAD_AUTHORITY = process.env.REACT_APP_AAD_AUTHORITY_PRD
    AZURE_AAD_QRADMIN_GROUP = process.env.REACT_APP_AAD_QRADMIN_GROUP_PRD
    CRYPT_KEY = process.env.REACT_APP_CRYPT_KEY_PRD
    AZURE_SCAN_URL = process.env.REACT_APP_AZURE_SCAN_URL_PRD

}
else if (process.env.REACT_APP_ENV == "Development") {
    AZURE_AAD_REDIRECT_URI = process.env.REACT_APP_AAD_REDIRECT_URI_DEV
    AZURE_AAD_CLIENT_ID = process.env.REACT_APP_AAD_CLIENT_ID_DEV
    AZURE_AAD_AUTHORITY = process.env.REACT_APP_AAD_AUTHORITY_DEV
    AZURE_AAD_QRADMIN_GROUP = process.env.REACT_APP_AAD_QRADMIN_GROUP_DEV
    CRYPT_KEY = process.env.REACT_APP_CRYPT_KEY_DEV
    isCookieEncryptionRequired = false;
    AZURE_SCAN_URL = process.env.REACT_APP_AZURE_SCAN_URL_DEV
}

export const msalConfig = {
    auth: {
        clientId: AZURE_AAD_CLIENT_ID,
        authority: MICROSOFT_LOGIN_ENDPOINT + AZURE_AAD_AUTHORITY,
        redirectUri: AZURE_AAD_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    }
};
export function IsCookieEncrypt() {
    return isCookieEncryptionRequired;
}
export function GetDisclaimerURL() {
    return DISCLAIMERURL;
}
export function GetCookie() {
    return COOKIEURL;
}

export function GetGraphUrlforGroup() {
    return AZURE_AAD_GRAPH_ENDPOINT_GROUP;
}
export function GetGraphUrl() {
    return AZURE_AAD_GRAPH_ENDPOINT;
}

export function GetGraphUrlForPhoto() {
    return AZURE_AAD_GRAPH_ENDPOINT_PHOTO;
}
export function GetGraphUrlForDepartment() {
    return AZURE_AAD_GRAPH_ENDPOINT_DEPARTMENT;
}

export function AdminGroupName() {
    return AZURE_AAD_QRADMIN_GROUP
};

export function GetCrpytKey() {
    return CRYPT_KEY
};

export function GetDynamicScannerUrl() {
    return AZURE_SCAN_URL
};