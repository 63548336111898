import axios from "axios";
import { msalConfig } from "../authConfig";

let cancelToken: any;

export const getQrCodeListById = () => {
    return axios.get(
        process.env.REACT_APP_API_ENDPOINT + "/api/QRCode/GetQRCodeListById"
    );
};

export const addQrCode = (data: any) => {
    return axios.post(
        process.env.REACT_APP_API_ENDPOINT + "/api/QRCode/AddQRCodes",
        data
    );
};

export const updateQr = (data: any) => {
    return axios.put(
        process.env.REACT_APP_API_ENDPOINT + "/api/QRCode/UpdateQRCode",
        data
    );
};

export const qrCodeDisplay = async (data: any) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()

    var result = await axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/QRCode/QRCodeDisplay", data, { cancelToken: cancelToken.token })

    return result;
}
export const getQrCodeListByUser = (

    UserName: any
) => {
    return axios.get(
        process.env.REACT_APP_API_ENDPOINT + "/api/QRCode/GetQRCodeListByUser",
        {
            params: {
                UserName: UserName,
                AppId: msalConfig.auth.clientId
            },
        }
    );
};

export const CheckDuplicateBcard = (data: any) => {
    return axios.post(
        process.env.REACT_APP_API_ENDPOINT + "/api/QRCode/CheckQrCodeBcard",
        data
    );
};