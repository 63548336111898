// Quick Tour Page.tsx
import React from "react";
 
const QuickTour: React.FC = () => {
  return (
    <div>
      <h2>Reference page for QR code Generator </h2>
      <p>This is a new page with a link to the Guidance Documentation on QR code .</p>
      <a
        href="https://amedeloitte.sharepoint.com/sites/DeloitteCollaborationCentral/Jobaid/Forms/AllItems.aspx?id=%2Fsites%2FDeloitteCollaborationCentral%2FJobaid%2FQR%5Fcode%5FQRG%5Fv215%2Epdf&parent=%2Fsites%2FDeloitteCollaborationCentral%2FJobaid"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to access the Documentation
      </a>
    </div>
  );
}
 
export default QuickTour;
 