import react from "react";
import { Box, Typography } from "@mui/material";
import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import { useTranslation } from "react-i18next";

function GuideLines() {
    const { t, i18n } = useTranslation();
    return (
        <Box className="addTemplate-left-Side" sx={{ marginTop: '1px' }}>
            <h3 className="addTemplate-guideLinesTextHeader" style={{ marginTop: '10px' }}>{t("QRGuidelines")}</h3>
            <p className="addTemplate-GuidelinesText">{t("GuidelinesText")}</p>
            <ArrowRightSharpIcon
                className="addTemplate-arrow"
                style={{ top: "148px" }}
            />
            <h3
                className="addTemplate-guideLinesTextHeader"
                style={{ top: "148px", left: "25px" }}
            >
                {t("GuideLine1")}
            </h3>
            <p
                className="addTemplate-GuidelinesText "
                style={{ top: "170px", width: "320px" }}
            >
                {t("GuideLine1Text")}
            </p>
            <ArrowRightSharpIcon
                className="addTemplate-arrow"
                sx={{ top: "220px" }}
            />
            <h3
                className="addTemplate-guideLinesTextHeader"
                style={{ top: "220px", left: "25px" }}
            >
                {t("GuideLine2")}
            </h3>
            <p
                className="addTemplate-GuidelinesText "
                style={{ top: "240px", width: "320px" }}
            >
                {t("GuideLine2Text")}
            </p>

            <ArrowRightSharpIcon
                className="addTemplate-arrow"
                sx={{ top: "320px" }}
            />
            <h3
                className="addTemplate-guideLinesTextHeader"
                style={{ top: "320px", left: "25px" }}
            >
                {t("GuideLine3")}
            </h3>
            <p
                className="addTemplate-GuidelinesText "
                style={{ top: "340px", width: "320px" }}
            >
                {t("GuideLine3Text")}
            </p>
        </Box>
    );
}
export default GuideLines;