import { useState } from "react";
import { MeetingTemplateProps } from "../../Props/MeetingTemplateProps";
import MeetingTemplateSlider from "./UserMeetingBg/MeetingTemplateSlider";
import UserMeetingCreation from "./UserMeetingBg/UserMeetingCreation";


type Props = {
    value: number;
    handleChange: (event: React.SyntheticEvent, newValue: number) => void;
};
export const MeetingBackground = ({value, handleChange }: Props) => {

    const [meetingTemplteId, setMeetingTemplateId] = useState<string>()
    const [meetingPreview, setMeetingPreview] = useState<MeetingTemplateProps>({})
    const [loader, setLoader] = useState(true);
    const changeTemplateID = (value: string) => {
        setMeetingTemplateId(value);
    };
    return (
        <div>
            <MeetingTemplateSlider changeTemplateID={changeTemplateID} meetingPreview={meetingPreview} setMeetingPreview={setMeetingPreview} setLoader={setLoader} />
            <UserMeetingCreation meetingPreview={meetingPreview} loader={loader} value={value} handleChange={handleChange } />
        </div>
    );
};