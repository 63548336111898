import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MeetingBackgroundUserList from "./MeetingBackgroundUserList";
function MeetingBackgroundList() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Box>
                <h2 className="myqrxtext1" style={{ fontSize: "25px" }}>
                    {t("Generatedmeetingbackgroundlists")}
                </h2>
                <p className="myqrtext2" style={{ fontSize: "15px" }}>
                    {t("GeneratedmeetingbackgroundText")}
                </p>
                <MeetingBackgroundUserList/>
            </Box>

        </>
    );
}
export default MeetingBackgroundList;
