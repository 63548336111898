import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function showSuccessMessage(msg: string) {
    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
    });
}
export function showErrorMessage(msg: string) {
    toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
    });
}
export function showWarningMessage(msg: string) {
    toast.warning(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
    });
}
export function showInfoMessage(msg: string) {
    toast.info(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
    });
}