import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { getUrlQrCodeListById, updateUrlQrCode } from "../../Services/Url";
import {
    showErrorMessage,
    showSuccessMessage,
} from "../../Notifications/Message";
import { DefaultURLProps } from "../../Props/Url";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DefaultQRCodeDetailsDefaultProps } from "../../Props/QRDeatailsProps";
import QrCodeDisplayable from "./../QrCodeUtils/QrCodeDisplayable";
import { downloadQrCodecAsBase64, getTempDivId } from "../../Utils/DownLoad";
import { TemplateProps } from "../../Props/TemplateProps";
import { getQrTemplateById } from "../../Services/QrTemplate";
import { updateQrDetail } from "../../Services/QrDetail";
import { updateQr } from "../../Services/Qrcode";
import { getLoggedInUser } from "../Authentication/Auth";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(1),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}
function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle className="dailoginViewUrldetails fontfamily" {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 1,
                        top: 12,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

type Props = {
    handleClickOpenDia: any;
    QRCodeId: any;
    fetchQrCodes: any;
    status: any;
    templateId: any;
};
export default function CustomizedDialogs({
    handleClickOpenDia,
    QRCodeId,
    fetchQrCodes,
    status,
    templateId,
}: Props) {
    const [open, setOpen] = React.useState(false);
    const [editopen, seteditOpen] = React.useState(false);
    const [requestData, setRequestData] = React.useState<any>({});
    const [expired, setExpired] = React.useState(false);
    const [templateProps, setTemplateProps] = React.useState<TemplateProps>({});
    const [title, setTitle] = React.useState<any>("")
    const [url, setUrl] = React.useState<any>("")
    const { t, i18n } = useTranslation();
    const CHARACTER_LIMIT = 100;
    const CHARACTER_LIMIT1 = 200;

    const {
        register, reset,
        handleSubmit,
        formState: { errors },
    } = useForm<DefaultURLProps>();
    const [responseQr, setResponseQr] =
        React.useState<DefaultQRCodeDetailsDefaultProps>({});

    const handleClickOpen = () => {
        handleClickOpenDia(QRCodeId, (response: any) => {
            setOpen(true);
            seteditOpen(false);
            setRequestData(response);
        });

        getUrlQrCodeListById(QRCodeId)
            .then(function (response: any) {
                setResponseQr(response.data);
              
                setTitle(response.data.Title)
                setUrl(response.data.Url)

            })
            .catch(function (error: any) { });
    };
    const handleTemplate = (templateId: any) => {
        getQrTemplateById(templateId).then(function (response) {
            setTemplateProps(response.data);
        });
    };

    const [QRDetails] = React.useState<DefaultQRCodeDetailsDefaultProps>({
        QRCodeId: "",
        QRName: "",
        QRImage: "",
    });

    const handleExpired = () => {
        if (status == 5) {
            setExpired(true);
        } else {
            setExpired(false);
        }
    };
    const handleClose = () => {
        setOpen(false);
        seteditOpen(false);
    };
    const EditQrCodesdetails = (data: any) => {
        setTitle(data.Title)
        setUrl(data.Url)
        const url = {
            Title: requestData?.Title,
            Url: requestData?.Url,
            QRCodeId: QRCodeId,
        };
        const _QrCodes = {
            QrCodeId: QRCodeId,
            modifiedBy: getLoggedInUser()
        }
        updateQr(_QrCodes)
            .then(function (response: any) {
                if (response.status == 200) {
                    updateUrlQrCode(url).then(function (response: any) {
                        response.data;
                        if (response.status == 200) {
                            QRDetails.QRCodeId = url.QRCodeId;
                            QRDetails.QRName = url.Title;
                            QRDetails.QRImage = downloadQrCodecAsBase64(getTempDivId());
                        }
                        updateQrDetail(QRDetails)
                            .then(function (response) {
                                setOpen(false);
                                showSuccessMessage(`${t("UpdateSuccessfully")}`);
                                fetchQrCodes();
                            })
                            .catch(function (response) {
                                showErrorMessage(`${t("FailedtoeditURLdetails")}`);
                            });
                    });
                }
            })
            .catch(function (error: any) {
                showErrorMessage(t("Failedtoupdate"));
            });
    };


    const handleeditChanges = (event: any) => {
        const { name, value } = event.target;
        setRequestData((prevState: any) => {
            return {
                ...prevState,
                [name]: value.trim(),
            };
        });
    };
    return (
        <div>
            <Button
                className="fontresize buttonsize fontfamily"
                sx={{
                    textTransform: "none",
                }}
                variant="outlined"
                onClick={() => {
                    handleClickOpen(), handleExpired(), handleTemplate(templateId);
                }}
            >
                {t("ViewDetails")}
            </Button>
            <div>
                {open && (
                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        sx={{
                            fontFamily:
                                "'Open Sans', Calibri, Arial, Helvetica, sans-serif, sans-serif",
                        }}
                    >
                        <BootstrapDialogTitle
                            id="customized-dialog-title"
                            onClose={handleClose}
                        >
                            {t("Urldetails")}
                        </BootstrapDialogTitle>
                        <hr></hr>
                        <div className="gridoverflow">
                            <div className="QrcodeinViewurl">
                                <QrCodeDisplayable
                                    TargetUrl={requestData.Url}
                                    RenderType={"svg"}
                                    level={"H"}
                                    marginRequired={true}
                                    DivId={getTempDivId()}
                                    ForeColor={templateProps.ForeColor}
                                    BackgroundColor={templateProps.BackgroundColor}
                                    Height={200}
                                    Width={200}
                                    Logo={templateProps.Logo}
                                    CreatedBy={templateProps.CreatedBy}
                                    TemplateName={templateProps.TemplateName}
                                    TemplateId={""}
                                    CreatedDate={new Date()}
                                    ModifiedBy={""}
                                    ModifiedDate={new Date()}
                                    ActiveStatus={""}
                                    TemplatePreview={""}
                                    Shape={templateProps.Shape}
                                />
                            </div>
                            <br></br>

                            <DialogContent dividers>
                                {editopen ? (
                                    <>
                                        <div className="fontfamily">
                                            <label className="labelinurldisplay">
                                                {t("TitleofURL")}*
                                            </label>
                                            <input
                                                defaultValue={title}
                                                className="form-control labelinurldisplay"
                                                maxLength={100}
                                                {...register("Title", {
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                })}
                                                name="Title"

                                                onChange={handleeditChanges}
                                                type="text"
                                                placeholder={`${t("Enteryourtitlehere")}`}
                                            ></input>
                                            {errors?.Title?.type === "required" ? (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            ) : (
                                                <p style={{ fontSize: "10px" }}>
                                                    {requestData.Title?.length}/{CHARACTER_LIMIT}
                                                </p>
                                            )}
                                            {errors?.Title?.type === "pattern" && (
                                                <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                            )}
                                        </div>
                                        <br></br>
                                        <div
                                            className="form-group"
                                            style={{
                                                fontFamily:
                                                    "'Open Sans', Calibri, Arial, Helvetica, sans-serif",
                                            }}
                                        >
                                            <label className="labelinurldisplay">
                                                {t("PasteUrl/Linkhere")}*
                                            </label>
                                            <input
                                                defaultValue={url}
                                                className="form-control"
                                                maxLength={200}
                                                {...register("Url", {
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                })}
                                                name="Url"

                                                onChange={handleeditChanges}
                                                type="text"
                                                placeholder={`${t("YourLinkHere")}`}
                                            ></input>
                                            {errors?.Url?.type === "required" ? (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            ) : (
                                                <p style={{ fontSize: "10px" }}>
                                                    {requestData.Url?.length}/{CHARACTER_LIMIT1}
                                                </p>
                                            )}

                                            {errors?.Url?.type === "pattern" && (
                                                <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <Typography
                                            sx={{
                                                display: "flex",
                                                float: "left",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            <b className="labelinurldisplay"> {t("urlTitle")}</b>{" "}
                                            &nbsp;&nbsp;
                                            <span className="textinurldisplay" style={{ color: "#0076A8" }}>
                                                {" "}
                                                {requestData?.Title}
                                            </span>
                                        </Typography>
                                        <br></br>
                                        <Typography
                                            sx={{
                                                display: "flex",
                                                float: "left",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            <b className="labelinurldisplay">{t("Url")}</b> &nbsp;&nbsp;
                                            <span className="textinurldisplay" style={{ color: "#0076A8" }}>
                                                {" "}
                                                {requestData?.Url}
                                            </span>
                                        </Typography>
                                    </>
                                )}
                            </DialogContent>
                            <DialogActions>
                                {expired ? (
                                    <Button
                                        className="fontfamily"
                                        sx={{ textTransform: "none" }}
                                        onClick={() => {
                                            handleClose(), handleExpired();
                                        }}
                                    >
                                        {t("Close")}
                                    </Button>
                                ) : (
                                    <>
                                        {" "}
                                        {editopen ? (
                                            <>
                                                <Button
                                                    className="fontfamily"
                                                    variant="outlined"
                                                    sx={{ textTransform: "none" }}
                                                    onClick={handleSubmit(EditQrCodesdetails)}
                                                >
                                                    {t("SaveBotton")}
                                                </Button>
                                                <Button
                                                    className="fontfamily"
                                                    variant="outlined"
                                                    sx={{ textTransform: "none" }}
                                                    onClick={() => { handleClose(), reset() }}
                                                >
                                                    {t("Close")}
                                                </Button>{" "}
                                            </>
                                        ) : (
                                            <>
                                                {/* <Button
                                                    variant="outlined"
                                                    className="fontfamily"
                                                    sx={{ textTransform: "none" }}
                                                    onClick={() => {
                                                        seteditOpen(true), handleExpired();
                                                    }}
                                                >
                                                    {t("EditDetails")}
                                                </Button> */}
                                                <Button
                                                    className="fontfamily"
                                                    variant="outlined"
                                                    sx={{ textTransform: "none" }}
                                                    onClick={handleClose}
                                                >
                                                    {" "}
                                                    {t("Close")}
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}
                            </DialogActions>
                        </div>
                    </BootstrapDialog>
                )}
            </div>
        </div>
    );
}
