import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./Internationalisation/i18n";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import MsalWrapper from "././Components/MsalWrapper";
import { QueryClient, QueryClientProvider } from "react-query";



const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <MsalWrapper>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </MsalWrapper>
  </React.StrictMode>
);

reportWebVitals();
