import React, { useEffect, useState } from "react";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import QrCodeTemplates from "./QrCodeTemplates";
import GeneratedQrs from "./GeneratedQrs";
import { useTranslation } from "react-i18next";
import {
    IsAdministrator,
    IsMeetingBackGroundRequired,
} from "../Authentication/Auth";
import AdminMeetingBg from "./AdminMeetingBg";
import GeneratedMeetingBackglist from "./GeneratedMeetingBackglist";

export default function QrCodeAdmin() {
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const { t, i18n } = useTranslation();
    const [isNormalUser, setisNormalUser] = useState<boolean>();
    useEffect(() => {
        setisNormalUser(!IsAdministrator());
    });
    return (
        <>
            <div className="topnava">
                <h4> {t("AdminHeading")}</h4>
            </div>
            <div className="leftcolumn">
                <div className="card">
                    <h4>{t("AdminAccess")}</h4>

                    <Tabs
                        value={value}
                        onChange={handleChange}
                        className="centerTabs"
                        orientation="vertical"
                        TabIndicatorProps={{
                            sx: {
                                backgroundColor: "white",
                                height: 3,
                            },
                        }}
                        textColor="primary"
                    >
                        <Tab
                            className="textTabss fontfamily textTabss1"
                            label={t("QRCodeTemplates")}
                            value={0}
                            icon={<AppRegistrationIcon />}
                            iconPosition="start"
                            sx={{ display: 'flex', alignItems: 'left', justifyContent: "left", textTransform: "none" }}
                        />
                        {IsMeetingBackGroundRequired() ? <Tab
                            className="textTabss fontfamily textTabss1"
                            label={t("MeetingTemplate")}
                            value={1}
                            icon={<img className="iconn" src="adminmbg.png" width="10%"  />}
                            iconPosition="start"
                            sx={{ display: 'flex', alignItems: 'left', justifyContent: "left", textTransform: "none" }}
                        /> : null}

                        <Tab
                            className="textTabss fontfamily textTabss2"
                            sx={{ justifyContent: "left", textTransform: "none" }}
                            label={t("GeneratedQRs")}
                            value={2}
                            icon={<img src="GeneratedQr.svg"></img>}
                            iconPosition="start"
                        />
                        {IsMeetingBackGroundRequired() ?<Tab
                            className="textTabss fontfamily textTabss2"
                            sx={{ justifyContent: "left", textTransform: "none" }}
                            label={t("GeneratedMeetingBackground")}
                            value={3}
                            icon={<img src="GeneratedQr.svg"></img>}
                            iconPosition="start"
                        />: null}
                    </Tabs>
                </div>
            </div>
            <div className="rightcolumn">
                <div className="card">
                    {value === 0 && (
                        <div>
                            <QrCodeTemplates />
                        </div>
                    )}
                    {value === 1 && (
                        <div>
                            <AdminMeetingBg />
                        </div>
                    )}
                    {value === 2 && (
                        <div>
                            <GeneratedQrs />
                        </div>
                    )}
                    {value === 3 && (
                        <div>
                            <GeneratedMeetingBackglist />
                        </div>
                    )}
                </div>
            </div>

            
        </>
    );
}
