import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { TemplateProps } from "../Props/TemplateProps";
import { useForm, UseFormHandleSubmit } from "react-hook-form";
import { DefaultURLProps } from "../Props/Url";
import { DefaultQRCodeDetailsDefaultProps } from "../Props/QRDeatailsProps";
import { Box, IconButton, styled, Tab, Tabs } from "@mui/material";
import MyQrCodes from "../Components/User/MyQrCodes";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  CreateNewURLQRCode: () => void;
  handleSubmit: UseFormHandleSubmit<DefaultURLProps>;
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isUrlValid : any;
};
export default function ConfirmPopupurl({
  CreateNewURLQRCode,
  handleSubmit,
  value,
  handleChange,
  open,
  setOpen,
  isUrlValid,
}: Props) {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Button
        className="submiturl fontfamily"
        variant="contained"
        sx={{
          backgroundColor: " #0076A8",
          color: "white",
          textTransform: "none",
        }}
        onClick={handleSubmit(CreateNewURLQRCode)}
        disabled={isUrlValid}
      >
        {t("Submit")}
      </Button>

          <Dialog
              
              maxWidth="md"
              fullWidth={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description" 
              sx={{
                  fontFamily: "'Open Sans', Calibri, Arial, Helvetica, sans-serif", 
        }}
      >
        <DialogTitle>
          <Box
            className="succescComponentstyle-circleIcon"
            sx={{ position: "absolute", top: 5 }}
          >
            {" "}
            <CheckIcon
              className="succescComponentstyle-tick"
              sx={{ fontSize: "50px" }}
            />
          </Box>
          <Box className="successTexturl">{t("Success")}</Box>
              </DialogTitle>
              <div className="gridoverflow">
              <DialogContent sx={{ marginTop: 5, color: "black" }} className='contentpopup'>
          <DialogContentText
            id="alert-dialog-slide-description" className="urlscroll"
            sx={{
              fontFamily: "'Open Sans', Calibri, Arial, Helvetica, sans-serif", 
            }}
          >
            {t("YourQRCodehasbeencreatedsuccessfully")} <br></br>
            {t("PressDonetoviewyourQRcodedetails")}
          </DialogContentText>
        </DialogContent>
        <DialogActions className='buttondone'>
          <Tabs value={value} onChange={handleChange} className="tabs">
            <Tab
              className=" donebutton123"
              label={t("Done")}
              sx={{
                color: "white",
                backgroundColor: " #4BB543",
             
                textTransform: "none",
                fontFamily:
                  "'Open Sans', Calibri, Arial, Helvetica, sans-serif",
              }}
              onClick={() => {
                setOpen(false);
              }}
              value={4}
            />
          </Tabs>
          {value === 4 && (
            <div>
              <MyQrCodes />
            </div>
          )}
                  </DialogActions>
                  </div>
      </Dialog>
    </div>
  );
}
