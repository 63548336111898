import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import QrCodeGeneratorLink from "./QrCodeGeneratorLink";
import QrCodeGeneratorVCard from "./QrCodeGeneratorVCard";
import MyQrCodes from "../User/MyQrCodes";
import { useTranslation } from "react-i18next";
import { MeetingBackground } from "./MeetingBackground";
import QRCodeGeneratorBusinessCard from "./QRCodeGeneratorBusinessCard";
import {
    IsBusinessCardRequired,
    IsMeetingBackGroundRequired,
} from "../Authentication/Auth";
import MeetingBackgroundList from "../User/MeetingBackgroundList";
import PortraitIcon from '@mui/icons-material/Portrait';
import QuickTour from "../User/QuickTour";
export default function QrCodeAdmin() {
    const [value, setValue] = React.useState(0);
    const { t, i18n } = useTranslation();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="topnava">
                <h3> {t("GenerateandDownloadQRcodesfor")}</h3>
            </div>

            <div className="leftcolumn">
                <div className="card">
                    <h4>{t("UserAccess")}</h4>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        orientation="vertical"
                        TabIndicatorProps={{
                            sx: {
                                backgroundColor: "white",
                                height: 3,
                            },
                        }}
                        textColor="primary"
                    >
                        <Tab
                            className="textTabss fontfamily textTabss1"
                            sx={{ justifyContent: "left", textTransform: "none" }}
                            label={t("UrlUser")}
                            value={0}
                            icon={<img src="card.png"></img>}
                            iconPosition="start"
                        />
                        <Tab
                            className="textTabss fontfamily textTabss2"
                            sx={{ justifyContent: "left", textTransform: "none" }}
                            label={t("ContactCard")}
                            value={1}
                            icon={<img src="link.png"></img>}
                            iconPosition="start"
                        />

                        {!IsBusinessCardRequired() ? (
                            ""
                        ) : (
                            <Tab className="fontfamily "
                                sx={{
                                    marginLeft: 0,
                                    justifyContent: "left",
                                    textTransform: "none",
                                }}
                                label={t("BusinessCard")}
                                value={2}
                                icon={<img src="business-card222.png" width="10%" ></img>}
                                iconPosition="start"
                            />
                        )}
                        {!IsMeetingBackGroundRequired() ? (
                            ""
                        ) : (
                            <Tab className="fontfamily "
                                sx={{ justifyContent: "left", textTransform: "none" }}
                                label={t("MeetingBackground")}
                                value={3}
                                icon={<PortraitIcon />}
                                iconPosition="start"
                            />
                        )}

                        <Tab
                            className="fontfamily "
                            label={t("MyQrCodes")}
                            value={4}
                            sx={{ justifyContent: "left", textTransform: "none" }}
                            icon={<img src="GeneratedQr.svg"></img>}
                            iconPosition="start"
                        />
                       {!IsMeetingBackGroundRequired() ? (
                            ""
                        ) : ( <Tab
                                className="fontfamily "
                                label={t("Meetingbackgroundlist")}
                                value={5}
                                sx={{ justifyContent: "left", textTransform: "none" }}
                                icon={<img src="GeneratedQr.svg"></img>}
                                iconPosition="start"
                            />
                        )}
                        <Tab
                            className="textTabss fontfamily textTabss1"
                            sx={{ justifyContent: "left", textTransform: "none" }}
                            label={t("QR Code Guidance")}
                            value={6}
                            icon={<img src="card.png"></img>}
                            iconPosition="start"
                        />
                    </Tabs>
                </div>
            </div>
            <div className="rightcolumn">
                <div className="card">
                    {value === 0 && (
                        <div>
                            <QrCodeGeneratorLink value={value} handleChange={handleChange} />
                        </div>
                    )}
                    {value === 1 && (
                        <div>
                            <QrCodeGeneratorVCard value={value} handleChange={handleChange} />
                        </div>
                    )}
                    {value === 3 && (
                        <div>
                            <MeetingBackground value={value} handleChange={handleChange} />
                        </div>
                    )}

                    {value === 2 && (
                        <div>
                            <QRCodeGeneratorBusinessCard
                                value={value}
                                handleChange={handleChange}
                            />
                        </div>
                    )}
                    {value === 4 && (
                        <div>
                            <MyQrCodes />
                        </div>
                    )}
                    {value === 5 && (
                        <div>
                            <MeetingBackgroundList />
                        </div>
                    )}
                    {value === 6 && (
                        <div>
                            <QuickTour />
                            
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
