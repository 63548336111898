import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import QrCodeListUser from "./QrCodeListUser";

function MyQrCodes() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Box>
                <h2 className="myqrxtext1" style={{ fontSize: "25px" }}>
                    {t("GeneratedQRCodes")}
                </h2>
                <p className="myqrtext2" style={{ fontSize: "15px" }}>
                    {t("GeneratedQRCodesText")}
                </p>
                <QrCodeListUser />
            </Box>

        </>
    );
}
export default MyQrCodes;
