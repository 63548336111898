import QrCodeDisplayable from "../QrCodeUtils/QrCodeDisplayable";
import { DefaultTemplateProps, TemplateProps } from "../../Props/TemplateProps";
import { getTempDivId } from "../../Utils/DownLoad";
import Box from "@mui/material/Box";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DefaultURLProps, URLpreview } from "../../Props/Url";
import { useTranslation } from "react-i18next";
import { getUrlSource } from "../../Services/Url";
type Props = {
  previewData: TemplateProps;
  url: DefaultURLProps;
  source: any;
  setPreviewData: Dispatch<SetStateAction<TemplateProps>>;
  callback: CallbackFunction;
};

type CallbackFunction  = (result: boolean) => void;

const ReviewUrl = ({ previewData, url, source ,callback}: Props) => {
  const [src, setSrc] = useState<URLpreview>({ url: "" });
  const [load, setLoad] = useState(false);
  const [reponse, setReponse] = useState<string>();
  const [template, setTemplate] = useState<DefaultTemplateProps>({
    Height: 150,
    Width: 150,
  });
  
  useEffect(() => {
    src.url = source;
    setLoad(true);
   
    getUrlSource(src)
      .then(function (response) {        
        setReponse(response.data);           
        if(src.url != "" && src.url != null){
          callback(false);
        }

        if(response.data == "" || response.data == null){
          callback(true);
        }
        setLoad(false);
      })
      .catch(function (response) {
        setLoad(false);
        setReponse(
          "<h3>Invalid Url!</h3> <br>If you are using the http: or https: portion of the URL, please include the forward slashes after the colon!"
        );
        callback(true);        
      });
  }, [source]);
  const { t, i18n } = useTranslation();

  return (
      <Box className="reviewUrlBox">
      <h6 style={{ marginLeft: "15px", marginTop: "10px", fontSize: "20px" }}>
        <b>{t("ReviewYourQR")}</b>
      </h6>
      <p
        className="reviewText"
        style={{ marginLeft: "5px", fontSize: "13px", width: "98%" }}
      >
        {t("ReviewofQRcode1")}
        <br></br>
        {t("ReviewofQRcode2")}
      </p>

      <div className="reviewQr">
        <QrCodeDisplayable
          TargetUrl={url.Url}
          RenderType={"svg"}
          level={"H"}
          marginRequired={true}
          DivId={getTempDivId()}
          ForeColor={previewData.ForeColor}
          BackgroundColor={previewData.BackgroundColor}
          Height={template.Height}
          Width={template.Width}
          Logo={previewData.Logo}
          CreatedBy={previewData.CreatedBy}
          TemplateName={previewData.TemplateName}
          TemplateId={""}
          CreatedDate={new Date()}
          ModifiedBy={""}
          ModifiedDate={new Date()}
          ActiveStatus={""}
          TemplatePreview={""}
          Shape={previewData.Shape}
        />
      </div>
      <div 
        style={{
          color: "#0076A8",
                  fontSize: "14px",
                  width: "98%", marginTop: '10px', marginLeft:'10px',
        }}
      >
        <b> {t("PreviewofthecontentoftheQRcode")} </b>
        <br></br>
        <b>{t("QRcodewillredirecttofollowingpage")}</b>
      </div>
          <Box sx={{ textOverflow:"ellipsis"}}>
        <iframe
          className="websiteboxhigh"
          srcDoc={load ? "<h3>Loading....</h3>" : reponse}
                  width="98%"
                  scrolling='true'
          sandbox="allow-scripts"
          height="220"
          referrerPolicy="same-origin"
          style={{  margin: 5 }}
        />
      </Box>

      <div></div>
    </Box>
  );
};

export default ReviewUrl;
