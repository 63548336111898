import * as React from "react";
import Button from "@mui/material/Button";
import { Box, Dialog } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { TemplateProps } from "../../Props/TemplateProps";
import AddTemplate from "./../QrCodeAdmin/Addtemplate/AddTemplate";
import { useTranslation } from "react-i18next";

type Props = {
  fetchTemplates: () => void;
  setItems: React.Dispatch<React.SetStateAction<TemplateProps[]>>;
};
export default function StepperBar({ fetchTemplates, setItems }: Props) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  const { t, i18n } = useTranslation();

  return (
    <div>
      <Button
        variant="contained"
        sx={{
          backgroundColor: " #0076A8",
          color: "white",
          textTransform: "none",
        }}
        onClick={handleClickOpen}
        className="addRight fontfamily"
      >
        <AddCircleOutlineIcon /> {`${t("AddTemplate")}`}
      </Button>

      <Box>
        <Dialog
          open={open}
          maxWidth="lg"
          fullWidth={fullWidth}
          onClose={handleClose}
        >
          <AddTemplate
            setOpen={setOpen}
            fetchTemplates={fetchTemplates}
            setItems={setItems}
          />
        </Dialog>
      </Box>
    </div>
  );
}
