import {
    DataGrid,
    getGridDateOperators,
    GridColDef,
    GridCsvExportMenuItem,
    GridToolbarExportContainer,
} from "@mui/x-data-grid";

import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { getLoggedInUser } from "../Authentication/Auth";
import { getQrCodeListByUser } from "../../Services/Qrcode";
import { showErrorMessage } from "../../Notifications/Message";
import { getStatusWorkFlow } from "../Template/TemplateWorkFlow";
import { GetTemplateGridDateFormat } from "../../Utils/DateDisplay";
import { QRCodeProps } from "../../Props/QRCodeProps";
import { getUrlQrCodeListById } from "../../Services/Url";
import QRDownload from "../QrCodeUtils/DownloadQr";
import { DefaultQRCodeDetailsDefaultProps } from "../../Props/QRDeatailsProps";
import { useTranslation } from "react-i18next";
import React from "react";
import StatusChangeGeneratedQrs from "../../Notifications/StatusChangeGeneratedQrs";

import CustomizedDialogs from "../QrCodeGenerator/ViewDetailsUrl";
import VieworEditBCard from "../QrCodeGenerator/VieworEditBCard";
import VieworEditVcard from "../QrCodeGenerator/VieworEditVcard";

import { DefaultReportProps } from "../../Props/ReportProps";
// import ViewUserVcard from "./ViewUserVcard";
// import ViewUserUrl from "./ViewUserUrl";
// import ViewUserBcard from "./ViewUserBcard";

export default function QrCodesListUser({ }): JSX.Element {
    const [items, setItems] = useState<QRCodeProps[]>([]);
    const [responseData, setResponseData] = useState<QRCodeProps>({});
    const [responseQr, setResponseQr] =
        useState<DefaultQRCodeDetailsDefaultProps>({});
    const { t } = useTranslation();
    const _UserName = getLoggedInUser();

    useEffect(() => fetchQrCodes(), [_UserName]);

    function fetchQrCodes() {
        getQrCodeListByUser(_UserName)
            .then(function (response: any) {
                setItems(response.data);
            })
            .catch(function (error: any) {
                showErrorMessage(t("Failedtogetqrs"));
            });
    }

    const handleClickOpen = (QRCode: any, callback: any, cellVallues: any) => {
        getUrlQrCodeListById(QRCode)
            .then(function (response: any) {
                setResponseData(response.data);
                callback(response.data);
                return response.data;
            })
            .catch(function (error: any) {

            });
    };
    const handleQRDisplay = (QRCode: any) => {
        getUrlQrCodeListById(QRCode.QRCodeId)
            .then(function (response: any) {
                setResponseQr(response.data);
            })
            .catch(function (error: any) { });
    };

    const [modal, setModal] = useState(false);

    const openModal = () => {
        setModal(!modal);
    };

    const columns: GridColDef[] = [
        {
            field: "Title",
            headerName: `${t("NameofQRcode")}`,
            width: 100,
            headerAlign: "left",
            align: "left",
            flex: 1,
        },

        {
            field: "QRType",
            headerName: `${t("TypesofQRCode")}`,
            width: 100,
            headerAlign: "left",
            align: "left",
            flex: 1,
        },

        {
            field: "CreatedDate",
            headerName: `${t("Processedon")}`,
            type: "date",
            width: 100,
            headerAlign: "left",
            align: "left",
            flex: 1,
            valueFormatter: (params) =>
                moment(params?.value).format(GetTemplateGridDateFormat()),
            filterOperators: getGridDateOperators().filter(
                (operator) => operator.value !== 'before' && operator.value !== 'after',
            ),
        },

        {
            field: "ExpiryDate",
            headerName: `${t("Activetill")}`,
            type: "date",
            width: 100,
            headerAlign: "left",
            align: "left",
            flex: 1,
            valueFormatter: (params) =>
                params?.value != null
                    ? moment(params?.value).format(GetTemplateGridDateFormat())
                    : "",
            filterOperators: getGridDateOperators().filter(
                (operator) => operator.value !== 'before' && operator.value !== 'after',
            ),
            renderCell: (cellVallues: any) => {
                if (cellVallues.row.QRType == "Vcard" ||
                    cellVallues.row.QRType == "Business Card") {
                    return "N/A";
                }
            },
        },

        {
            field: "ActiveStatus",
            headerName: `${t("ActiveStatus")}`,
            width: 100,
            headerAlign: "left",
            align: "left",
            flex: 1,
            valueGetter: (params) => getStatusWorkFlow(params.value),
        },

        {
            field: "QrCodeId",
            headerName: `${t("QRcodeDetails")}`,
            width: 230,
            sortable: false,
            filterable: false, flex: 1.5,

            renderCell: (cellVallues: any) => {
                if (cellVallues.row.QRType == "Vcard") {
                    return (
                        <span>
                            <VieworEditVcard
                                QrCodeId={cellVallues?.row?.QRCodeId}
                                fetchQrCodes={fetchQrCodes}
                                rowData={cellVallues.row.ActiveStatus}
                                templateId={cellVallues.row.TemplateId}
                            />
                        </span>
                    );
                } else if (cellVallues.row.QRType == "Business Card") {
                    return (
                        <span>
                            <VieworEditBCard
                                QrCodeId={cellVallues?.row?.QRCodeId}
                                fetchQrCodes={fetchQrCodes}
                                rowData={cellVallues.row.ActiveStatus}
                                templateId={cellVallues.row.TemplateId}
                            />
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            <CustomizedDialogs
                                handleClickOpenDia={handleClickOpen}
                                QRCodeId={cellVallues?.row?.QRCodeId}
                                fetchQrCodes={fetchQrCodes}
                                status={cellVallues.row.ActiveStatus}
                                templateId={cellVallues.row.TemplateId}
                            />
                        </span>
                    );
                }
            },
        },

        {
            field: "QRCodeId",
            headerName: `${t("Action")}`,
            width: 140,
            sortable: false,
            filterable: false,
            headerAlign: "left",
            flex: 1,
            align: "left",

            renderCell: (row: any) => {
                return (
                    <StatusChangeGeneratedQrs
                        QrCodes={row.row}
                        fetchQrCodes={fetchQrCodes}
                    />
                );
            },
        },

        {
            field: "mm",
            headerName: `${t("DownloadQRlist")}`,
            width: 100,
            headerAlign: "left",
            align: "left",
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (row: any) => {
                return (
                    <span
                        onClick={() => {
                            handleQRDisplay(row.row);
                        }}
                    >
                        <QRDownload
                            Qrcodeid={row.row.QRCodeId}
                            download={row.row.QRType}
                            status={row.row.ActiveStatus}
                            templateId={row.row.TemplateId}
                        />
                    </span>
                );
            },
        },
    ];

    const GridToolbarExport = ({ csvOptions }: any) => (
        <GridToolbarExportContainer
            sx={{ textTransform: "capitalize" }}
            className="fontfamily"
        >
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 5,
        page: 0,
    });
    return (
        <>
            <DataGrid
                sx={{ width: "100%" }}
                className="vvvvsss"
                getRowId={(row) => row.QRCodeId}
                rows={items}
                pageSizeOptions={[5, 10, 15]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 5, page: 0 },
                    },
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                columns={columns}
                components={{
                    Toolbar: GridToolbarExport,
                }}
            />
        </>
    );
}
