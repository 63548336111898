import react from "react";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import TemplateComponent from "../../Template/TemplateComponent";
import GuideLines from "./GuideLines";
import { TemplateProps } from "../../../Props/TemplateProps";
import React from "react";
import SuccesTemplate from "../../Settings/SuccesTemplate";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
type Props = {
    setOpen :React.Dispatch<React.SetStateAction<boolean>>;
    fetchTemplates: () => void;
    setItems: React.Dispatch<React.SetStateAction<TemplateProps[]>>;
};
function AddTemplate({ setOpen, fetchTemplates, setItems }: Props) {
    const [show, SetShow] = useState(true);
    const { t, i18n } = useTranslation();
    return (
        <>
            <Typography variant="h5" sx={{ textAlign: "left", m: 2 }} className="fontfamily">
                {t("UploadanewQRtemplate")}
            </Typography>
            <CloseIcon className='closeIconstyelIntemplate' onClick={() => setOpen(false)} />
            <Box className="addTemplateContainer">
                <hr className="horizantal-Line-AddTemplate"></hr>

                {show ? (
                    <>
                        {" "}
                        <TemplateComponent
                            setOpen={setOpen}
                            setShow={SetShow}
                            fetchTemplates={fetchTemplates}
                        />
                        <GuideLines />
                    </>
                ) : (
                        <SuccesTemplate setOpen={setOpen} />
                )}
            </Box>
        </>
    );
}

export default AddTemplate;