
import URLComponent from "./URLComponent";
import ReviewUrl from "./ReviewUrl";
import { useState } from "react";
import { TemplateProps } from "../../Props/TemplateProps";
import { DefaultURLProps } from "../../Props/Url";
import Footbar from "../Headers/Footer";

type Props = {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
};



function QrCodeTemplates({ value, handleChange }: Props) {
  const [previewData, setPreviewData] = useState<TemplateProps>({});
  const [source, setSource] = useState("" as any);
  const [url, seturl] = useState<DefaultURLProps>({
    URLId: -1,
    QRCodeId: "",
    Title: "",
    Url: "",
  });

 // const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleURLChanges = (event: any) => {
    const { name, value } = event.target;
    seturl((prevState: any) => {
      return {
        ...prevState,
        [name]: value.trim(),
      };
    });
  };
  
  type CallbackFunction  = (result: boolean) => void;

  const callback: CallbackFunction = (result: boolean) => {

    // Simulate some logic    
    setIsButtonDisabled(result);    
  
    return result; // Return the result parameter
  };

  return (
    <>
      <div className="rightside">
        <URLComponent
          setSource={setSource}
          setPreviewData={setPreviewData}
          url={url}
          handleURLChanges={handleURLChanges}
          value={value}
          handleChange={handleChange}
          previewData={previewData}
         // isButtonDisabled={isValidUrl}
         isButtonDisabled={isButtonDisabled}
        />
      </div>
      <div className="leftside">
        <ReviewUrl
          previewData={previewData}
          url={url}
          source={source}
          setPreviewData={setPreviewData}
          callback={callback}
        />
      </div>
    </>
  );
}
export default QrCodeTemplates;
