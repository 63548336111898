import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import QrCodesList from "../QrCodeGenerator/QRCodesList";

function QrCodeTemplates() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Box>
        <h2 className="GeneratedQRCtexth1">{t("GeneratedQRCodes")}</h2>
        <p className="GeneratedQRCtextp">{t("GeneratedQRCodesText")}</p>
      </Box>
      <QrCodesList />
    </>
  );
}
export default QrCodeTemplates;
