import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import translationEnglish from "./Translation/English.json";
import translationSpanish from "./Translation/Spanish.json";
import translationFrench from "./Translation/French.json";
import translationPortuguese from "./Translation/Portuguese.json";
import translationGerman from "./Translation/German.json";
import translationItalian from "./Translation/Italian.json";
import translationJapanese from "./Translation/Japanese.json";
import translationChinese_Traditional from "./Translation/Chinese_Traditional.json";
import translationChinese_Simplified from "./Translation/Chinese_Simplified.json";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: translationEnglish,
    },
    es: {
        translation: translationSpanish,
    },
    fr: {
        translation: translationFrench,
    },
    prt: {
        translation: translationPortuguese,
    },
    ger: {
        translation: translationGerman,
    },
    it: {
        translation: translationItalian,
    },
    jp: {
        translation: translationJapanese,
    },
    CHT: {
        translation: translationChinese_Traditional,
    },
    Hans: {
        translation: translationChinese_Simplified,
    },
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next).init(
        {
            resources,
            lng: "en",
            fallbackLng: "en",
            debug: true,
            interpolation: {
                escapeValue: false
            }
        });

export default i18n;