import {
  DataGrid,
  GridColDef,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
    GridFilterOperator,
    getGridDateOperators
} from "@mui/x-data-grid";
import { Dispatch, SetStateAction, useState } from "react";
import { TemplateProps } from "../../Props/TemplateProps";
import moment from "moment";
import {
  getStatusWorkFlow,
  StatusWorkFlow,
} from "../Template/TemplateWorkFlow";
import { GetTemplateGridDateFormat } from "../../Utils/DateDisplay";
import StatusChangeDialogue from "../../Notifications/StatusChangeDialogue";
import { useTranslation } from "react-i18next";
import React from "react";

type Props = {
  items: TemplateProps[];
    fetchTemplate: () => void;
    loading: boolean;
};

export default function TemplateList({
  items,
  fetchTemplate, loading,
}: Props): JSX.Element {
  const { t, i18n } = useTranslation();
  const columns: GridColDef[] = [
    {
      field: "TemplatePreview",
      headerName: `${t("TemplateIcon")}`,
      sortable: false,
      filterable: false,
      headerAlign: "left",
      flex: 1,
      align: "left",
      renderCell: (row: any) => {
        return (
          <img
            src={row.row.TemplatePreview}
            className="imagemobile"
          
          ></img>
        );
      },
    },

    {
      field: "TemplateName",
      headerName: `${t("TemplateName")}`,

      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "Height",
      headerName: `${t("Height")}`,
      flex: 1,
      type: "number",
      headerAlign: "left",
        align: "left",
        width: 70,
       


    },
    {
      field: "Width",
      headerName: `${t("Width")}`,
        type: "number",
        headerAlign: "left",
        align: "left",
        flex: 1,
        width:70,
        
    },

    {
      field: "ActiveStatus",
      headerName: `${t("Status")}`,
      headerAlign: "left",
      align: "left",
      flex: 1,

      valueGetter: (params) => getStatusWorkFlow(params.value),
    },

    {
      field: "CreatedBy",
      headerName: `${t("CreatedBy")}`,

      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "CreatedDate",
      headerName: `${t("CreatedDate")}`,
      type: "date",

      align: "left",
      valueFormatter: (params) =>
            moment(params?.value).format(GetTemplateGridDateFormat()),
        filterOperators: getGridDateOperators().filter(
                (operator) => operator.value !== 'before' && operator.value !== 'after',
            ), 
      headerAlign: "left",
        flex: 1,

    },

    {
      field: "ModifiedBy",
      headerName: `${t("ModifiedBy")}`,
      align: "left",
      flex: 1,
      width: 100,
      headerAlign: "left",
    },

    {
      field: "ModifiedDate",
      headerName: `${t("ModifiedDate")}`,
      type: "date",
     
      align: "left",
        valueFormatter: (params) => {
           
           return params?.value != null
                ? moment(params?.value).format(GetTemplateGridDateFormat())
                : ""
        },
        filterOperators: getGridDateOperators().filter(
            (operator) => operator.value !== 'before' &&  operator.value !== 'after',
        ), 
      headerAlign: "left",
      flex: 1,
    },
    {
      field: "TemplateId",
      headerName: `${t("Action")}`,

      sortable: false,
      filterable: false,
      headerAlign: "left",
      align: "left",
      flex: 1.5,

      renderCell: (row: any) => {
        return (
          <StatusChangeDialogue
            template={row.row}
            StatusNumber={row.row.ActiveStatus.toString()}
            fetchTemplate={fetchTemplate}
          />
        );
      },
    },
  ];

  const GridToolbarExport = ({ csvOptions }: any) => (
    <GridToolbarExportContainer
      sx={{ textTransform: "capitalize" }}
      className="fontfamily"
    >
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });

   

  return (
      <>
          <div>
              <DataGrid sx={{ width: "100%" }}
                  className="vvvvsss"
                  loading={loading }
  
        getRowId={(row) => row.TemplateId}
        rows={items}
        pageSizeOptions={[5, 10, 15]}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        columns={columns}
        components={{
          Toolbar: GridToolbarExport,
        }}
      />
        
          </div>
            </>
  );
}
