import {  Tab, Tabs } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { t } from "i18next";
import MeetingBackgroundList from "../../User/MeetingBackgroundList";
type Props = {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    setsucessmsg: React.Dispatch<React.SetStateAction<boolean>>;
    value: number;
    handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  
};
function SucestemplateinMeetingBGUser({ setActiveStep, setsucessmsg, value, handleChange }: Props) {
  return (
    <div className="succesinusermeetingbg">
      <div className="circleiconinMBG">
        <CheckIcon className="checkiconinMBG" />
      </div>
      <div>
        <h5 className="textcolor fontfamily postionoftext">
                  {t("MeetingbgCreatedSuccesfully")}

              </h5>
              <p className="textcolor fontfamily postionoftext">
                  {t("PressDoneSuccesfully")}

              </p>
      </div>
          <div>
              <Tabs value={value} onChange={handleChange} >
        <Tab
                      label={t("Done")}
                      className="positionofbuttoninbg"
                      onClick={() => {
                          setActiveStep(0);
                          setsucessmsg(false);
                      }} value={5} sx={{
                          color: "white",
                          backgroundColor: " #4BB543",
} }
        />  
              </Tabs>
              {value === 5 && (
                  <div>
                      <MeetingBackgroundList />
                  </div>
              )}
      </div>
     
    </div>
  );
}

export default SucestemplateinMeetingBGUser;
