
import { useState } from "react";
import { TemplateProps } from "../../Props/TemplateProps";
import VCardComponent from "./VCardComponent";

type Props = {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
};

function QrCodeTemplates({ value, handleChange }: Props) {
  const [previewData, setPreviewData] = useState<TemplateProps>({});
  return (
    <>
      <>
        <VCardComponent
          setPreviewData={setPreviewData}
          previewData={previewData}
          value={value}
          handleChange={handleChange}
        />
      </>
    </>
  );
}

export default QrCodeTemplates;
