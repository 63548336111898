import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { getLoggedInUser } from "../Components/Authentication/Auth";
import { showErrorMessage, showSuccessMessage } from "./Message";
import {
  getStatusWorkFlow,
  StatusWorkFlow,
} from "../Components/Template/TemplateWorkFlow";
import { updateQr } from "../Services/Qrcode";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
type Props = {
  QrCodes: any;
  fetchQrCodes: () => void;
};
export default function StatusChangeGeneratedQrs({
  QrCodes,
  fetchQrCodes,
}: Props) {
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  function handleStatus(QrCodes: any, newStatus: any) {
    QrCodes.ActiveStatus = newStatus;
    QrCodes.ModifiedBy = getLoggedInUser();
    updateQr(QrCodes)
      .then(function (response: any) {
        showSuccessMessage(
            QrCodes.Title + `${t("Statuschangedto")}` + getStatusWorkFlow(newStatus)
        );
        fetchQrCodes();
      })
      .catch(function (error: any) {
        showErrorMessage(t("Failedtoupdate"));
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        className="fontfamily buttonsize"
        variant="outlined"
        sx={{
          Color: " #0076A8",
          backgroundColor: "white",
          textTransform: "none",
        }}
        onClick={handleClickOpen}
      >
        {QrCodes.ActiveStatus === StatusWorkFlow.Active
          ? `${t("Expired")}`
          : `${t("Activate")}`}
      </Button>

      <Dialog
        className="fontfamily"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="fontfamily">{t("ConfirmAction")}</DialogTitle>
        <DialogContent>
          <DialogContentText
            className="fontfamily"
            id="alert-dialog-slide-description"
          >
            {t("Actionpopup")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="fontfamily"
            variant="outlined"
            sx={{
              Color: " #0076A8",
              backgroundColor: "white",
              textTransform: "none",
            }}
            onClick={handleClose}
          >
            {t("Cancel")}
          </Button>
          <Button
            className="fontfamily"
            variant="outlined"
            sx={{
              Color: " #0076A8",
              backgroundColor: "white",
              textTransform: "none",
              fontFamily: "Open Sans",
            }}
            onClick={() => {
              handleStatus(
                QrCodes,
                QrCodes.ActiveStatus === StatusWorkFlow.Active
                  ? StatusWorkFlow.Expired
                  : StatusWorkFlow.Active
              ),
                handleClose();
            }}
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
