import { Box, Button, Dialog, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddMeeting from "./AddMeetingTemplate/AddMeeting";
import PreviewMeeting from "./AddMeetingTemplate/PreviewMeeting";
import CloseIcon from "@mui/icons-material/Close";
import { MeetingTemplateProps } from "../../Props/MeetingTemplateProps";
import { showErrorMessage } from "../../Notifications/Message";
import { getMeetingTemplateList } from "../../Services/MeetingTemplate";
import SucessMeetingTemplate from "./AddMeetingTemplate/SucessMeetingTemplate";
import { MeetingBackGroundList } from "../Template/MeetingBackGroundList";
import CircularProgress from '@mui/material/CircularProgress';
function AdminMeetingBg() {

    const [items, setItems] = useState<MeetingTemplateProps[]>([]);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState<boolean>(true)
    const [filebase64, setFileBase64] = useState<string>("");
    const [fullWidth, setFullWidth] = React.useState(false);
    const [topLeft, setTopLeft] = useState(false);
    const [topCenter, setTopCenter] = useState(false);
    const [bottomCenter, setBottomCenter] = useState(false);
    const [topRight, setTopRight] = useState(false);
    const [bottomLeft, setBottomLeft] = useState(false);
    const [bottomRight, setBottomRight] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
  

    useEffect( () => fetchMeetingTemplate(), []);

    function fetchMeetingTemplate () {
      

        getMeetingTemplateList()
            .then(function (response: any) {
                setItems(response.data);
              
             
            })
            .catch(function (error: any) {
               
                showErrorMessage(t('Failedtogettemplates'));
            });
    }
  
     
        const handleClickOpen = () => {
            setOpen(true);
           
        };

        const handleClose = (value: string) => {
            setOpen(false);
            setShow(true)
        
        };


   


    return (
        <> <Box >
            <Box>
                <h2 style={{ fontSize: "25px" }}>{t("MeetingTemplate")}</h2>
                <p style={{ fontSize: "15px" }}>{t("detailsshown")}</p>
            </Box>

            <Box>
                <Button
                    variant="contained" className=" addRight fontfamily"
                    sx={{ backgroundColor: " #0076A8", color: "white", textTransform: 'none', }}
                    onClick={handleClickOpen}>
                    <AddCircleOutlineIcon /> 
                    {t("Addanewbackground")}
                </Button>

                <Box className="list">
                    <MeetingBackGroundList fetchMeetingTemplate={fetchMeetingTemplate} items={items}  />             </Box>
               
            </Box>
        </Box>
          <Dialog

                open={open}
                maxWidth='lg'
                fullWidth={fullWidth}
                onClose={handleClose}            >
                <Typography variant="h6" sx={{ textAlign: "left", m: 2 }} className="fontfamily">
                    {t("UploadanewMeetingtemplate")}
                </Typography>
                <CloseIcon className='closeIconstyelIntemplate' style={{ left: "1120" }} onClick={() => { setOpen(false),setShow(true), setFileBase64("") }} />

                <hr style={{ width: "1190px", }}></hr>
                <div className="addTemplateContainer" style={{ width: "1190px" }}>
                        {show ? (<>                    {isLoading ? <CircularProgress value={50} sx={{

                            position: 'relative',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }} disableShrink /> : <><AddMeeting
                            open={open}

                            setIsLoading={setIsLoading}
                            setTopCenter={setTopCenter}
                            setBottomCenter={setBottomCenter}
                            setShow={setShow}
                            setOpen={setOpen}
                            filebase64={filebase64}
                            setFileBase64={setFileBase64}
                            setTopLeft={setTopLeft}
                            setTopRight={setTopRight}
                            setBottomLeft={setBottomLeft}
                            setBottomRight={setBottomRight}
                            fetchMeetingTemplate={fetchMeetingTemplate}

                        />
                            <PreviewMeeting
                                filebase64={filebase64}
                                topLeft={topLeft}
                                topRight={topRight}
                                bottomLeft={bottomLeft}
                                bottomRight={bottomRight}
                                topCenter={topCenter}
                                bottomCenter={bottomCenter}

                            /></>}</>) :( <SucessMeetingTemplate setOpen={setOpen} setShow={setShow} />)}

                   
                </div>
            </Dialog>
        </>
    );
}
export default AdminMeetingBg;