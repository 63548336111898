import { Box } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { showErrorMessage } from "../../Notifications/Message";
import { TemplateProps } from "../../Props/TemplateProps";
import {
  getQrTemplateById,
  getQrTemplateList,
} from "../../Services/QrTemplate";
import { StatusWorkFlow } from "../Template/TemplateWorkFlow";

type Props = {
  changeTemplateID: (value: string) => void;
  setPreviewData: Dispatch<SetStateAction<TemplateProps>>;
  previewData: TemplateProps;
};

const TemplateSlideBar = ({
  changeTemplateID,
  setPreviewData,
  previewData,
}: Props) => {
  const [templateData, setTemplateData] = useState<TemplateProps[]>([]);
  const { t, i18n } = useTranslation();
  const handleClick = (template: TemplateProps) => {
    if (template.TemplateId != undefined) {
      changeTemplateID(template.TemplateId);

      getQrTemplateById(template.TemplateId).then(function (response) {
          setPreviewData(response.data);
        
      });
    }
  };
  const [index, setIndex] = useState(0);

  function setSliderData(data: TemplateProps[]) {
    setTemplateData(data);

    if (data.length > 0) {
      let i = 0;
      while (i < data.length) {
        if (data[i].ActiveStatus != StatusWorkFlow.Active) {
          i++;
          continue;
        }
        handleClick(data[i]);
        break;
      }
    }
  }

  useEffect(() => {
    getQrTemplateList()
      .then(function (response) {
        setSliderData(response.data);
      })
      .catch(function (error) {
        showErrorMessage(t("Failedtogettemplates"));
      });
  }, []);

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
            className={className} 
        style={{
          ...style,
          display: "block",
          background: "white",
          position: "relative",
          border: 0,
          left: "96%",
          top: "-100px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "white",
          position: "relative",
          left: "-40px",
          top: "70px",
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
      speed: 0,

    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    focusOnChange: true,
    waitForAnimate: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (e: any) => setIndex(e),
  };

  return (
      <div>
          <div>
      <h5
        className="selectTemplate"
        style={{ marginTop: "1px", color: "black" }}
      >
        {t("SelectatemplateforyourQRCode")}
      </h5>
      <p className="sliderTemplatename">
        <b>{t("SelectedTemplateName")}</b> &nbsp;&nbsp;
        {previewData.TemplateName}
              </p>
              </div>
      <Box className="urlComponent-Template-Slider">
        <Slider {...settings}>
          {templateData?.length &&
            templateData?.map((item: any) => {
              if (item.ActiveStatus == StatusWorkFlow.Active)
                return (
                    <div className="slideer123">
                       
                        <img
                            className=" sliderImg"
                          onClick={(event) => {
                          handleClick(item);
                          event.preventDefault();
                        
                      }}
                      src={item?.TemplatePreview}
                    />
                 
                    </div>
                );
            })}
        </Slider>
      </Box>
    </div>
  );
};

export default TemplateSlideBar;
