import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetCookie, GetDisclaimerURL } from "../../authConfig";

function Footbar() {
  const { t, i18n } = useTranslation();
  return (
      <>
          <div className="footer">
      <hr style={{ width: "100%" }}></hr>
        
              <Typography className="fontinfotterinMobile fontfamily" style={{ float: "left" }}>
      
                  <a href={GetCookie()} target="popup"
                      style={{ float: "left", marginLeft: "4px", marginTop: "1px" }} className="fontinfotterinMobile fontfamily"

                  >
                      {t("Cookie")}
                  </a>
        {t("Footer1")}
              </Typography>
             
            
              <a href={GetDisclaimerURL()} target="popup"
                  style={{ float: "left", marginLeft: "4px", marginTop: "1px" }} className="fontinfotterinMobile fontfamily"
                
              >
                  {t("Footer3")}
              </a>
              <Typography className="fontinfotterinMobile fontfamily" style={{ float: "right", marginRight:"10px" }}>{t("Footer2")}</Typography>
    </div>
    </>
  );
}
export default Footbar;
