import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
    showErrorMessage,
    showSuccessMessage,
} from "../../Notifications/Message";
import "./../../App.css";
import CloseIcon from "@mui/icons-material/Close";

import QrCodeDisplayable from "./../QrCodeUtils/QrCodeDisplayable";
import { downloadQrCodecAsBase64, getTempDivId } from "../../Utils/DownLoad";
import { DefaultQRCodeDetailsDefaultProps } from "../../Props/QRDeatailsProps";
import { updateQrDetail } from "../../Services/QrDetail";
import { getQrTemplateById } from "../../Services/QrTemplate";
import { DefaultTemplateProps } from "../../Props/TemplateProps";
import { useTranslation } from "react-i18next";
import { constructVCard, constructBCard } from "../../Utils/VcfGenerator";
import { getBusinessCardListById, updateBusinessCard } from "../../Services/BusinessCard";
import { DefaultBusinessCardProps } from "../../Props/BusinessCardProps";
import { getLoggedInUser } from "../Authentication/Auth";
import { updateQr } from "../../Services/Qrcode";
export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
    setChange: React.Dispatch<React.SetStateAction<boolean>>;
}


function BootstrapDialogTitle(props: DialogTitleProps) {
    const { t, i18n } = useTranslation();
    const { children, onClose, setChange, ...other } = props;
    return (
        <DialogTitle className="fontfamily headerViewvcard " {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label={`${t("Close")}`}
                    onClick={() => {
                        onClose(), setChange(false);
                    }}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
type Props = {
    QrCodeId: any;
    rowData: any;
    fetchQrCodes: () => void;
    templateId: any;
};
function VieworEditBCard({
    QrCodeId,
    fetchQrCodes,
    rowData,
    templateId,
}: Props) {
    const [bcard, setBcard] = useState<any>();
    const [FIRSTNAME, setFIRSTNAME] = useState("" as any);
    const [LASTNAME, setLASTNAME] = useState("" as any);
    const [EMAILADDRESS, setEMAILADDRESS] = useState("" as any);
    const [ORGDESC, setORGDESC] = useState("" as any);
    const [RELEMTELEPHONENUMBER, setRELEMTELEPHONENUMBER] = useState("" as any);
    const [JOBTITLE, setJOBTITLE] = useState("" as any);
    const [DESIGNATIONDESCRIPTION, setDESIGNATIONDESCRIPTION] = useState("" as any);
    const [MAILADDCITYDESC, setMAILADDCITYDESC] = useState("" as any);
    const [MAILADDCOUNTRYDESC, setMAILADDCOUNTRYDESC] = useState("" as any);
    const [MAILINGADDRESSLINE_1, setMAILINGADDRESSLINE_1] = useState("" as any);
    const [MAILINGADDRESSLINE_2, setMAILINGADDRESSLINE_2] = useState("" as any);
    const [MAILINGADDRESSLINE_3, setMAILINGADDRESSLINE_3] = useState("" as any);
    const [POSTALCODE, setPOSTALCODE] = useState("" as any);
    const [TELEPHONENUMBER, setTELEPHONENUMBER] = useState("" as any);
    const [QrcodeImage, setQrcodeImage] = useState(" " as any);
    const {
        handleSubmit,
        control, reset,
        formState: { errors },
    } = useForm<DefaultBusinessCardProps>();

    const [op, setOp] = useState(false);
    const [change, setChange] = useState(false);
    const [templateProps, setTemplateProps] = useState<DefaultTemplateProps>({});
    const [expired, setExpired] = useState(false);
    const { t, i18n } = useTranslation();

    const handleClickOpen1 = async (QrCodeId: string, templateId: any) => {

        getBusinessCardListById(QrCodeId).then(function (response: any) {
           
            setFIRSTNAME(response.data.FIRST_NAME);
            setLASTNAME(response.data.LAST_NAME);
            setEMAILADDRESS(response.data.EMAIL_ADDRESS);
            setORGDESC(response.data.ORGANIZATION_DESCRIPTION);
            setRELEMTELEPHONENUMBER(response.data.RELATED_EMPLOYEE_TELEPHONE_NUMBER);
            setJOBTITLE(response.data.JOB_TITLE_DESCRIPTION);
            setDESIGNATIONDESCRIPTION(response.data.DESIGNATION_DESCRIPTION);
            setMAILADDCITYDESC(response.data.MAILING_ADDRESS_CITY_DESCRIPTION);
            setMAILADDCOUNTRYDESC(response.data.MAILING_ADDRESS_COUNTRY_DESCRIPTION);
            setMAILINGADDRESSLINE_1(response.data.MAILING_ADDRESS_LINE_1);
            setMAILINGADDRESSLINE_2(response.data.MAILING_ADDRESS_LINE_2);
            setMAILINGADDRESSLINE_3(response.data.MAILING_ADDRESS_LINE_3);
            setPOSTALCODE(response.data.MAILING_ADDRESS_POSTAL_CODE);
            setTELEPHONENUMBER(response.data.TELEPHONE_NUMBER);
            setQrcodeImage(response.data.QRImage);
            setBcard(response.data);
        });
        getQrTemplateById(templateId).then(function (response) {
            setTemplateProps(response.data);
        });
    };
    const [QRDetails] = useState<DefaultQRCodeDetailsDefaultProps>({
        QRCodeId: "",
        QRName: "",
        QRImage: "",
    });
    function constructQR(props: DefaultBusinessCardProps) {
        return constructBCard(
            FIRSTNAME,
            LASTNAME,
            JOBTITLE,
            DESIGNATIONDESCRIPTION,
            EMAILADDRESS,
            RELEMTELEPHONENUMBER,
            TELEPHONENUMBER,
            "",
            ORGDESC,
            "",

            MAILINGADDRESSLINE_1,
            MAILINGADDRESSLINE_2,
            MAILINGADDRESSLINE_3,
            MAILADDCITYDESC,
            MAILADDCOUNTRYDESC,
            POSTALCODE,
            ""

        );
    }
    const handleExpired = () => {
        if (rowData == 5) {
            setExpired(true);
        } else {
            setExpired(false);
        }
    };
    const addOpen = () => {
        setOp(true);
    };



    const addClose = () => {
        setOp(false);

    };
    const handleUpadate = (data: any) => {
        bcard.QRCodeId = QrCodeId;
        const _QrCodes = {
            QrCodeId: QrCodeId,
            modifiedBy: getLoggedInUser()
        }
        updateQr(_QrCodes)
            .then(function (response: any) {
                if (response.status == 200) {
                    updateBusinessCard(bcard).then(function (response) {
                        if (response.status == 200) {
                            QRDetails.QRCodeId = QrCodeId;
                            QRDetails.QRName = FIRSTNAME;
                            QRDetails.QRImage = downloadQrCodecAsBase64(getTempDivId());

                            updateQrDetail(QRDetails)
                                .then(function (response) {
                                    addClose();
                                    showSuccessMessage(`${t("UpdateSuccessfully")}`);



                                    fetchQrCodes();
                                })
                                .catch(function (repsonse) {
                                    showErrorMessage(`${t("FailedtoeditContactcarddetails")}`);
                                });
                        }
                    });
                }
            })
            .catch(function (error: any) {
                showErrorMessage(t("Failedtoupdate"));
            });
    };

  

    const handChange = (event: any) => {
        const { name, value } = event.target;
        setBcard((prevState: any) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };
    return (
        <>
            <Button
                className="fontfamily buttonsize"
                variant="outlined"
                sx={{ textTransform: "none" }}
                onClick={() => {
                    addOpen(), handleExpired(), handleClickOpen1(QrCodeId, templateId);
                }}
            >
                {t("ViewDetails")}
            </Button>
            <Dialog
                className="fontfamily gridoverflow"
                open={op}
                onClose={() => {
                    addClose(), setChange(false); 
                }}
            >
                
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={addClose}
                    setChange={setChange}
                >
                    {t("BusinessCardInformation")}
                </BootstrapDialogTitle>
                <hr></hr>
                <div className="gridoverflow gridoverFlow1">
                    {change ? (
                        <DialogTitle>
                            <div className="qrcodeinViewVcard">
                                <QrCodeDisplayable
                                    TargetUrl={constructQR(bcard)}
                                    RenderType={"svg"}
                                    level={"L"}
                                    marginRequired={true}
                                    DivId={getTempDivId()}
                                    ForeColor={templateProps.ForeColor}
                                    BackgroundColor={templateProps.BackgroundColor}
                                    Height={160}
                                    Width={160}
                                    Logo={templateProps.Logo}
                                    CreatedBy={templateProps.CreatedBy}
                                    TemplateName={templateProps.TemplateName}
                                    TemplateId={""}
                                    CreatedDate={new Date()}
                                    ModifiedBy={""}
                                    ModifiedDate={new Date()}
                                    ActiveStatus={""}
                                    TemplatePreview={""}
                                    Shape={templateProps.Shape}
                                />
                            </div>
                        </DialogTitle>
                    ) : (
                        <DialogTitle className="dialogTitltboxvvcard">
                            <div className="viewqrcodeineditvcard">
                                <QrCodeDisplayable
                                    TargetUrl={constructQR(bcard)}
                                    RenderType={"svg"}
                                    level={"L"}
                                    marginRequired={true}
                                    DivId={getTempDivId()}
                                    ForeColor={templateProps.ForeColor}
                                    BackgroundColor={templateProps.BackgroundColor}
                                    Height={160}
                                    Width={160}
                                    Logo={templateProps.Logo}
                                    CreatedBy={templateProps.CreatedBy}
                                    TemplateName={templateProps.TemplateName}
                                    TemplateId={""}
                                    CreatedDate={new Date()}
                                    ModifiedBy={""}
                                    ModifiedDate={new Date()}
                                    ActiveStatus={""}
                                    TemplatePreview={""}
                                    Shape={templateProps.Shape}
                                />
                            </div>
                        </DialogTitle>
                    )}

                    <DialogContent>
                        <Box component="form" noValidate autoComplete="off">
                            <Grid
                                container
                                spacing={{ xs: 3, md: 3 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                {change ? (
                                    <>
                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t("FirstName")}*
                                            </Typography>
                                            <Controller
                                                name="FIRST_NAME"
                                                control={control}
                                                rules={{
                                                    maxLength: 100,
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={FIRSTNAME}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        required
                                                        placeholder={`${t("EnteryourFirstName")}`}
                                                        inputProps={{
                                                            name: "FIRST_NAME",
                                                            maxlength: 100
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setFIRSTNAME(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : ""} />
                                                )}
                                            />

                                            {errors?.FIRST_NAME?.type === "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                            {errors?.FIRST_NAME?.type === "maxLength" && (
                                                <p className="alertColor">{`${t(
                                                    "maximum_30_characters"
                                                )}`}</p>
                                            )}
                                            {errors?.FIRST_NAME?.type === "pattern" && (
                                                <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                            )}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t('LastName')}*
                                            </Typography>
                                            <Controller
                                                name="LAST_NAME"
                                                control={control}
                                                rules={{
                                                    maxLength: 100,
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={LASTNAME}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        required

                                                        inputProps={{
                                                            name: "LAST_NAME", maxlength: 100
                                                        }}
                                                        placeholder={`${t("EnteryourLastName")}`}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setLASTNAME(e.target.value);
                                                        }}

                                                        error={!!error}
                                                        helperText={error ? error.message : ""}
                                                    />
                                                )}
                                            />

                                            {errors?.LAST_NAME?.type === "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                            {errors?.LAST_NAME?.type === "maxLength" && (
                                                <p className="alertColor">{`${t(
                                                    "maximum_30_characters"
                                                )}`}</p>
                                            )}
                                            {errors?.LAST_NAME?.type === "pattern" && (
                                                <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                            )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t("MobileNo")}*
                                            </Typography>
                                            <Controller
                                                name="RELATED_EMPLOYEE_TELEPHONE_NUMBER"
                                                control={control}
                                                rules={{
                                                    maxLength: 50,
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={RELEMTELEPHONENUMBER}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        required
                                                        placeholder={`${t("EnteryourMobilenumber")}`}
                                                        inputProps={{
                                                            name: "RELATED_EMPLOYEE_TELEPHONE_NUMBER", maxLength: 50,
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setRELEMTELEPHONENUMBER(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : ""} />
                                                )}
                                            />
                                            {errors?.RELATED_EMPLOYEE_TELEPHONE_NUMBER?.type ===
                                                "required" && (
                                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                                )}
                                            {errors?.RELATED_EMPLOYEE_TELEPHONE_NUMBER?.type ===
                                                "maxLength" && (
                                                    <p className="alertColor">{`${t(
                                                        "maximum_30_characters"
                                                    )}`}</p>
                                                )}
                                            {errors?.RELATED_EMPLOYEE_TELEPHONE_NUMBER?.type ===
                                                "pattern" && (
                                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                                )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t("Organization")}*
                                            </Typography>
                                            <Controller
                                                name="ORGANIZATION_DESCRIPTION"
                                                control={control}
                                                rules={{
                                                    maxLength: 100,
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={ORGDESC}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        required
                                                        placeholder={`${t("Enteryourorganization")}`}
                                                        inputProps={{
                                                            name: "ORGANIZATION_DESCRIPTION", maxlength: 100
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setORGDESC(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : ""}
                                                    />
                                                )}
                                            />

                                            {errors?.ORGANIZATION_DESCRIPTION?.type ===
                                                "required" && (
                                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                                )}
                                            {errors?.ORGANIZATION_DESCRIPTION?.type ===
                                                "maxLength" && (
                                                    <p className="alertColor">{`${t(
                                                        "maximum_30_characters"
                                                    )}`}</p>
                                                )}
                                            {errors?.ORGANIZATION_DESCRIPTION?.type ===
                                                "pattern" && (
                                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                                )}

                                        </Grid>
                                       
                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t("JobTitle")}*
                                            </Typography>
                                            <Controller
                                                name="JOB_TITLE_DESCRIPTION"
                                                control={control}
                                                rules={{
                                                    maxLength: 100,
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={JOBTITLE}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        required
                                                        placeholder={`${t("EnterYourJobTitle")}`}
                                                        inputProps={{
                                                            name: "JOB_TITLE_DESCRIPTION", maxLength: 100,
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setJOBTITLE(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : ""} />
                                                )}
                                            />
                                            {errors?.JOB_TITLE_DESCRIPTION?.type === "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                            {errors?.JOB_TITLE_DESCRIPTION?.type === "maxLength" && (
                                                <p className="alertColor">{`${t(
                                                    "maximum_30_characters"
                                                )}`}</p>
                                            )}
                                            {errors?.JOB_TITLE_DESCRIPTION?.type === "pattern" && (
                                                <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                            )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t("Designation")}*
                                            </Typography>
                                            <Controller
                                                name="DESIGNATION_DESCRIPTION"
                                                control={control}
                                                rules={{
                                                    maxLength: 100,
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={DESIGNATIONDESCRIPTION}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        required placeholder={`${t("EnteryourDesignation")}`}
                                                        inputProps={{
                                                            name: "DESIGNATION_DESCRIPTION", maxLength: 100,
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setDESIGNATIONDESCRIPTION(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : ""} />
                                                )}
                                            />
                                            {errors?.DESIGNATION_DESCRIPTION?.type === "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                            {errors?.DESIGNATION_DESCRIPTION?.type === "maxLength" && (
                                                <p className="alertColor">{`${t(
                                                    "maximum_30_characters"
                                                )}`}</p>
                                            )}
                                            {errors?.DESIGNATION_DESCRIPTION?.type === "pattern" && (
                                                <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                            )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t("Workphone")}*
                                            </Typography>
                                            <Controller
                                                name="TELEPHONE_NUMBER"
                                                control={control}
                                                rules={{
                                                    maxLength: 50,
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={TELEPHONENUMBER}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        required placeholder={`${t("EnteryourPhonenumber")}`} 
                                                        inputProps={{
                                                            name: "TELEPHONE_NUMBER", maxLength: 50,
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setTELEPHONENUMBER(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : ""} />
                                                )}
                                            />
                                            {errors?.TELEPHONE_NUMBER?.type === "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                            {errors?.TELEPHONE_NUMBER?.type === "maxLength" && (
                                                <p className="alertColor">{`${t("maximum_30_characters")}`}</p>
                                            )}
                                            {errors?.TELEPHONE_NUMBER?.type === "pattern" && (
                                                <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                            )}
                                        </Grid>
                                        
                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t("MailingAddress1")}*
                                            </Typography>
                                            <Controller
                                                name="MAILING_ADDRESS_LINE_1"
                                                control={control}
                                                rules={{
                                                    maxLength: 100,
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={MAILINGADDRESSLINE_1}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        required placeholder={`${t("EnterMailingAddress")}`}
                                                        inputProps={{
                                                            name: "MAILING_ADDRESS_LINE_1", maxLength: 100,
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setMAILINGADDRESSLINE_1(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : ""} />
                                                )}
                                            />
                                            {errors?.MAILING_ADDRESS_LINE_1?.type === "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                            {errors?.MAILING_ADDRESS_LINE_1?.type === "maxLength" && (
                                                <p className="alertColor">{`${t(
                                                    "maximum_30_characters"
                                                )}`}</p>
                                            )}
                                            {errors?.MAILING_ADDRESS_LINE_1?.type === "pattern" && (
                                                <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                            )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t("MailingAddress2")}
                                            </Typography>
                                            <Controller
                                                name="MAILING_ADDRESS_LINE_2"
                                                control={control}
                                                rules={{
                                                    maxLength: 100,
                                                    required: false,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={MAILINGADDRESSLINE_2}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                         placeholder={`${t("EnterMailingAddress")}`}
                                                        inputProps={{
                                                            name: "MAILING_ADDRESS_LINE_2", maxLength: 100,
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setMAILINGADDRESSLINE_2(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : ""} />
                                                )}
                                            />
                                            {errors?.MAILING_ADDRESS_LINE_2?.type === "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                            {errors?.MAILING_ADDRESS_LINE_2?.type === "maxLength" && (
                                                <p className="alertColor">{`${t(
                                                    "maximum_30_characters"
                                                )}`}</p>
                                            )}
                                            {errors?.MAILING_ADDRESS_LINE_2?.type === "pattern" && (
                                                <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                            )}
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t("City")}*
                                            </Typography>
                                            <Controller
                                                name="MAILING_ADDRESS_CITY_DESCRIPTION"
                                                control={control}
                                                rules={{
                                                    maxLength: 100,
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={MAILADDCITYDESC}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        required placeholder={`${t("Enteryourcityname")}`}
                                                        inputProps={{
                                                            name: "MAILING_ADDRESS_CITY_DESCRIPTION", maxLength: 100,
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setMAILADDCITYDESC(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : ""} />
                                                )}
                                            />
                                            {errors?.MAILING_ADDRESS_CITY_DESCRIPTION?.type ===
                                                "required" && (
                                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                                )}
                                            {errors?.MAILING_ADDRESS_CITY_DESCRIPTION?.type ===
                                                "maxLength" && (
                                                    <p className="alertColor">{`${t(
                                                        "maximum_30_characters"
                                                    )}`}</p>
                                                )}
                                            {errors?.MAILING_ADDRESS_CITY_DESCRIPTION?.type ===
                                                "pattern" && (
                                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                                )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t("MailingAddress3")}*
                                            </Typography>
                                            <Controller
                                                name="MAILING_ADDRESS_LINE_3"
                                                control={control}
                                                rules={{
                                                    maxLength: 100,
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={MAILINGADDRESSLINE_3}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        required placeholder={`${t("EnterStateProvince")}`}
                                                        inputProps={{
                                                            name: "MAILING_ADDRESS_LINE_3", maxLength: 100,
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setMAILINGADDRESSLINE_3(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : ""} />
                                                )}
                                            />
                                            {errors?.MAILING_ADDRESS_LINE_3?.type === "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                            {errors?.MAILING_ADDRESS_LINE_3?.type === "maxLength" && (
                                                <p className="alertColor">{`${t(
                                                    "maximum_30_characters"
                                                )}`}</p>
                                            )}
                                            {errors?.MAILING_ADDRESS_LINE_3?.type === "pattern" && (
                                                <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                            )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t("PostalCode")}*
                                            </Typography>
                                            <Controller
                                                name="MAILING_ADDRESS_POSTAL_CODE"
                                                control={control}
                                                rules={{
                                                    maxLength: 20,
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={POSTALCODE}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        required placeholder={`${t("EnteryourPostalcode")}`}
                                                        inputProps={{
                                                            name: "MAILING_ADDRESS_POSTAL_CODE", maxLength: 20,
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setPOSTALCODE(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : ""} />
                                                )}
                                            />
                                            {errors?.MAILING_ADDRESS_POSTAL_CODE?.type === "required" && (
                                                <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                            )}
                                            {errors?.MAILING_ADDRESS_POSTAL_CODE?.type === "maxLength" && (
                                                <p className="alertColor">{`${t(
                                                    "maximum_30_characters"
                                                )}`}</p>
                                            )}
                                            {errors?.MAILING_ADDRESS_POSTAL_CODE?.type === "pattern" && (
                                                <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                            )}
                                        </Grid>
                                       
                                        <Grid item xs={4}>
                                            <Typography className="fontfamily">
                                                {t("Country")}*
                                            </Typography>
                                            <Controller
                                                name="MAILING_ADDRESS_COUNTRY_DESCRIPTION"
                                                control={control}
                                                rules={{
                                                    maxLength: 100,
                                                    required: true,
                                                    pattern: /^\s*(\S(.*\S)?)\s*$/,
                                                }}
                                                defaultValue={MAILADDCOUNTRYDESC}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        required placeholder={`${t("Enteryourcountryname")}`}
                                                        inputProps={{
                                                            name: "MAILING_ADDRESS_COUNTRY_DESCRIPTION", maxLength: 100,
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e), handChange(e), setMAILADDCOUNTRYDESC(e.target.value);
                                                        }}
                                                        error={!!error}
                                                        helperText={error ? error.message : ""} />
                                                )}
                                            />
                                            {errors?.MAILING_ADDRESS_COUNTRY_DESCRIPTION?.type ===
                                                "required" && (
                                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                                )}
                                            {errors?.MAILING_ADDRESS_COUNTRY_DESCRIPTION?.type ===
                                                "maxLength" && (
                                                    <p className="alertColor">{`${t(
                                                        "maximum_30_characters"
                                                    )}`}</p>
                                                )}
                                            {errors?.MAILING_ADDRESS_COUNTRY_DESCRIPTION?.type ===
                                                "pattern" && (
                                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                                )}
                                        </Grid>
                                       
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("FirstName")}
                                            </Typography>
                                            <Typography
                                                className="fontfamily address2"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {FIRSTNAME}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily ">
                                                {t("LastName")}
                                            </Typography>
                                            <Typography
                                                className="fontfamily address2"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {LASTNAME}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("EmailId")}
                                            </Typography>
                                            <Typography
                                                className="fontfamily address3"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {EMAILADDRESS}
                                            </Typography>
                                        </Grid>
                                       

                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                    {t("MobileNo")}
                                            </Typography>

                                            <Typography
                                                className="fontfamily"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {RELEMTELEPHONENUMBER}
                                            </Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={4} md={4}>
                                                <Typography className="fontfamily">
                                                    {t("Organization")}
                                                </Typography>
                                                <Typography
                                                    className="fontfamily address2"
                                                    variant="caption"
                                                    display="block"
                                                    sx={{ color: "#0076A8" }}
                                                    gutterBottom
                                                >
                                                    {ORGDESC}
                                                </Typography>
                                            </Grid>
                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("JobTitle")}
                                            </Typography>
                                            <Typography
                                                className="fontfamily address1"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {JOBTITLE}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("Designation")}
                                            </Typography>
                                            <Typography
                                                className="fontfamily address2"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {DESIGNATIONDESCRIPTION}
                                                </Typography>

                                            </Grid>

                                            <Grid item xs={2} sm={4} md={4}>
                                                <Typography className="fontfamily">

                                                    {t("Workphone")}
                                                </Typography>
                                                <Typography
                                                    className="fontfamily"
                                                    variant="caption"
                                                    display="block"
                                                    sx={{ color: "#0076A8" }}
                                                    gutterBottom
                                                >
                                                    {TELEPHONENUMBER}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={2} sm={4} md={4}>
                                                <Typography className="fontfamily">
                                                    {t("MailingAddress1")}
                                                </Typography>
                                                <Typography
                                                    className="fontfamily address1"
                                                    variant="caption"
                                                    display="block"
                                                    sx={{ color: "#0076A8" }}
                                                    gutterBottom
                                                >
                                                    {MAILINGADDRESSLINE_1}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={4} md={4}>
                                                <Typography className="fontfamily ">
                                                    {t("MailingAddress2")}
                                                </Typography>
                                                <Typography
                                                    className="fontfamily address2"
                                                    variant="caption"
                                                    display="block"
                                                    sx={{ color: "#0076A8" }}
                                                    gutterBottom
                                                >
                                                    {MAILINGADDRESSLINE_2}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={4} md={4}>
                                                <Typography className="fontfamily">
                                                    {t("City")}
                                                </Typography>
                                                <Typography
                                                    className="fontfamily address2"
                                                    variant="caption"
                                                    display="block"
                                                    sx={{ color: "#0076A8" }}
                                                    gutterBottom
                                                >
                                                    {MAILADDCITYDESC}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={4} md={4}>
                                                <Typography className="fontfamily">
                                                    {t("MailingAddress3")}
                                                </Typography>
                                                <Typography
                                                    className="fontfamily address2"
                                                    variant="caption"
                                                    display="block"
                                                    sx={{ color: "#0076A8" }}
                                                    gutterBottom
                                                >
                                                    {MAILINGADDRESSLINE_3}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={2} sm={4} md={4}>
                                                <Typography className="fontfamily">
                                                    {t("PostalCode")}
                                                </Typography>
                                                <Typography
                                                    className="fontfamily"
                                                    variant="caption"
                                                    display="block"
                                                    sx={{ color: "#0076A8" }}
                                                    gutterBottom
                                                >
                                                    {POSTALCODE}
                                                </Typography>
                                            </Grid>
                                       
                                        <Grid item xs={2} sm={4} md={4}>
                                            <Typography className="fontfamily">
                                                {t("Country")}
                                            </Typography>
                                            <Typography
                                                className="fontfamily address2"
                                                variant="caption"
                                                display="block"
                                                sx={{ color: "#0076A8" }}
                                                gutterBottom
                                            >
                                                {MAILADDCOUNTRYDESC}
                                            </Typography>
                                        </Grid>
                                      

                                       
                                       
                                    </>
                                )}
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {expired ? (
                            <Button
                                className="fontfamily"
                                variant="outlined"
                                sx={{ textTransform: "none" }}
                                onClick={() => {
                                    addClose(), handleExpired();
                                }}
                            >
                                {t("Close")}
                            </Button>
                        ) : (
                            <>
                                {change ? (
                                    <div className="buttonGrupviewvcard">
                                        <Button
                                            className="fontfamily"
                                            variant="outlined"
                                            sx={{ textTransform: "none" }}
                                            type="submit"
                                            onClick={handleSubmit(handleUpadate)}
                                            style={{ marginRight: "10px" }}
                                        >
                                            {t("SaveBotton")}
                                        </Button>
                                        <Button
                                            className="fontfamily"
                                            variant="outlined"
                                            sx={{ textTransform: "none" }}
                                            onClick={() => {
                                                addClose(), setChange(false), reset();
                                            }}
                                        >
                                            {t("Close")}
                                        </Button>
                                    </div>
                                ) : (
                                    <>
                                        <Button
                                            className="fontfamily"
                                            variant="outlined"
                                            sx={{ textTransform: "none" }}
                                            onClick={() => {
                                                setChange(true), handleExpired();
                                            }}
                                            style={{ marginRight: "10px" }}
                                        >
                                            {t("EditDetails")}
                                        </Button>
                                        <Button
                                            className="fontfamily"
                                            variant="outlined"
                                            sx={{ textTransform: "none" }}
                                            onClick={() => {
                                                addClose(), setChange(false);
                                                    }} 
                                        >
                                            {t("Close")}{" "}
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
}

export default VieworEditBCard;