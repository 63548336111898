import { t } from "i18next";
import react, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Slider from "react-slick";
import { MeetingTemplateProps } from "../../../Props/MeetingTemplateProps";
import { getMeetingTemplateById, getMeetingTemplateList } from "../../../Services/MeetingTemplate";
import { StatusWorkFlow } from "../../Template/TemplateWorkFlow";

type Props = {
  
    setLoader: Dispatch<SetStateAction<boolean>>;
    changeTemplateID: (value: string) => void;
    meetingPreview: MeetingTemplateProps;
    setMeetingPreview: Dispatch<SetStateAction<MeetingTemplateProps>>;
}
function MeetingTemplateSlider({ setMeetingPreview, meetingPreview, changeTemplateID, setLoader }: Props) {
    const [index, setIndex] = useState(0);
    const [meetingBgdata, setMeetingBgdata] = useState<MeetingTemplateProps[]>([])
    const handleClick = (Meeting: any) => {
        setLoader(true)
   
        if (Meeting.MeetingId !== undefined) {
            changeTemplateID(Meeting.MeetingId);

            getMeetingTemplateById(Meeting.MeetingId).then(function (response) {
                setMeetingPreview(response.data)
                setLoader(false)
              
            }).catch(function (response) {

            })
        }
    }

    function setSliderData(data: MeetingTemplateProps[]) {
        setMeetingBgdata(data);

        if (data.length > 0) {
            let i = 0;
            while (i < data.length) {
                if (data[i].ActiveStatus != StatusWorkFlow.Active) {
                    i++;
                    continue;
                }
                handleClick(data[i]);
                break;
            }
        }
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 0,
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        focusOnChange: true,
        waitForAnimate: true,
        arrows: true,
       
        afterChange: (e: any) => setIndex(e),
    };
    useEffect(() => {
        getMeetingTemplateList().then(function (response) {
            setSliderData(response.data)
          
        }).catch(function(response){
            
            })
    },[])


    return (<div className="topoffSliderinMB">
        <div>
            <h5
                className="selectTemplate"
                style={{ marginTop: "1px", color: "black" }}
            >
                {t("SelectatemplateforyourQRCode")}
            </h5>
            <p className="sliderTemplatename">
                <b>{t("SelectedTemplateName")}</b>
                &nbsp; &nbsp; {meetingPreview.MeetingName}
            </p>
        </div>
        <div>
        <Slider {...settings}>
            {meetingBgdata?.length &&
                    meetingBgdata?.map((item: MeetingTemplateProps ) => {
                    if (item.ActiveStatus == StatusWorkFlow.Active)
                        return (
                            <div >

                                <img
                                    className="meetingimg"
                               


                                    onClick={(event) => {
                                        handleClick(item);
                                        event.preventDefault();
                                    }}
                          
                                    src={item?.MeetingIcon}
                                />

                            </div>
                        );
                })}

            </Slider>
        </div>
    </div>)
}
export default MeetingTemplateSlider