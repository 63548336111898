import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { FieldValues, useForm, UseFormHandleSubmit } from "react-hook-form";
import { Box, Tab, Tabs } from "@mui/material";
import MyQrCodes from "../Components/User/MyQrCodes";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
type Props = {
  CreateNewVcard: (data: any) => void;
  handleSubmit: UseFormHandleSubmit<any>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
};
export default function ConfirmPopupVcard({
  CreateNewVcard,
  handleSubmit,
  open,
  setOpen,
  value,
  handleChange,
}: Props) {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Button className="submitvcard"
        variant="contained"
        sx={{
          // position: "absolute",
          // left: "400px",
          // top: "265px",
          backgroundColor: " #0076A8",
          color: "white",
          textTransform: "none",
          fontFamily: "'Open Sans', Calibri, Arial, Helvetica, sans-serif",
        }}
        onClick={handleSubmit(CreateNewVcard)}
      >
        {t("Submit")}
      </Button>
          <Dialog
              maxWidth="md"
              fullWidth={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
              aria-describedby="alert-dialog-slide-description" 
        sx={{
          fontFamily:
            "'Open Sans', Calibri, Arial, Helvetica, sans-serif, sans-serif",
        }}
      >
        <DialogTitle>
          <Box
            className="succescComponentstyle-circleIcon"
            sx={{ position: "absolute", top: 5 }}
          >
            {" "}
            <CheckIcon
              className="succescComponentstyle-tick"
              sx={{ fontSize: "50px" }}
            />
          </Box>
          <Box
                      className="successTexturl"
            sx={{
             
              textTransform: "none",
              fontFamily: "'Open Sans', Calibri, Arial, Helvetica, sans-serif",
            }}
          >
            {t("Success")}
          </Box>
              </DialogTitle>
              <div className="gridoverflow">
        <DialogContent sx={{ marginTop: 5, color: "black" }} className='contentpopup'>
          <DialogContentText
                      id="alert-dialog-slide-description" className="urlscroll"
            sx={{
              fontFamily: "'Open Sans', Calibri, Arial, Helvetica, sans-serif",
            }}
          >
            {t("YourQRCodehasbeencreatedsuccessfully")}
            <br></br>
            {t("PressDonetoviewyourQRcodedetails")}
          </DialogContentText>
        </DialogContent>
                  <DialogActions className='buttondone'>
          <Tabs value={value} onChange={handleChange} className="tabs">
            <Tab
              
              label={t("Done")}
              sx={{
                color: "white",
                backgroundColor: " #4BB543",
              
                textTransform: "none",
                fontFamily:
                  "'Open Sans', Calibri, Arial, Helvetica, sans-serif",
              }}
              onClick={() => {
                setOpen(false);
              }}
              value={4}
            />
          </Tabs>
          {value === 4 && (
            <div>
              <MyQrCodes />
            </div>
          )}
                  </DialogActions>
                  </div>
      </Dialog>
    </div>
  );
}
