import axios from "axios";

export const getUrlQrCodeList = () => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/URL/GetURLQRCodeList")
}

export const getUrlQrCodeListById = (QrCodeId: any) => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/URL/GetURLQRCodeListById/" + QrCodeId + "")
}

export const addUrlQrCode = (data: any) => {
    return axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/URL/AddURLQRCode", data)
}

export const updateUrlQrCode = (data: any) => {
    return axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/URL/UpdateURLQRCode", data)
}
export const getUrlSource = async (data: any) => {
    return await axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/URL/GetURLSource", data)
}

export const CheckDuplicateUrl = (data: any) => {
    return axios.post(
        process.env.REACT_APP_API_ENDPOINT + "/api/QRCode/CheckQrCodeUrl",
        data
    );
};