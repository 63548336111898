import {
    DataGrid,
    getGridDateOperators,
    GridColDef,
    GridCsvExportMenuItem,
    GridToolbarExportContainer,
} from "@mui/x-data-grid";

import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { getLoggedInUser } from "../Authentication/Auth";
import { showErrorMessage } from "../../Notifications/Message";
import { getStatusWorkFlow } from "../Template/TemplateWorkFlow";
import { GetTemplateGridDateFormat } from "../../Utils/DateDisplay";
import { useTranslation } from "react-i18next";
import React from "react";
import { getMeetingBackgDetailsById, GetMeetingBackgListByUser, getMeetingTemplateById } from "../../Services/MeetingTemplate";
import { MeetingTemplateProps } from "../../Props/MeetingTemplateProps";
import MeetBackDownload from "../QrCodeUtils/DownloadMeetBack";

export default function MeetingBackgroundUserList({ }): JSX.Element {
    const [items, setItems] = useState<MeetingTemplateProps[]>([]);
    const { t } = useTranslation();
    const _UserName = getLoggedInUser();
    const [responseQr, setResponseQr] =
        useState<MeetingTemplateProps>({});
    useEffect(() => fetchQrCodes(), [_UserName]);

    function fetchQrCodes() {
        GetMeetingBackgListByUser(_UserName)
            .then(function (response: any) {


                setItems(response.data);
            })
            .catch(function (error: any) {
                showErrorMessage(t("Failedtogetmeetingbackgroundlist"));
            });
    }
    const handleQRDisplay = (QRCode: any) => {
        getMeetingBackgDetailsById(QRCode.MeetingId)
            .then(function (response: any) {
                setResponseQr(response.data);
            })
            .catch(function (error: any) { });
    };

    const columns: GridColDef[] = [

        {
            field: "MeetingName",
            headerName: `${t("TemplateName")}`,
            width: 150,
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "ActiveStatus",
            headerName: `${t("ActiveStatus")}`,
            width: 50,
            headerAlign: "left",
            align: "left",
            flex: 1,
            valueGetter: (params) => getStatusWorkFlow(params.value),
        },
        {
            field: "CreatedDate",
            headerName: `${t("Processedon")}`,
            type: "date",
            width: 70,
            headerAlign: "left",
            align: "left",
            flex: 1,
            valueFormatter: (params) =>
                moment(params?.value).format(GetTemplateGridDateFormat()),
            filterOperators: getGridDateOperators().filter(
                    (operator) => operator.value !== 'before' && operator.value !== 'after',
                ), 
        },
        {
            field: "ModifiedBy",
            headerName: `${t("ModifiedBy")}`,
            width: 130,
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "ModifiedDate",
            headerName: `${t("ModifiedDate")}`,
            type: "date",
            width: 700,
            headerAlign: "left",
            align: "left",
            flex: 1,
            valueFormatter: (params) =>
                params?.value != null
                    ? moment(params?.value).format(GetTemplateGridDateFormat())
                    : "",
            filterOperators: getGridDateOperators().filter(
                        (operator) => operator.value !== 'before' && operator.value !== 'after',
                    ), 
        },
        {
            field: "mm",
            headerName: `${t("DownloadQRlist")}`,
            width: 100,
            headerAlign: "left",
            align: "left",
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (row: any) => {
                return (
                    <span
                        onClick={() => {
                            handleQRDisplay(row.row);
                        }}
                    >
                        <MeetBackDownload
                            MeetingId={row.row.MBDetailsID}
                            status={row.row.ActiveStatus}
                        />
                    </span>
                );
            },
        },
    ];

    const GridToolbarExport = ({ csvOptions }: any) => (
        <GridToolbarExportContainer
            sx={{ textTransform: "capitalize" }}
            className="fontfamily"
        >
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 5,
        page: 0,
    });
    return (
        <>
            <DataGrid
                sx={{ width: "100%" }}
                className="vvvvsss"
                getRowId={(row) => row.MBDetailsID}
                rows={items}
                pageSizeOptions={[5, 10, 15]}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 5, page: 0 },
                    },
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                columns={columns}
                components={{
                    Toolbar: GridToolbarExport,
                }}
            />
        </>
    );
}
