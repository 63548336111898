export enum StatusWorkFlow {
    "Active" = 1,
    "Inactive" = 2,
    "Approved" = 3,
    "Pending" = 4,
    "Expired" = 5,
}

export function getStatusWorkFlow(workFlowId: number) {
    return StatusWorkFlow[workFlowId];
}