import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { getLoggedInUser } from "../Components/Authentication/Auth";
import { showErrorMessage, showSuccessMessage } from "./Message";
import {
    getStatusWorkFlow,
    StatusWorkFlow,
} from "../Components/Template/TemplateWorkFlow";
import { useTranslation } from "react-i18next";
import { UpdateMeetingTemplateDetails } from "../Services/MeetingTemplate";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
type Props = {
    QrMeetBack: any;
    fetchQrCodes: () => void;
};
export default function StatusChangeGeneratedMeetBack({
    QrMeetBack,
    fetchQrCodes,
}: Props) {
    const [open, setOpen] = React.useState(false);
    const { t, i18n } = useTranslation();
    function handleStatus(QrMeetBack: any, newStatus: any) {
        
        QrMeetBack.ActiveStatus = newStatus;
        QrMeetBack.ModifiedBy = getLoggedInUser();
        UpdateMeetingTemplateDetails(QrMeetBack)
            .then(function (response: any) {
               
                showSuccessMessage(
                    QrMeetBack.MeetingName + `${t("Statuschangedto")}` + getStatusWorkFlow(newStatus)
                );
                fetchQrCodes();
            })
            .catch(function (error: any) {
                showErrorMessage(t("Failedtoupdate"));
            });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                className="fontfamily buttonsize"
                variant="outlined"
                sx={{
                    Color: " #0076A8",
                    backgroundColor: "white",
                    textTransform: "none",
                }}
                onClick={handleClickOpen}
            >
                {QrMeetBack.ActiveStatus === StatusWorkFlow.Active
                    ? `${t("Expired")}`
                    : `${t("Activate")}`}
            </Button>

            <Dialog
                className="fontfamily"
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="fontfamily">{t("ConfirmAction")}</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        className="fontfamily"
                        id="alert-dialog-slide-description"
                    >
                        {t("Actionpopup")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="fontfamily"
                        variant="outlined"
                        sx={{
                            Color: " #0076A8",
                            backgroundColor: "white",
                            textTransform: "none",
                        }}
                        onClick={handleClose}
                    >
                        {t("Cancel")}
                    </Button>
                    <Button
                        className="fontfamily"
                        variant="outlined"
                        sx={{
                            Color: " #0076A8",
                            backgroundColor: "white",
                            textTransform: "none",
                            fontFamily: "Open Sans",
                        }}
                        onClick={() => {
                            handleStatus(
                                QrMeetBack,
                                QrMeetBack.ActiveStatus === StatusWorkFlow.Active
                                    ? StatusWorkFlow.Expired
                                    : StatusWorkFlow.Active
                            ),
                                handleClose();
                        }}
                    >
                        {t("Confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
