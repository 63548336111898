import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
20;
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { getMeetingTemplateById } from "../../../Services/MeetingTemplate";
import { t } from "i18next";
import CircularProgress from "@mui/material/CircularProgress";
type Props = {
  MeetingId: any;
};
const MeetingBGAdminView = ({ MeetingId }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(`${t("PreviewofMeetingBackground")}`);
  const [loader, setLoader] = React.useState(true);
  const [previewImg, setPreviewImage] = useState<string>();
  const [positioning, setPositioning] = useState<string>();
  const theme = useTheme();
  const handleClickOpen = () => {
      setOpen(true);
      setName(`${t("PreviewofMeetingBackground")}`);
  };

  const handleClose = (value: string) => {
    setOpen(false);
      setName(`${t("PreviewofMeetingBackground")}`);
  };
  const handleImagefetch = () => {
    setLoader(true);
    getMeetingTemplateById(MeetingId)
      .then(function (response) {
        setPreviewImage(response.data.MeetingPreview);
        setPositioning(response.data.Position);
        setName(response.data.MeetingName);
        setLoader(false);
      })
      .catch(function (response) {
        setLoader(false);
      });
  };
  let array: string | undefined = positioning;
  let TL = array?.includes("TL");
  let TR = array?.includes("TR");
  let BL = array?.includes("BL");
  let BR = array?.includes("BR");
  let TC = array?.includes("TC");
  let BC = array?.includes("BC");

  return (
    <>
      <Button
        className="texttranform fontfamily buttonsize"
        onClick={() => {
            handleClickOpen(), handleImagefetch();
            setName(`${t("PreviewOfMeetingBackGround")}`);
        }}
      >
        <RemoveRedEyeIcon className='meetingpreviewpopup' />
        {t("Preview")}
      </Button>

      <Dialog
        maxWidth="md"
        fullWidth={true}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle style={{ textAlign: "center", width: "100%" }}>
          {" "}
          {name}
          <CloseIcon
            className="hovering"
            onClick={() => {
                setOpen(false);

            }}
          />
        </DialogTitle>

        <hr style={{ margin: "10px" }}></hr>
        <DialogContent>
          {loader ? (
            <CircularProgress
              value={50}
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",

                marginTop: "-12px",
                marginLeft: "-12px",
              }}
              disableShrink
            />
          ) : (
            <>
              <div className="meetingpreviewcontainer">
                <img
                  src={previewImg}
                  width="100%"
                  height="100%"
                  style={{ position: "relative", marginLeft: 10 }}
                ></img>
                {TL ? (
                  <img
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "20px",
                    }}
                    src="DeloitteQRImg.png"
                    width="10%"
                    height="15%"
                  ></img>
                ) : (
                  ""
                )}
                {BL ? (
                  <img
                    style={{ position: "absolute", top: 372, left: 20 }}
                    src="DeloitteQRImg.png"
                    width="10%"
                    height="15%"
                  ></img>
                ) : (
                  ""
                )}
                {TR ? (
                  <img
                    style={{ position: "absolute", top: 12, right: 5 }}
                    src="DeloitteQRImg.png"
                    width="10%"
                    height="15%"
                  ></img>
                ) : (
                  ""
                )}

                {BR ? (
                  <img
                    style={{ position: "absolute", top: 372, right: 5 }}
                    src="DeloitteQRImg.png"
                    width="10%"
                    height="15%"
                  ></img>
                ) : (
                  ""
                )}
                {TC ? (
                  <img
                    style={{ position: "absolute", top: 12, right: 380 }}
                    src="DeloitteQRImg.png"
                    width="10%"
                    height="15%"
                  ></img>
                ) : (
                  ""
                )}
                {BC ? (
                  <img
                    style={{ position: "absolute", top: 372, right: 380 }}
                    src="DeloitteQRImg.png"
                    width="10%"
                    height="15%"
                  ></img>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default MeetingBGAdminView;
