import {
    DataGrid,
    getGridDateOperators,
    GridColDef,
    GridFilterModel,
    GridSortModel,
    GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { useState } from "react";
import React, { useEffect } from "react";
import moment from "moment";
import { getQrCodeList } from "../../Services/QrcodeList";
import {
    getStatusWorkFlow,
    StatusWorkFlow,
} from "./../Template/TemplateWorkFlow";
import { GetTemplateGridDateFormat } from "../../Utils/DateDisplay";
import { QRCodeProps } from "../../Props/QRCodeProps";
import { getUrlQrCodeListById } from "../../Services/Url";
import CustomizedDialogs from "./ViewDetailsUrl";
import QRDownload from "./../QrCodeUtils/DownloadQr";
import { DefaultQRCodeDetailsDefaultProps } from "../../Props/QRDeatailsProps";
import VieworEditVcard from "./VieworEditVcard";
import StatusChangeGeneratedQrs from "../../Notifications/StatusChangeGeneratedQrs";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { DefaultReportProps } from "../../Props/ReportProps";
import { GetAdminMetricList } from "../../Services/Report";
import VieworEditBCard from "./VieworEditBCard";

export default function QrCodesList({ }): JSX.Element {
    const { t, i18n } = useTranslation();
    const [items, setItems] = useState<QRCodeProps[]>([]);
    const [csvitems, setcsvItems] = useState<QRCodeProps[]>([]);
    const [report, setReport] = useState<DefaultReportProps[]>([]);
    const [emptyrow, setEmptyrow] = useState([]);
    const [responseData, setResponseData] = useState<QRCodeProps>({});
    const [responseQr, setResponseQr] =
        useState<DefaultQRCodeDetailsDefaultProps>({});

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: "",
            sort: "desc",
        },
    ]);

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 5,
        page: 0,
    });

    const [pagestate, setPageState] = useState({
        isLoading: true,
        total: 0,
    });
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
    });

    const _UserName = {
        PageNumber: paginationModel.page,
        PageSize: paginationModel.pageSize,
        SortBy: sortModel.length > 0 ? sortModel[0].field : "",
        SortType: sortModel.length > 0 ? sortModel[0].sort : "",
        Items: {
            columnField:
                filterModel.items.length > 0 ? filterModel.items[0].field : "",
            operatorValue:
                filterModel.items.length > 0 ? filterModel.items[0].operator : "",
            value: filterModel.items.length > 0 ? (filterModel.items[0].operator != "isAnyOf" ? filterModel.items[0].value : (String(filterModel.items[0].value).replace('[', '') == 'undefined' ? "" : String(filterModel.items[0].value).replace('[', ''))) : "",
        },
    };
    const data = {
        PageNumber: 0,
        PageSize: 0,
        SortBy: sortModel.length > 0 ? sortModel[0].field : "",
        SortType: sortModel.length > 0 ? sortModel[0].sort : "",
        Items: {
            columnField:
                filterModel.items.length > 0 ? filterModel.items[0].field : "",
            operatorValue:
                filterModel.items.length > 0 ? filterModel.items[0].operator : "",
            value: filterModel.items.length > 0 ? (filterModel.items[0].operator != "isAnyOf" ? filterModel.items[0].value : (String(filterModel.items[0].value).replace('[', '') == 'undefined' ? "" : String(filterModel.items[0].value).replace('[', ''))) : "",
        },
    };
    useEffect(() => {
        fetchQrCodes();
        getdata();
        getreportdata();
    }, [
        filterModel,
        sortModel,
        paginationModel.pageSize,
        paginationModel.page,
        pagestate.total,
    ]);

    function fetchQrCodes() {
        setPageState((old) => ({ ...old, isLoading: true }));

        setTimeout(() => {
            setItems(emptyrow);
        }, 10);

        getQrCodeList(_UserName)
            .then(function (response: any) {
                setTimeout(() => {
                    setItems(response.data.QrCodeList);
                }, 50);

                setPageState((old) => ({
                    ...old,
                    isLoading: false,
                    total: response.data.totalrecord,
                }));
            })
            .catch(function (error: any) {
                setPageState((old) => ({
                    ...old,
                    isLoading: false,
                    total: 0,
                }));
            });
    }
    function getdata() {
        getQrCodeList(data)
            .then(function (response: any) {
                const records = response.data.QrCodeList.map((record: any) => ({
                    Title: record.Title,
                    QRType: record.QRType,
                    CreatedDate: moment(record.CreatedDate).format(
                        GetTemplateGridDateFormat()
                    ),
                    ExpiryDate:
                        record.ExpiryDate != null
                            ? moment(record.ExpiryDate).format(GetTemplateGridDateFormat())
                            : null,
                    ActiveStatus: getStatusWorkFlow(record.ActiveStatus),
                }));
                setcsvItems(records);
              
                setPageState((old) => ({
                    ...old,
                    isLoading: false,
                    total: response.data.totalrecord,
                }));
            })
            .catch(function (error: any) {
                setPageState((old) => ({
                    ...old,
                    total: 0,
                }));
               
            });
    }
   
    function getreportdata() {
        GetAdminMetricList()
            .then(function (response: any) {
                const reportlist = response.data.map((reportlist: any) => ({
                    UserName: reportlist.UserName,
                    CarrerLevel: reportlist.CarrerLevel,
                    Status: getStatusWorkFlow(reportlist.Status),
                    MemberFirm: reportlist.MemberFirm,
                    ServiceLine: reportlist.ServiceLine,
                    CreatedDate: moment(reportlist.CreatedDate).format(GetTemplateGridDateFormat()),
                    CreatedBy: reportlist.CreatedBy,
                    ModifiedDate: reportlist.ModifiedDate != null
                        ? moment(reportlist.ModifiedDate).format(GetTemplateGridDateFormat())
                        : null,
                    ModifiedBy: reportlist.ModifiedBy,
                    TemplateName: reportlist.TemplateName,
                    QrType: reportlist.QrType,
                }))
              
                setReport(reportlist);
            })
            .catch(function (error: any) {
            });
    }

    const Reportheaders = [
        { key: "UserName", label: "User Name" },
        { key: "CarrerLevel", label: "Carrer Level" },
        { key: "Status", label: "Status" },
        { key: "MemberFirm", label: "Member Firm" },
        { key: "ServiceLine", label: "Service Line" },
        { key: "CreatedDate", label: "Created Date" },
        { key: "CreatedBy", label: "Created By" },
        { key: "ModifiedDate", label: "Modified Date" },
        { key: "ModifiedBy", label: "Modified By" },
        { key: "TemplateName", label: "Template Name" },
        { key: "QrType", label: "QRType" },
    ]

    const headers = [
        { key: "Title", label: "Name of QRcode" },
        { key: "QRType", label: "Type of QR code" },
        { key: "CreatedDate", label: "Created date" },
        { key: "ExpiryDate", label: "Expiration date" },
        { key: "ActiveStatus", label: "Status" },
    ];
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (QRCode: any, callback: any, cellVallues: any) => {
        getUrlQrCodeListById(QRCode)
            .then(function (response: any) {
                setResponseData(response.data);
                callback(response.data);
                return response.data;
            })
            .catch(function (error: any) {
                
            });
    };
    const handleQRDisplay = (QRCode: any) => {
        getUrlQrCodeListById(QRCode.QRCodeId)
            .then(function (response: any) {
                setResponseQr(response.data);
            })
            .catch(function (error: any) { });
    };

    const [modal, setModal] = useState(false);

    const openModal = () => {
        setModal(!modal);
    };

    const columns: GridColDef[] = [
        {
            field: "Title",
            headerName: `${t("NameofQRcode")}`,
            width: 180,
            flex: 1,
            headerAlign: "left",
            align: "left",
        },

        {
            field: "QRType",
            headerName: `${t("TypesofQRCode")}`,
            width: 180,
            flex: 1,
            headerAlign: "left",
            align: "left",
        },

        {
            field: "CreatedDate",
            headerName: `${t("Processedon")}`,
            type: "date",
            width: 160,
            headerAlign: "left",
            align: "left",
            flex: 1,
            valueFormatter: (params) =>
                moment(params?.value).format(GetTemplateGridDateFormat()),
            filterOperators: getGridDateOperators().filter(
                (operator) => operator.value !== 'before' && operator.value !== 'after',
            ),
        },

        {
            field: "ExpiryDate",
            headerName: `${t("Activetill")}`,
            type: "date",
            width: 120,
            headerAlign: "left",
            align: "left",
            flex: 1,
            valueFormatter: (params) =>
                params?.value != null
                    ? moment(params?.value).format(GetTemplateGridDateFormat())
                    : "",
            filterOperators: getGridDateOperators().filter(
                (operator) => operator.value !== 'before' && operator.value !== 'after',
            ),
            renderCell: (cellVallues: any) => {
                if (
                    cellVallues.row.QRType == "Vcard" ||
                    cellVallues.row.QRType == "Business Card"
                ) {
                    return "N/A";
                }
            },
        },

        {
            field: "ActiveStatus",
            headerName: `${t("ActiveStatus")}`,

            headerAlign: "left",
            align: "left",
            flex: 1,
            valueGetter: (params) => getStatusWorkFlow(params.value),
        },

        {
            field: "QrCodeId",
            headerName: `${t("QRcodeDetails")}`,
            width: 230,
            sortable: false,
            filterable: false, flex: 1.5,

            renderCell: (cellVallues: any) => {
                if (cellVallues.row.QRType == "Vcard") {
                    return (
                        <span>
                            <VieworEditVcard
                                QrCodeId={cellVallues?.row?.QRCodeId}
                                fetchQrCodes={fetchQrCodes}
                                rowData={cellVallues.row.ActiveStatus}
                                templateId={cellVallues.row.TemplateId}
                            />
                        </span>
                    );
                } else if (cellVallues.row.QRType == "Business Card") {
                    return (
                        <span>
                            <VieworEditBCard
                                QrCodeId={cellVallues?.row?.QRCodeId}
                                fetchQrCodes={fetchQrCodes}
                                rowData={cellVallues.row.ActiveStatus}
                                templateId={cellVallues.row.TemplateId}
                            />
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            <CustomizedDialogs
                                handleClickOpenDia={handleClickOpen}
                                QRCodeId={cellVallues?.row?.QRCodeId}
                                fetchQrCodes={fetchQrCodes}
                                status={cellVallues.row.ActiveStatus}
                                templateId={cellVallues.row.TemplateId}
                            />
                        </span>
                    );
                }
            },
        },

        {
            field: "QRCodeId",
            headerName: `${t("Action")}`,
            width: 140,
            sortable: false,
            filterable: false,
            headerAlign: "left",
            flex: 1,
            align: "left",

            renderCell: (row: any) => {
                return (
                    <StatusChangeGeneratedQrs
                        QrCodes={row.row}
                        fetchQrCodes={fetchQrCodes}
                    />
                );
            },
        },
        {
            field: "yy",
            headerName: `${t("DownloadQRlist")}`,
            width: 120,
            headerAlign: "left",
            align: "left",
            sortable: false,

            flex: 1,
            filterable: false,

            renderCell: (row: any) => {
                return (
                    <span
                        onClick={() => {
                            handleQRDisplay(row.row);
                        }}
                    >
                        <QRDownload
                            Qrcodeid={row.row.QRCodeId}
                            download={row.row.QRType}
                            status={row.row.ActiveStatus}
                            templateId={row.row.TemplateId}
                        />
                    </span>
                );
            },
        },
    ];

    const GridToolbarExport = ({ csvOptions }: any) => (
        <GridToolbarExportContainer sx={{ textTransform: 'capitalize' }} className="fontfamily fontresize">
            <CSVLink className='btn text-decoration-none' data={csvitems} headers={headers}
                filename={"DeloitteQRCodeGeneration.csv"}>Dowload as CSV</CSVLink> <br></br>
            <CSVLink className='btn text-decoration-none' data={report} headers={Reportheaders}
                filename={"AdminMetricInformation.csv"}>Admin metric information</CSVLink>
        </GridToolbarExportContainer>
    );

    return (
        <>
            {" "}
            <div>
                <DataGrid
                    className="vvvvsss"
                    sx={{ width: "100%" }}
                    loading={pagestate.isLoading}
                    getRowId={(row) => row.QRCodeId}
                    rows={items}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    hideFooterPagination={pagestate.isLoading}
                    pageSizeOptions={[5, 10, 15]}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    rowCount={pagestate.total}
                    filterModel={filterModel}
                    onFilterModelChange={(newFilterModel) => {
                       
                        return setFilterModel(newFilterModel);
                    }}
                    filterMode="server"
                    sortingMode="server"
                    columns={columns}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      
                        return setSortModel(newSortModel);
                    }}
                    components={{
                        Toolbar: GridToolbarExport,
                    }}
                />
            </div>
        </>
    );
}