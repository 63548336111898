import { Box } from "@mui/material";
import { useState } from "react";
import { TemplateProps } from "../../Props/TemplateProps";
import  BusinessCard  from "./BusinessCard";
import VCardComponent from "./VCardComponent";

type Props = {
    value: number;
   handleChange: (event: React.SyntheticEvent, newValue: number) => void;
};

function QRCodeGeneratorBusinessCard({value, handleChange }:Props) {
    const [previewData, setPreviewData] = useState<TemplateProps>({});
    return (
        <Box >
            <BusinessCard
                setPreviewData={setPreviewData}
                previewData={previewData}
                value={value}
                handleChange={handleChange }
               
            />
        </Box>
    );
}

export default QRCodeGeneratorBusinessCard;