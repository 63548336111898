import { Button, CircularProgress, Dialog, DialogTitle, TextField } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import moment from "moment";
import react, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { QRCodeProps } from "../../../Props/QRCodeProps";
import { DefaultVCardProps } from "../../../Props/VCardProps";
import { getQrCodeListByUser } from "../../../Services/Qrcode";
import { getVcardListById } from "../../../Services/Vcard";
import { GetTemplateGridDateFormat } from "../../../Utils/DateDisplay";
import {
  getMobilePhone,
  getLoggedInUser,
  getUserEmail,
  getUserEntityName,
  getUserFirstName,
  getUserLastName,
  getUserTitle,
} from "../../Authentication/Auth";
import { getStatusWorkFlow } from "../../Template/TemplateWorkFlow";
import CloseIcon from "@mui/icons-material/Close";
import { constructVCard } from "../../../Utils/VcfGenerator";
import { addMeetingBGdetails, getMeetingBackgDetailsById } from "../../../Services/MeetingTemplate";
import { showErrorMessage, showWarningMessage } from "../../../Notifications/Message";
type Props = {
  setQRTarget: React.Dispatch<React.SetStateAction<string>>;
  MeetingID: string | undefined;
  positionqr: string;
  qrdata: string;
  setsucessmsg: React.Dispatch<React.SetStateAction<boolean>>;
  setImagefinale: React.Dispatch<React.SetStateAction<string | undefined>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};
function VcardMeetingbg({
  setQRTarget,
  setImagefinale,
  positionqr,
  MeetingID,
  qrdata,
  setsucessmsg, setActiveStep
}: Props) {
  const [datalist, setDatalist] = useState<QRCodeProps[]>([]);
  const getphoneorigin = getMobilePhone();
  const getphone = `${getphoneorigin}`.replace("+", "")
  const [aMobile, setAMobile] = useState("");
  const [mobile, setMobile] = useState(getphone);
  const [vcardDetails, setVcardDetails] = useState<DefaultVCardProps>();
  const [open, setOpen] = useState(false);
  const _UserName = getLoggedInUser();
  type SimpleDialogProps = {
    open: boolean;
    onClose: () => void;
  };

  const UserRole = getUserTitle();
  const UserlastName = getUserLastName();
  const UserfirstName = getUserFirstName();
  const Userdeloitteentity = getUserEntityName();
  const UserEmail = getUserEmail();
  const [Userentity, setUserentity] = useState(Userdeloitteentity);
  const [isEntityEmpty, setIsEntityEmpty] = useState(false);
  const {
    reset,
    handleSubmit,
    control,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<DefaultVCardProps>({
    defaultValues: {
      QRCodeId: "",
      Title: "",
      EmployeeCode: "",
      FirstName: UserfirstName,
      LastName: UserlastName,
      UploadImage: "",
      Designation: UserRole,
      MobileNo: getMobilePhone(),
      AlternateMobileNo: "",
      DeloitteEntity: Userdeloitteentity,
      EmailId: UserEmail,
      CompanyName: "",
      Website: "",
      PersonalLinks: "",
    }
  });

  const [loader, setloader] = useState(false)
  const getDataFromlistvcard = (vcardid: string) => {
    getVcardListById(vcardid)
      .then(function (response) {
        setValue("MobileNo", `${response.data.MobileNo}`);
        setMobile(response.data.MobileNo);
        setAMobile(response.data.AlternateMobileNo);

        let a = response.data.AlternateMobileNo;
        if (a !== null) {
          setValue("AlternateMobileNo", `${response.data.AlternateMobileNo}`);

        }

        setVcardDetails(response.data);
        constructQR();
      })
      .catch(function (response) {

      });
  };
  const handleVcardchanges = (data: any) => {
    setMobile(data.MobileNo);
    setAMobile(data.AlternateMobileNo);
    constructQR()

    let a = qrdata.split('href="');
    let b = a.at(1)?.trimEnd().split("/> </svg>").at(0);
    let c = b?.split(" ").at(1);
    const vcf = constructVCard(
      UserfirstName,
      UserlastName,
      UserRole,
      Userdeloitteentity,
      UserEmail,
      data.MobileNo,
      data.AlternateMobileNo,
      undefined,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );

    setQRTarget(vcf);
    const params = {
      MeetingId: MeetingID,
      Position: positionqr,
      MeetingQRImage: c,
      CreatedBy: getLoggedInUser(),
    };
    addMeetingBGdetails(params)
      .then(function (response) {
        setsucessmsg(true);

        getMeetingBackgDetailsById(response.data).then(function (response) {

          setImagefinale(response.data.MeetingBgWithQr)
        })
      })
      .catch(function (response: any) {

        if (response.response.status == 500) {
          showErrorMessage(
            `${t("SelectinganyoftheallowedPositiontoplaceQRisrequired")}`
          );
          return (
            setActiveStep((prevActiveStep) => prevActiveStep - 2)
          )

        }
        if (response.response.status == 409) {
          showWarningMessage(
            `${t("MeetingBackgroundwithsimilarparametersalreadyexists")}`
          );
        }



      });

  };
  useEffect(() => {
    constructQR();
  }, [mobile, aMobile]);
  function constructQR() {
    const a = constructVCard(
      UserfirstName,
      UserlastName,
      UserRole,
      Userdeloitteentity,
      UserEmail,
      mobile,
      aMobile,
      undefined,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    setQRTarget(a);

    return a;
  }



  useEffect(() => {

    setloader(true)
    getQrCodeListByUser(_UserName)
      .then(function (response: any) {
        {

          response.data.map((item: any) => {
            if (item.QRType === "Vcard") {
              if (item.ActiveStatus !== 5) {
                datalist.push(item);

              }

            }
            setloader(false)
          }

          );
        }
      })
      .catch(function (error: any) { });
  }, [_UserName]);
  function SimpleDialog(props: SimpleDialogProps) {
    const { onClose, open } = props;
    const handleClose = () => {
      onClose();
    };

    const columns: GridColDef[] = [
      {
        field: "Title",
        headerName: `${t("NameofQRcode")}`,
        width: 180,
        flex: 1,
        headerAlign: "left",
        align: "left",
      },

      {
        field: "QRType",
        headerName: `${t("TypesofQRCode")}`,
        width: 180,
        flex: 1,
        headerAlign: "left",
        align: "left",
      },

      {
        field: "CreatedDate",
        headerName: `${t("Processedon")}`,
        type: "date",
        width: 160,
        headerAlign: "left",
        align: "left",
        flex: 1,
        valueFormatter: (params) =>
          moment(params?.value).format(GetTemplateGridDateFormat()),
      },

      {
        field: "ExpiryDate",
        headerName: `${t("Activetill")}`,
        type: "date",
        width: 120,
        headerAlign: "left",
        align: "left",
        flex: 1,
        valueFormatter: (params) =>
          params?.value != null
            ? moment(params?.value).format(GetTemplateGridDateFormat())
            : "",
        renderCell: (cellVallues: any) => {
          if (
            cellVallues.row.QRType == "Vcard" ||
            cellVallues.row.QRType == "Business Card"
          ) {
            return "N/A";
          }
        },
      },

      {
        field: "ActiveStatus",
        headerName: `${t("ActiveStatus")}`,
        width: 100,
        headerAlign: "left",
        align: "left",
        flex: 1,
        valueGetter: (params) => getStatusWorkFlow(params.value),
      },

      {
        field: "",
        width: 180,
        sortable: false,
        filterable: false,
        flex: 1,
        renderCell: (row: any) => {
          return (
            <Button sx={{ textTransform: "none" }} className='buttonSize'
              onClick={() => {
                getDataFromlistvcard(row.row.QRCodeId), handleClose();
              }}
            >
              {t("Select")}
            </Button>
          );
        },
      },
    ];
    return (
      <Dialog fullWidth={true} onClose={handleClose} open={open}>
        <DialogTitle>
          {t("ChooseFromExistingQR")}
          <CloseIcon onClick={handleClose} className="closeiconinMBG" />
        </DialogTitle>
        <div style={{ height: 400, width: "100%" }}>
          {loader ?
            <CircularProgress
              value={50}
              sx={{
                position: "relative",
                left: "20%",
                height: "100%",
                marginTop: "20%",
                marginBottom: "20%",
                marginLeft: "30%",
              }}
              disableShrink
            /> :
            <DataGrid
              getRowId={(datalist) => datalist.QRCodeId}
              rows={datalist}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 15]}
            />}
        </div>
      </Dialog>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>

        <Button variant="outlined"
          className="buttoninurlinmeetingbg"
          onClick={() => {
            handleClickOpen(), reset();
          }} disabled={datalist.length == 0}
        >
          {t("ChooseFromExisting")}
        </Button>
        <SimpleDialog open={open} onClose={handleClose} />
        <div className="contentVcard">
          <TextField
            placeholder={`${t("EnteryourFirstName")}`}
            label={t("FirstName")}
            size="medium"
            variant="filled"
            value={UserfirstName}
            InputProps={{
              readOnly: true,
              style: { fontSize: 14, top: 2, fontWeight: "normal" },
            }}
          />
          <TextField
            className="lastnamevcard"
            placeholder={`${t("EnteryourLastName")}`}
            label={t("LastName")}
            size="medium"
            variant="filled"
            value={UserlastName}
            InputProps={{
              readOnly: true,
              style: { fontSize: 14, top: 2, fontWeight: "normal" },
            }}
          />
          <TextField
            className="jobTitle"
            placeholder={`${t("EnterYourJobTitle")}`}
            label={t("JobTitle")}
            size="medium"
            variant="filled"
            value={UserRole}
            InputProps={{
              readOnly: true,
              style: { fontSize: 14, top: 2, fontWeight: "normal" },
            }}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField required
            className="deloitteEntity"
            placeholder={`${t("EnterYourDeloitteEntity")}`}
            size="medium"
            sx={{ textTransform: "none", border: "1px solid #ccc" }}
            label={t("DeloitteEntity")}
            variant="filled"
            value={Userentity}
            onChange={(e) => {
              setUserentity(e.target.value);
              setIsEntityEmpty(e.target.value.trim() === "");
            }}
            InputProps={{
              style: { fontSize: 14, top: 2, fontWeight: "normal", backgroundColor: "white" },
            }}
          />
          {isEntityEmpty && (
            <p style={{ color: 'red', fontSize: 12, margin: 10 }}>
              Please fill this field!
            </p>
          )}
          </div>

          <TextField
            className="emailId"
            label={t("EmailId")}
            size="medium"
            variant="filled"
            placeholder={`${t("EnteryourEmailAddress")}`}
            value={UserEmail}
            InputProps={{
              readOnly: true,
              style: { fontSize: 14, top: 2, fontWeight: "normal" },
            }}
          />
          <label htmlFor="phone-input" className="FontsizinginmBG">
            {t("MobileNo")} *
            <Controller
              name="MobileNo"
              defaultValue={getphone}
              control={control}
              rules={{ required: true, minLength: 7 }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PhoneInput

                  placeholder={`${t("MobileNoPlaceholder")}`}
                  dropdownStyle={{
                    position: "absolute",
                    top: "-215px",
                  }}
                  inputProps={{
                    name: "MobileNo",
                    style: {
                      fontSize: 12,
                    },
                  }}
                  enableAreaCodes
                  autoFormat
                  autocompleteSearch
                  value={value}
                  onChange={(e) => {
                    onChange(e), setMobile(e), constructQR()

                  }}
                />
              )}
            />
            {errors?.MobileNo?.type == "required" && (
              <>
                <span className="alertColor">{t("Thisfieldisrequired")}</span>

              </>
            )}
            {errors?.MobileNo?.type == "minLength" && (
              <span className="alertColor">{t("PhoneNumberisnotValid")}</span>
            )}
          </label>
          <label htmlFor="AlternateMobileNo" className="FontsizinginmBG">
            {t("OfficeNo")}
            <Controller
              name="AlternateMobileNo"
              control={control}
              rules={{ minLength: 7 }}
              render={({ field: { value, onChange } }) => (
                <PhoneInput
                  disableSearchIcon
                  enableSearch
                  placeholder={`${t("MobileNoPlaceholder")}`}
                  dropdownStyle={{
                    position: "absolute",
                    top: "-215px",
                  }}
                  value={value}
                  autoFormat
                  onChange={(e) => {
                    onChange(e), setAMobile(e), constructQR();
                  }}
                  inputProps={{
                    style: { fontSize: 12 },
                  }}
                />
              )}
            />
            {errors?.AlternateMobileNo?.type == "minLength" && (
              <p className="alertColor">{t("PhoneNumberisnotValid")}</p>
            )}{" "}
          </label>

        </div>
      </div>
      <Button
        variant="contained"
        sx={{
          height: "10%", color: "white",
          backgroundColor: "#0076A8",
        }}
        className="submitinMBGv fontbutton1 fontfamily"
        onClick={handleSubmit(handleVcardchanges)}
      >
        {t("Submit")}
      </Button > </>
  );
}
export default VcardMeetingbg;
