import { Box, Button, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import {
    getLoggedInDisplayName,
    IsTranslationRequired,
} from "../Authentication/Auth";

function Mainbar() {
    const { instance } = useMsal();
   

    const changeLanguage = (lng: any) => {
        i18n.changeLanguage(lng.target.value);
    };
    const { t, i18n } = useTranslation();
   

    return (
        <>
            <Box className="mainBar">
                <Box className="mainBar1">
                    <img className="deloitteImg" src="delogo.jpg"></img>
                    <Typography className="qrCodePortal fontfamily">
                        {t("QRCodePortal")}
                    </Typography>
                </Box>
                {!IsTranslationRequired() ? (
                    ""
                ) : (
                    <div style={{ marginTop: "15px", marginRight: "15px", }}  >

                        <select className='language' onChange={(e) => changeLanguage(e)}>
                            <option value="">Select your language</option>
                            <option value="en">English</option>
                            <option value="es">Español</option>
                            <option value="fr">Français</option>
                            <option value="prt">Português</option>
                            <option value="ger">Deutsch</option>
                            <option value="it">Italiano</option>
                            <option value="jp">日本</option>
                            <option value="CHT">中國傳統的</option>
                            <option value="Hans">简体中文</option>
                        </select>
                    </div>
                )}
                <div className="divHeader">
                    <Typography className="fonSize login fontfamily language">
                        {getLoggedInDisplayName()}
                    </Typography>

                    <Button
                        className="fontfamily language"
                        sx={{ textTransform: "none" }}
                        onClick={() => {
                            signOutClickHandler(instance);
                        }}
                    >
                        <img src="logout.svg" />
                        {`${t("LogOut")}`}
                    </Button>
                </div>
            </Box>

          
        </>
    );
}
export default Mainbar;

function signOutClickHandler(instance: any): void {
    instance.logoutRedirect();
}
