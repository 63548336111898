import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { downloadQrCode, downloadQrCodeFromBase64, getTempDivId } from "../../Utils/DownLoad";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { getVcardListById } from "../../Services/Vcard";
import { getUrlQrCodeListById } from "../../Services/Url";
import { useTranslation } from "react-i18next";
import { StatusWorkFlow } from "./../Template/TemplateWorkFlow";
import { getBusinessCardListById } from "../../Services/BusinessCard";
import { DefaultBusinessCardProps } from "../../Props/BusinessCardProps";
import { getPkPass } from "../../Services/PkPass";
import { getQrTemplateById } from "../../Services/QrTemplate";
import QrCodeDisplayableReact from "./QrCodeDisplayableReact";
import { DefaultTemplateProps } from "../../Props/TemplateProps";
import { GetDynamicScannerUrl } from "../../authConfig";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },

}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle className="fontfamily downloadqrpopup" {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
type Props = {
    download: any;
    Qrcodeid: any;
    status: any;
    templateId:any
};

export default function QRDownload({ download, Qrcodeid, status, templateId }: Props) {
    const [open, setOpen] = React.useState(false);

    const [downloadImage, setDownloadImage] = React.useState<DefaultTemplateProps>({
        Height: 350,
        Width: 350,
    });
    var dynamicUrl = GetDynamicScannerUrl() + "Scanner/Navigate?qrcodeid=";
    const [downloadOption, setdownloadOption] = React.useState("png");

    const handleChange = (event: { target: { value: string } }) => {
        setdownloadOption(event.target.value);
    };
    const [responseQr, setResponseQr] = React.useState<any>("" as any);
    const [pkPass, setPkPass] = React.useState<any>("" as any);
    const { t, i18n } = useTranslation();

    const [templateImage, setTemplateImage] = React.useState<any>("" as any);


    const handleClickOpen = () => {
        debugger;
        const EndPoint = process.env.REACT_APP_API_ENDPOINT;
        if (download == "Vcard") {
            getQrTemplateById(templateId).then(function (response) {

                setTemplateImage(response.data);
            });

            getVcardListById(Qrcodeid).then(function (response) {

                setResponseQr(response.data);
            });
            setOpen(true);
        } else if (download == "Business Card") {
            getQrTemplateById(templateId).then(function (response) {

                setTemplateImage(response.data);
            });
            getBusinessCardListById(Qrcodeid).then(function (response) {

                setResponseQr(response.data);
            });
            setOpen(true);
        }
        else {
            getQrTemplateById(templateId).then(function (response) {

                setTemplateImage(response.data);
            });
            getUrlQrCodeListById(Qrcodeid).then(function (response: any) {

                setResponseQr(response.data);
            });
            setOpen(true);
        }

       
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                sx={{ textTransform: "none" }}
                variant="outlined"
                onClick={() => {
                    handleClickOpen();
                }}
                className="fontfamily buttonsize"
                disabled={status === StatusWorkFlow.Expired}
            >
                {t("DownloadQRlist")}
            </Button>

            <BootstrapDialog className='popupdownload'

                fullWidth={true}
                maxWidth='md'
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                sx={{
                    fontFamily:
                        "'Open Sans', Calibri, Arial, Helvetica, sans-serif, sans-serif",

                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    {t("DownloadQRImage")}
                </BootstrapDialogTitle>

                <div className='gridoverflow1 gridoverflow2'>

                    <DialogContent className="downloadimg"
                        dividers
                        sx={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                        }}
                    >   <br></br>
                        {/* <img src={responseQr?.QRImage} className='mobileviewdownload' />*/}
                        <div className='mobileviewdownload'>
                        <QrCodeDisplayableReact
                            TargetUrl={dynamicUrl + Qrcodeid}
                            RenderType={"svg"}
                            level={"H"}
                            marginRequired={true}
                            DivId={getTempDivId()}
                            ForeColor={templateImage.ForeColor}
                            BackgroundColor={templateImage.BackgroundColor}
                            Logo={templateImage.Logo}
                            Height={downloadImage.Height}
                            Width={downloadImage.Width}

                            TemplateId={templateId}
                            CreatedDate={new Date()}
                            ModifiedBy={""}

                            ModifiedDate={new Date()}
                            ActiveStatus={""}
                            TemplatePreview={""}
                           
                            />
                        </div>

                    </DialogContent>

                    <DialogActions>
                        <Button
                            className="fontfamily"
                            sx={{
                                color: "white",
                                backgroundColor: "#0076A8",
                                textTransform: "none",
                            }}
                            variant="contained"
                            onClick={() => {
                                
                                const ios = (/iPad|iPhone|iPod/.test(navigator.platform) ||
                                    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));

                                if (ios) {
                                  

                                    getPkPass(Qrcodeid).then(function (response) {
                                        const fileBlob = new Blob([response.data], { type: 'application/vnd.apple.pkpass' });
                                        const fileURL = window.URL.createObjectURL(fileBlob);
                                        const downloadLink = document.createElement('a');
                                        downloadLink.href = fileURL;
                                        downloadLink.download = 'Businesscard.pkpass';
                                        downloadLink.style.display = 'none';
                                        document.body.appendChild(downloadLink);
                                        downloadLink.click();
                                        window.URL.revokeObjectURL(fileURL);
                                        document.body.removeChild(downloadLink);
                                    });
                                    const qrDivId = getTempDivId();
                                    if (qrDivId != undefined) {
                                        downloadQrCode(
                                            qrDivId,
                                            downloadOption,
                                            800,
                                            800
                                        );
                                    }
                                }
                                else {
                                    const qrDivId = getTempDivId();
                                    if (qrDivId != undefined)
                                       downloadQrCode (
                                            qrDivId,
                                            downloadOption, 6000, 6000
                                        );


                                }

                            }}
                        >
                            {t("DownloadQRlist")}
                        </Button>

                        <FormControl sx={{ m: 1, marginTop: -1 }} variant="standard">
                            <InputLabel
                                className="fontfamily"
                                htmlFor="demo-customized-select-native"
                            ></InputLabel>
                            <NativeSelect
                                id="demo-customized-select-native"
                                value={downloadOption}
                                onChange={handleChange}
                                sx={{ color: "#0076A8", backgroundColor: "white" }}
                            >
                                <option value={"png"}>PNG</option>
                                <option value={"jpeg"}>JPEG</option>
                                <option value={"svg"}>SVG</option>
                                <option value={"pdf"}>PDF</option>
                            </NativeSelect>
                        </FormControl>

                        <Button
                            className="fontfamily"
                            variant="outlined"
                            sx={{ textTransform: "none" }}
                            autoFocus
                            onClick={handleClose}
                        >
                            {t("Close")}
                        </Button>
                        <br></br>
                    </DialogActions>
                </div>
            </BootstrapDialog>
        </div>

    );
}
