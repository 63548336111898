import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TemplateSlideBar from "./TemplateSlideBar";
import { DefaultTemplateProps, TemplateProps } from "../../Props/TemplateProps";
import {
    Box,
    Button,
    Grid,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import QrCodeDisplayable from "../QrCodeUtils/QrCodeDisplayable";
import { downloadQrCodecAsBase64, getTempDivId } from "../../Utils/DownLoad";
import {
    getLoggedInUser,
    getMemberFirm,
    getUserEmail,
    getUserFirstName,
    getUserLastName,
    getMobilePhone,
    getWorkPhone,
} from "../Authentication/Auth";
import { DefaultQRCodeDetailsDefaultProps } from "../../Props/QRDeatailsProps";
import { addQrCode, CheckDuplicateBcard } from "../../Services/Qrcode";
import { addQrDetail } from "../../Services/QrDetail";
import "react-phone-input-2/lib/style.css";
import {
    showErrorMessage,
    showWarningMessage,
} from "../../Notifications/Message";
import { constructVCard, constructBCard } from "../../Utils/VcfGenerator";
import { DefaultBusinessCardProps } from "../../Props/BusinessCardProps";
import { addBusinessCard, GetBusinessCardDoct, getOnePeopleMasterBCard } from "../../Services/BusinessCard";
import ConfirmPopupBcard from "../../Notifications/ConfirmPopupBcard";

type Props = {
    setPreviewData: Dispatch<SetStateAction<TemplateProps>>;
    previewData: TemplateProps;
    value: number;
    handleChange: (event: React.SyntheticEvent, newValue: number) => void;
};

export default function BusinessCard({
    setPreviewData,
    previewData,
    value,
    handleChange,
}: Props) {
    const { t, i18n } = useTranslation();

    const opmmobile = getMobilePhone();
    const [mobile, setMobile] = useState<any>(opmmobile);
    const [country, setCountry] = useState("" as any);
    const opmbusmblnum = getWorkPhone();
  

    const [bCard, setBCard] = useState({
        QRCodeId: "",
        FIRST_NAME: getUserFirstName(),
        LAST_NAME: getUserLastName(),
        EMAIL_ADDRESS: getUserEmail(),
        ORGANIZATION_DESCRIPTION: "",
        RELATED_EMPLOYEE_TELEPHONE_NUMBER: opmmobile,
        JOB_TITLE_DESCRIPTION: "",
        DESIGNATION_DESCRIPTION: "",
        MAILING_ADDRESS_CITY_DESCRIPTION: "",
        MAILING_ADDRESS_COUNTRY_DESCRIPTION: "",
        MAILING_ADDRESS_LINE_1: "",
        MAILING_ADDRESS_LINE_2: "",
        MAILING_ADDRESS_LINE_3: "",
        MAILING_ADDRESS_POSTAL_CODE: "",
        TELEPHONE_NUMBER: opmbusmblnum,
        CreatedDate: new Date(),
    });
   

    const [templateID, setTemplateID] = useState("");
    const [image, setImage] = useState("")
    const [selectedTab, setSelectedTab] = useState(0);
    const changeTemplateID = (value: string) => {
        setTemplateID(value);
    };
    const [QRDetails] = useState<DefaultQRCodeDetailsDefaultProps>({
        QRCodeId: "",
        QRName: "",
        QRImage: "",
    });
    const {
        handleSubmit,
        reset,
        register,
        control,
        formState: { errors },
    } = useForm<DefaultBusinessCardProps>();

    const [languageChoose, setLanguageChoose] = useState('');
    const handleChooseLanguage = (e: any) => {
        setLanguageChoose(e.target.value);
    }

    const handletab1 = () => {
        setSelectedTab(0)
    }
    const handletab2 = () => {
        setSelectedTab(1)
    }
    const handletab3 = () => {
        setSelectedTab(2)
    }

    const [template, setTemplate] = useState<DefaultTemplateProps>({
        Height: 160,
        Width: 160,
    });


    

    const HandleTelKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedChars = ['+', '.',' ', ...Array.from({ length: 10 }, (_, i) => i.toString())];
        const pressedKey = event.key;
    
     
    
        if (!allowedChars.includes(pressedKey)) {
          event.preventDefault();
        }
      };

    const handChange = (event: any) => {
        const { name, value } = event.target;

        setBCard((prevState: any) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };
    const [open, setOpen] = React.useState(false);
    const onsubmit = async (data: any) => {
      
            bCard.FIRST_NAME == data.FIRST_NAME,
            bCard.LAST_NAME == data.LAST_NAME,
            bCard.EMAIL_ADDRESS == data.EMAIL_ADDRESS,
            bCard.ORGANIZATION_DESCRIPTION == data.ORGANIZATION_DESCRIPTION,
            bCard.RELATED_EMPLOYEE_TELEPHONE_NUMBER == data.RELATED_EMPLOYEE_TELEPHONE_NUMBER,
            bCard.JOB_TITLE_DESCRIPTION == data.JOB_TITLE_DESCRIPTION,
            bCard.DESIGNATION_DESCRIPTION == data.DESIGNATION_DESCRIPTION,
            bCard.MAILING_ADDRESS_CITY_DESCRIPTION == data.MAILING_ADDRESS_CITY_DESCRIPTION,
            bCard.CreatedDate == data.CreatedDate,
            bCard.MAILING_ADDRESS_COUNTRY_DESCRIPTION == data.COUNTRY_DESC,
            bCard.MAILING_ADDRESS_LINE_1 == data.MAILING_ADDRESS_LINE_1,
            bCard.MAILING_ADDRESS_LINE_2 == data.MAILING_ADDRESS_LINE_2,
            bCard.MAILING_ADDRESS_LINE_3 == data.MAILING_ADDRESS_LINE_3,
            bCard.MAILING_ADDRESS_POSTAL_CODE == data.MAILING_ADDRESS_POSTAL_CODE,
            bCard.TELEPHONE_NUMBER == data.TELEPHONE_NUMBER;

        const qrCode = {
            TemplateId: templateID,
            QRType: "Business Card",
            CreatedBy: getLoggedInUser(),
            MemberFirmId: getMemberFirm(),
        };

        const checkBcardExists = {
            FIRST_NAME: data.FIRST_NAME,
            LAST_NAME: data.LAST_NAME,
            EMAIL_ADDRESS: data.EMAIL_ADDRESS,
            ORGANIZATION_DESCRIPTION: data.ORGANIZATION_DESCRIPTION,
            RELATED_EMPLOYEE_TELEPHONE_NUMBER: data.RELATED_EMPLOYEE_TELEPHONE_NUMBER,
            JOB_TITLE_DESCRIPTION: data.JOB_TITLE_DESCRIPTION,
            DESIGNATION_DESCRIPTION: data.DESIGNATION_DESCRIPTION,
            MAILING_ADDRESS_CITY_DESCRIPTION: data.MAILING_ADDRESS_CITY_DESCRIPTION,
            CreatedDate: data.CreatedDate,
            MAILING_ADDRESS_COUNTRY_DESCRIPTION: data.COUNTRY_DESC,
            MAILING_ADDRESS_LINE_1: data.MAILING_ADDRESS_LINE_1,
            MAILING_ADDRESS_LINE_2: data.MAILING_ADDRESS_LINE_2,
            MAILING_ADDRESS_LINE_3: data.MAILING_ADDRESS_LINE_3,
            MAILING_ADDRESS_POSTAL_CODE: data.MAILING_ADDRESS_POSTAL_CODE,
            TELEPHONE_NUMBER: data.TELEPHONE_NUMBER,
            TemplateId: templateID
        };

        CheckDuplicateBcard(checkBcardExists)
            .then(function (response) {
                addQrCode(qrCode)
                    .then(function (response) {
                        if (response.data != "" && response.status == 201) {
                            data.QRCodeId = response.data;
                            addBusinessCard(data)
                                .then(function (response) {
                                    if (response.status == 201) {
                                        QRDetails.QRCodeId = data.QRCodeId;
                                        QRDetails.QRName = data.FIRST_NAME;
                                        QRDetails.QRImage = downloadQrCodecAsBase64(getTempDivId());
                                        addQrDetail(QRDetails)
                                            .then(function (response) {
                                                setOpen(true);
                                            })
                                            .catch(function (error) {
                                                if (error.response.status == 409)
                                                    showWarningMessage(
                                                        `${t("Qrcodewithsimilarparameteralreadyexists")}`
                                                    )
                                            })
                                    }
                                })

                                .catch(function (error) {
                                  
                                    showErrorMessage(t("FailedtocreateQRcode"));
                                });
                        }
                    })

                    .catch(function (error) {
                      
                        showErrorMessage(`${t("FailedtocreateQRcodeforBusinesscard")}`);
                    });
            })
            .catch(function (error) {
                if (error.response.status == 409)
                    showWarningMessage(`${t("Qrcodewithsimilarparameteralreadyexists")}`);
            });
    };

    const handleNext = () => {
        setSelectedTab(selectedTab + 1);
    };
    const handleBack = () => {
        setSelectedTab(selectedTab - 1);
    };

    function constructQR(props: DefaultBusinessCardProps) {
        return constructBCard(
            bCard.FIRST_NAME,
            bCard.LAST_NAME,
            bCard.JOB_TITLE_DESCRIPTION,
            bCard.DESIGNATION_DESCRIPTION,
            bCard.EMAIL_ADDRESS,
            bCard.RELATED_EMPLOYEE_TELEPHONE_NUMBER,
            bCard.TELEPHONE_NUMBER,
            "",
            bCard.ORGANIZATION_DESCRIPTION,
            "",

            bCard.MAILING_ADDRESS_LINE_1,
            bCard.MAILING_ADDRESS_LINE_2,
            bCard.MAILING_ADDRESS_CITY_DESCRIPTION,
            bCard.MAILING_ADDRESS_LINE_3,
            bCard.MAILING_ADDRESS_POSTAL_CODE,
            bCard.MAILING_ADDRESS_COUNTRY_DESCRIPTION,
            ""

        );
    }
   
    const UserEmail = getUserEmail();

    useEffect(() => {
        getopmdatails(UserEmail);
    }, []);

    function getopmdatails(UserEmail: any) {
        getOnePeopleMasterBCard(UserEmail)
            .then(function (response) {
              
                setBCard({
                    ...bCard,
                    FIRST_NAME: response.data.FIRST_NAME,
                    LAST_NAME: response.data.LAST_NAME,
                    EMAIL_ADDRESS: response.data.EMAIL_ADDRESS,
                    ORGANIZATION_DESCRIPTION: response.data.ORGANIZATION_DESCRIPTION,
                    RELATED_EMPLOYEE_TELEPHONE_NUMBER: response.data.RELATED_EMPLOYEE_TELEPHONE_NUMBER,
                    JOB_TITLE_DESCRIPTION: response.data.JOB_TITLE_DESCRIPTION,
                    DESIGNATION_DESCRIPTION: response.data.DESIGNATION_DESCRIPTION,
                    MAILING_ADDRESS_CITY_DESCRIPTION: response.data.MAILING_ADDRESS_CITY_DESCRIPTION,
                    MAILING_ADDRESS_COUNTRY_DESCRIPTION: response.data.MAILING_ADDRESS_COUNTRY_DESCRIPTION,
                    MAILING_ADDRESS_LINE_1: response.data.MAILING_ADDRESS_LINE_1,
                    MAILING_ADDRESS_LINE_2: response.data.MAILING_ADDRESS_LINE_2,
                    MAILING_ADDRESS_LINE_3: response.data.MAILING_ADDRESS_LINE_3,
                    MAILING_ADDRESS_POSTAL_CODE: response.data.MAILING_ADDRESS_POSTAL_CODE,
                    TELEPHONE_NUMBER: response.data.TELEPHONE_NUMBER,
                   
                })
                
            })
            .catch(function (error) {
           
                showErrorMessage(t("Failedtogetdetails"));
            }
            );
    }

    useEffect(() => {
        getdocuments(); 
    }, [bCard, languageChoose]);

    const document_data = {
        "FIRST_NAME": `${bCard.FIRST_NAME}`,
        "LAST_NAME": `${bCard.LAST_NAME}`,
        "Language": `${languageChoose}`,
        "EMAIL_ADDRESS": `${bCard.EMAIL_ADDRESS}`,
        "ORGANIZATION_DESCRIPTION": `${bCard.ORGANIZATION_DESCRIPTION}`,
        "RELATED_EMPLOYEE_TELEPHONE_NUMBER": `${bCard.RELATED_EMPLOYEE_TELEPHONE_NUMBER}`,
        "JOB_TITLE_DESCRIPTION": `${bCard.JOB_TITLE_DESCRIPTION}`,
        "DESIGNATION_DESCRIPTION": `${bCard.DESIGNATION_DESCRIPTION}`,
        "MAILING_ADDRESS_CITY_DESCRIPTION": `${bCard.MAILING_ADDRESS_CITY_DESCRIPTION}`,
        "MAILING_ADDRESS_COUNTRY_DESCRIPTION": `${bCard.MAILING_ADDRESS_COUNTRY_DESCRIPTION}`,
        "MAILING_ADDRESS_LINE_1": `${bCard.MAILING_ADDRESS_LINE_1}`,
        "MAILING_ADDRESS_LINE_2": `${bCard.MAILING_ADDRESS_LINE_2}`,
        "MAILING_ADDRESS_LINE_3": `${bCard.MAILING_ADDRESS_LINE_3}`,
        "MAILING_ADDRESS_POSTAL_CODE": `${bCard.MAILING_ADDRESS_POSTAL_CODE}`,
        "TELEPHONE_NUMBER": `${bCard.TELEPHONE_NUMBER}`,
    }
    
    function getdocuments() {
        GetBusinessCardDoct(document_data) 
            .then(function (response: any) {
                const anchorElement = document.createElement('a');
                anchorElement.href = 'data:image/png;base64,' + response.data;
                setImage(response.data)

            })
    };
   
    return (
        <>
            <div className="rightside" >
                <div >
                    {
                        <TemplateSlideBar
                            changeTemplateID={changeTemplateID}
                            setPreviewData={setPreviewData}
                            previewData={previewData}
                        />
                    }
                </div>
                <Box >
                    <h5 style={{ color: "black", marginTop: '14px' }}>
                        {t("BusinessCardInformation")}
                    </h5>

                    <form>
                        <Tabs
                            value={selectedTab}
                          
                          
                            TabIndicatorProps={{
                                sx: {
                                    backgroundColor: "#86BC25",
                                    height: 3,
                                },
                            }}
                            textColor="inherit"
                        >
                            <Tab label={t("PersonalDetails")} className='fontbutton' sx={{ textTransform: "none" }} onClick={handleSubmit(handletab1)} />
                            <Tab label={t("BusinessDetails")} className='fontbutton' sx={{ textTransform: "none" }} onClick={handleSubmit(handletab2)} /> 
                            <Tab label={t("LocationDetails")} className='fontbutton' sx={{ textTransform: "none" }} onClick={handleSubmit(handletab3)} disabled={selectedTab === 0 } />
                        </Tabs>

                        {selectedTab === 0 && (
                            <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                sx={{ marginTop: '10px' }}
                            >
                                <Grid item xs={12} md={6}  >
                                    <Controller

                                        name={"FIRST_NAME"}
                                        control={control}
                                        defaultValue={bCard.FIRST_NAME}
                                        rules={{
                                            maxLength: 100,
                                            required: true,
                                            pattern: /^\s*(\S(.*\S)?)\s*$/,
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                name={"FIRST_NAME"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                value={value}
                                                label={t('FirstName')}
                                                variant="standard"
                                                placeholder={`${t("EnteryourFirstName")}`}
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",
                                                }}
                                                inputProps={{ maxlength: 100 }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />

                                    {errors?.FIRST_NAME?.type === "required" && (
                                        <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                    )}
                                    {errors?.FIRST_NAME?.type === "maxLength" && (
                                        <p className="alertColor">{`${t(
                                            "maximum_30_characters"
                                        )}`}</p>
                                    )}
                                    {errors?.FIRST_NAME?.type === "pattern" && (
                                        <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"LAST_NAME"}
                                        control={control}
                                        defaultValue={bCard.LAST_NAME}
                                        rules={{
                                            maxLength: 100,
                                            required: true,
                                            pattern: /^\s*(\S(.*\S)?)\s*$/,
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                name={"LAST_NAME"}
                                                value={value}
                                                label={t('LastName')}
                                                variant="standard"
                                                placeholder={`${t("EnteryourLastName")}`}
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",
                                                }}
                                                inputProps={{ maxlength: 100 }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />

                                    {errors?.LAST_NAME?.type === "required" && (
                                        <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                    )}
                                    {errors?.LAST_NAME?.type === "maxLength" && (
                                        <p className="alertColor">{`${t(
                                            "maximum_30_characters"
                                        )}`}</p>
                                    )}
                                    {errors?.LAST_NAME?.type === "pattern" && (
                                        <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                    )}
                                </Grid>{" "}
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"EMAIL_ADDRESS"}
                                        control={control}
                                        defaultValue={bCard.EMAIL_ADDRESS}
                                        rules={{
                                            maxLength: 100,
                                            required: true,
                                            pattern: /^\s*(\S(.*\S)?)\s*$/,
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                name={"EMAIL_ADDRESS"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e)
                                                }}
                                                value={value}

                                                label={t('EmailId')}
                                                variant="filled"
                                                placeholder={`${t("EnteryourEmailAddress")}`}
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",
                                                }}
                                                inputProps={{ maxlength: 100, readOnly: true }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />
                                    {errors?.EMAIL_ADDRESS?.type === "required" && (
                                       <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                    )}
                                   
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"RELATED_EMPLOYEE_TELEPHONE_NUMBER"}
                                        defaultValue={bCard.RELATED_EMPLOYEE_TELEPHONE_NUMBER}
                                        control={control}
                                        rules={{
                                            maxLength: 17,
                                            required: true,
                                            min: 0
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                name={"RELATED_EMPLOYEE_TELEPHONE_NUMBER"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                onKeyPress={HandleTelKeyPress}
                                                placeholder={`${t("EnteryourMobilenumber")}`}
                                                value={value}
                                                label={t("MobileNo")}

                                                variant="standard"

                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",

                                                }}
                                                inputProps={{
                                                    maxlength: 17, inputMode: 'numeric',
                                                    pattern: '/^-?\d+(?:\.\d+)?$/g'
                                                }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />
                                    {errors?.RELATED_EMPLOYEE_TELEPHONE_NUMBER?.type ===
                                        "required" && (
                                            <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                        )}
                                    {errors?.RELATED_EMPLOYEE_TELEPHONE_NUMBER?.type ===
                                        "maxLength" && (
                                            <p className="alertColor">{`${t(
                                                "maximum_30_characters"
                                            )}`}</p>
                                        )}
                                    {errors?.RELATED_EMPLOYEE_TELEPHONE_NUMBER?.type ===
                                        "pattern" && (
                                            <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                        )}
                                </Grid>
                            </Grid>
                        )}

                        {selectedTab === 1 && (
                            <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '10px' }}
                            >
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"ORGANIZATION_DESCRIPTION"}
                                        defaultValue={bCard.ORGANIZATION_DESCRIPTION}
                                        control={control}
                                        rules={{
                                            maxLength: 100,
                                            required: true,
                                            pattern: /^\s*(\S(.*\S)?)\s*$/,
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                name={"ORGANIZATION_DESCRIPTION"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                value={value}
                                                label={t("Organization")}
                                                variant="standard"
                                                placeholder={`${t("Enteryourorganization")}`}
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",
                                                }}
                                                inputProps={{ maxlength: 100 }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />
                                    {errors?.ORGANIZATION_DESCRIPTION?.type ===
                                        "required" && (
                                            <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                        )}
                                    {errors?.ORGANIZATION_DESCRIPTION?.type ===
                                        "maxLength" && (
                                            <p className="alertColor">{`${t(
                                                "maximum_30_characters"
                                            )}`}</p>
                                        )}
                                    {errors?.ORGANIZATION_DESCRIPTION?.type ===
                                        "pattern" && (
                                            <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                        )}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"JOB_TITLE_DESCRIPTION"}
                                        control={control}
                                        defaultValue={bCard.JOB_TITLE_DESCRIPTION}
                                        rules={{
                                            maxLength: 100,
                                            required: true,
                                            pattern: /^\s*(\S(.*\S)?)\s*$/,
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                name={"JOB_TITLE_DESCRIPTION"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                value={value}
                                                label={t("JobTitle")}
                                                variant="standard"
                                                placeholder={`${t("EnterYourJobTitle")}`}
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",
                                                }}
                                                inputProps={{ maxlength: 100 }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />
                                    {errors?.JOB_TITLE_DESCRIPTION?.type === "required" && (
                                        <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                    )}
                                    {errors?.JOB_TITLE_DESCRIPTION?.type === "maxLength" && (
                                        <p className="alertColor">{`${t(
                                            "maximum_30_characters"
                                        )}`}</p>
                                    )}
                                    {errors?.JOB_TITLE_DESCRIPTION?.type === "pattern" && (
                                        <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                    )}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"DESIGNATION_DESCRIPTION"}
                                        control={control}
                                        defaultValue={bCard.DESIGNATION_DESCRIPTION}
                                        rules={{
                                            maxLength: 100,
                                            required: true,
                                            pattern: /^\s*(\S(.*\S)?)\s*$/,
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                name={"DESIGNATION_DESCRIPTION"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                value={value}
                                                label={t("Designation")}
                                                variant="standard"
                                                placeholder={`${t("EnteryourDesignation")}`}
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",
                                                }}
                                                inputProps={{ maxlength: 100 }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />

                                    {errors?.DESIGNATION_DESCRIPTION?.type === "required" && (
                                        <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                    )}
                                    {errors?.DESIGNATION_DESCRIPTION?.type === "maxLength" && (
                                        <p className="alertColor">{`${t(
                                            "maximum_30_characters"
                                        )}`}</p>
                                    )}
                                    {errors?.DESIGNATION_DESCRIPTION?.type === "pattern" && (
                                        <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                    )}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"TELEPHONE_NUMBER"}
                                        defaultValue={bCard.TELEPHONE_NUMBER}
                                        control={control}                                        
                                        rules={{
                                            maxLength: 17,
                                            required: true,
                                            min: 0
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                name={"TELEPHONE_NUMBER"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                onKeyPress={HandleTelKeyPress}
                                                
                                                value={value}
                                                label={t('Workphone')}
                                                variant="standard"    
                                                placeholder={`${t("EnteryourPhonenumber")}`}                                            
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",
                                                    
                                                }}
                                                inputProps={{
                                                    maxlength: 17, inputMode: 'numeric', min: 0,
                                                    pattern: '/^-?\d+(?:\.\d+)?$/g'
                                                }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />

                                    {errors?.TELEPHONE_NUMBER?.type === "required" && (
                                        <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                    )}
                                    {errors?.TELEPHONE_NUMBER?.type === "maxLength" && (
                                        <p className="alertColor">{`${t("maximum_30_characters")}`}</p>
                                    )}
                                    {errors?.TELEPHONE_NUMBER?.type === "pattern" && (
                                        <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        {selectedTab === 2 && (
                            <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"MAILING_ADDRESS_LINE_1"}
                                        control={control}
                                        defaultValue={bCard.MAILING_ADDRESS_LINE_1}
                                        rules={{
                                            maxLength: 100,
                                            required: true,
                                            pattern: /^\s*(\S(.*\S)?)\s*$/,
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                name={"MAILING_ADDRESS_LINE_1"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                value={value}
                                                label={t("MailingAddress1")}
                                                variant="standard"
                                                placeholder={`${t("EnterMailingAddress")}`}
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",
                                                }}
                                                inputProps={{ maxlength: 100 }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />

                                    {errors?.MAILING_ADDRESS_LINE_1?.type === "required" && (
                                        <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                    )}
                                    {errors?.MAILING_ADDRESS_LINE_1?.type === "maxLength" && (
                                        <p className="alertColor">{`${t("maximum_30_characters")}`}</p>
                                    )}
                                    {errors?.MAILING_ADDRESS_LINE_1?.type === "pattern" && (
                                        <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"MAILING_ADDRESS_LINE_2"}
                                        control={control}
                                        defaultValue={bCard.MAILING_ADDRESS_LINE_2}
                                        rules={{
                                            maxLength: 100,
                                           
                                            pattern: /^\s*(\S(.*\S)?)\s*$/,
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                               
                                                name={"MAILING_ADDRESS_LINE_2"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                value={value}
                                                label={t("MailingAddress2")}
                                                variant="standard"
                                                placeholder={`${t("EnterMailingAddress")}`}
                                                style={{
                                                    textTransform: "none",

                                                    width: "100%",
                                                }}
                                                inputProps={{ maxlength: 100 }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />

                                    
                                    {errors?.MAILING_ADDRESS_LINE_2?.type === "maxLength" && (
                                        <p className="alertColor">{`${t(
                                            "maximum_30_characters"
                                        )}`}</p>
                                    )}
                                    {errors?.MAILING_ADDRESS_LINE_2?.type === "pattern" && (
                                        <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                    )}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"MAILING_ADDRESS_CITY_DESCRIPTION"}
                                        control={control}
                                        defaultValue={bCard.MAILING_ADDRESS_CITY_DESCRIPTION}
                                        rules={{
                                            maxLength: 100,
                                            required: true,
                                            pattern: /^\s*(\S(.*\S)?)\s*$/,
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                name={"MAILING_ADDRESS_CITY_DESCRIPTION"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                value={value}
                                                label={t("City")}
                                                variant="standard"
                                                placeholder={`${t("Enteryourcityname")}`}
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",
                                                }}
                                                inputProps={{ maxlength: 100 }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />

                                    {errors?.MAILING_ADDRESS_CITY_DESCRIPTION?.type ===
                                        "required" && (
                                            <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                        )}
                                    {errors?.MAILING_ADDRESS_CITY_DESCRIPTION?.type ===
                                        "maxLength" && (
                                            <p className="alertColor">{`${t(
                                                "maximum_30_characters"
                                            )}`}</p>
                                        )}
                                    {errors?.MAILING_ADDRESS_CITY_DESCRIPTION?.type ===
                                        "pattern" && (
                                            <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                        )}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"MAILING_ADDRESS_LINE_3"}
                                        control={control}
                                        defaultValue={bCard.MAILING_ADDRESS_LINE_3}
                                        rules={{
                                            maxLength: 100,
                                            required: true,
                                            pattern: /^\s*(\S(.*\S)?)\s*$/,
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                name={"MAILING_ADDRESS_LINE_3"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                value={value}
                                                label={t("MailingAddress3")}
                                                variant="standard"
                                                placeholder={`${t("EnterStateProvince")}`}
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",
                                                }}
                                                inputProps={{ maxlength: 100 }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />

                                    {errors?.MAILING_ADDRESS_LINE_3?.type === "required" && (
                                        <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                    )}
                                    {errors?.MAILING_ADDRESS_LINE_3?.type === "maxLength" && (
                                        <p className="alertColor">{`${t(
                                            "maximum_30_characters"
                                        )}`}</p>
                                    )}
                                    {errors?.MAILING_ADDRESS_LINE_3?.type === "pattern" && (
                                        <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"MAILING_ADDRESS_POSTAL_CODE"}
                                        control={control}
                                        defaultValue={bCard.MAILING_ADDRESS_POSTAL_CODE}
                                        rules={{
                                            maxLength: 20,
                                            required: true,
                                            pattern: /^\s*(\S(.*\S)?)\s*$/,
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                name={"MAILING_ADDRESS_POSTAL_CODE"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                value={value}
                                                label={t("PostalCode")}
                                                variant="standard"
                                                placeholder={`${t("EnteryourPostalcode")}`}
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",
                                                }}
                                                inputProps={{ maxlength: 20 }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />

                                    {errors?.MAILING_ADDRESS_POSTAL_CODE?.type === "required" && (
                                        <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                    )}
                                    {errors?.MAILING_ADDRESS_POSTAL_CODE?.type === "maxLength" && (
                                        <p className="alertColor">{`${t(
                                            "maximum_30_characters"
                                        )}`}</p>
                                    )}
                                    {errors?.MAILING_ADDRESS_POSTAL_CODE?.type === "pattern" && (
                                        <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                    )}
                                </Grid>

                              
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"MAILING_ADDRESS_COUNTRY_DESCRIPTION"}
                                        control={control}
                                        defaultValue={bCard.MAILING_ADDRESS_COUNTRY_DESCRIPTION}
                                        rules={{
                                            maxLength: 100,
                                            required: true,
                                            pattern: /^\s*(\S(.*\S)?)\s*$/,
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                required
                                                name={"MAILING_ADDRESS_COUNTRY_DESCRIPTION"}
                                                onChange={(e) => {
                                                    onChange(e), handChange(e);
                                                }}
                                                value={value}
                                                label={t("Country")}
                                                variant="standard"
                                                placeholder={`${t("Enteryourcountryname")}`}
                                                style={{
                                                    textTransform: "none",
                                                    width: "100%",
                                                }}
                                                inputProps={{ maxlength: 100 }}
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                            />
                                        )}
                                    />

                                    {errors?.MAILING_ADDRESS_COUNTRY_DESCRIPTION?.type ===
                                        "required" && (
                                            <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                        )}
                                    {errors?.MAILING_ADDRESS_COUNTRY_DESCRIPTION?.type ===
                                        "maxLength" && (
                                            <p className="alertColor">{`${t(
                                                "maximum_30_characters"
                                            )}`}</p>
                                        )}
                                    {errors?.MAILING_ADDRESS_COUNTRY_DESCRIPTION?.type ===
                                        "pattern" && (
                                            <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                        )}
                                </Grid>
                            </Grid>
                        )}

                    </form>

                </Box>

                <div
                    style={{ marginTop: '40px' }} 
                >
                    <Button
                        variant="contained"
                        disabled={selectedTab === 0 ? true : false}
                        onClick={handleBack}
                        sx={{
                            color: "white",
                            backgroundColor: "#0076A8",
                            textTransform: "none",
                        }} className='fontbutton'
                    >
                        {t("BackBotton")}
                    </Button>

                    <Button className='buttonnext fontbutton'
                        variant="contained"
                        disabled={selectedTab === 2 ? true : false}
                        onClick={handleSubmit(handleNext)}
                        sx={{
                            color: "white",
                            backgroundColor: "#0076A8",
                            textTransform: "none",
                            left: 60,
                        }}>
                        {t("NextBotton")}
                    </Button>
                    <ConfirmPopupBcard
                        handleSubmit={handleSubmit}
                        onsubmit={onsubmit}
                        selectedTab={selectedTab}
                        open={open}
                        setOpen={setOpen}
                        value={value}
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <Box className="leftside">
                <Box className="reviewUrlBox">
                    <h6 style={{ marginLeft: "15px", marginTop: "10px", fontSize: "20px" }}>
                        <b>{t("ReviewYourQR")}</b>
                    </h6>

                    <p style={{ marginLeft: "5px", fontSize: "13px", width: "98%" }}>
                        {t("ReviewofQRcode1")}
                        <br></br>
                        {t("ReviewofQRcode2")}
                    </p>

                    <div className="reviewqrvcard"
                        style={{
                            width: "100%",

                            marginLeft: "5px",
                        }}                    >
                        <QrCodeDisplayable
                            TargetUrl={constructQR(bCard)}
                            RenderType={"svg"}
                            level={"L"}
                            marginRequired={true}
                            DivId={getTempDivId()}
                            ForeColor={previewData.ForeColor}
                            BackgroundColor={previewData.BackgroundColor}
                            Height={template.Height}
                            Width={template.Width}
                            Logo={previewData.Logo}
                            CreatedBy={previewData.CreatedBy}
                            TemplateName={previewData.TemplateName}
                            TemplateId={""}
                            CreatedDate={new Date()}
                            ModifiedBy={""}
                            ModifiedDate={new Date()}
                            ActiveStatus={""}
                            TemplatePreview={""}
                            Shape={previewData.Shape}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <h6 style={{ marginLeft: "15px", marginTop: "10px", fontSize: "16px", color: "#0076A8", }} className='previewtext'>{t("Preview")}</h6>

                        <select style={{ fontSize: "16px", marginRight: '10px' }}
                            onChange={(e) => handleChooseLanguage(e)} className='previewlanguage'>
                            <option value="en">English</option>
                           
                            <option value="fr">Français</option>
                            <option value="ca">Canada</option>
                           
                        </select>
                    </div>
                 
                        <div className="flip-card">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={`data:image/png;base64,${image[0]}`} style={{ width: '100%', height: "100%",marginTop: '5px' }} />
                            </div> 
                        <div className="flip-card-back">
                            <img src={`data:image/png;base64,${image[1]}`} style={{ width: '100%', height: "100%",  marginTop: '5px' }} ></img> 
                               
                                </div> </div></div>
                    
                    
                    
                </Box>
            </Box>

        </>
    );
}