import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import AdminMeetingBackList from "./AdminMeetingBackList";

function GeneratedMeetingBackglist() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Box>
                <h2 className="GeneratedQRCtexth1">{t("Generatedmeetingbackgroundlists")}</h2>
                <p className="GeneratedQRCtextp">{t("GeneratedmeetingbackgroundText")}</p>
            </Box>
            <AdminMeetingBackList/>
        </>
    );
}
export default GeneratedMeetingBackglist;
