import "./App.css";
import { useState, useEffect } from "react";
import "./Internationalisation/i18n";
import { ToastContainer } from "react-toastify";
import Mainbar from "./Components/Headers/Mainbar";
import Footbar from "./Components/Headers/Footer";
import { useTranslation } from "react-i18next";
import QrCodeGenerator from "../src/Components/QrCodeGenerator/QrCodeGenerator";
import Outbar from "./Components/Headers/Outbar";
import {
 
  IsAdministrator,
 
} from "../../dttl.qr.presentation/src/Components/Authentication/Auth";
function App() {
  const [value1, setValue1] = useState(0);
  const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
    setValue1(newValue);
  };
  const [normalUser, setNormalUser] = useState(true);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setNormalUser(!IsAdministrator());
  }, []);

  return (
      <>
          <div style={{maxWidth:"100%", maxHeight:"100%"} } >
      <div className="header">
        <Mainbar />
        <ToastContainer />
      </div>
      <div className="header121">
        {normalUser ? <QrCodeGenerator /> : <Outbar />}
      </div>

    
        <Footbar />
            
              </div>
    </>
    
  );
}
export default App;
