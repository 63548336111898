import jsPDF from "jspdf";

let nameofthefile = "qrcode.";
let tempCanvasId = "canvasqrid";

export function getTempDivId() {
    let qrcodeTempId = "qrcodeidfortemplate";
    return qrcodeTempId;
}

export function downloadQrCodecAsBase64(SvgElementId: string) {
    const svg = getSvgElement(SvgElementId);
    var xml = new XMLSerializer().serializeToString(svg);
    var svg64 = btoa(xml);
    var b64Start = "data:image/svg+xml;base64,";
    var image64 = b64Start + svg64;
    return image64;
}

export async function downloadQrCodecAsIconPreview(
    SvgElementId: string,
    height: number,
    width: number
) {
    var image64 = downloadQrCodecAsBase64(SvgElementId);
    const image = document.createElement("img");
    image.src = image64;

    var canvasElement = document.createElement("canvas");
    canvasElement.id = tempCanvasId;
    canvasElement.width = width;
    canvasElement.height = height;

    let context = canvasElement.getContext("2d");
    let hadLoaded = false;
    image.onload = function () {
        context?.drawImage(image, 0, 0, width, height);
        hadLoaded = true;
    };
    while (!hadLoaded) await timeout(50);
    return canvasElement.toDataURL("image/" + "png");
}
function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
}

export function downloadQrCodeFromBase64(
    image64: string,
    imageType: string,
    width: number,
    height: number
) {
    const image = document.createElement("img");
    image.src = image64;
    image.width = width;
    image.height = height;
    var canvasElement = document.createElement("canvas");
    canvasElement.id = tempCanvasId;
    canvasElement.width = width;
    canvasElement.height = height;
    let context = canvasElement.getContext("2d");
    image.onload = function () {
        context?.drawImage(image, 0, 0, width, height);
        downLoad(image64, canvasElement, imageType, width, height);
    };
}

export function downloadQrCode(SvgElementId: string, imageType: string, width:number, height:number) {
    var svg = getSvgElement(SvgElementId);
   
    var image64 = downloadQrCodecAsBase64(SvgElementId);
    downloadQrCodeFromBase64(image64, imageType, width, height);
}
function downLoad(
    imagesrc: string,
    canvasElement: HTMLElement,
    type: string,
    width: number,
    height: number
) {
    switch (type.toLowerCase()) {
        case "svg":
            downloadQrCodeSvg(imagesrc, type);
            break;
        case "pdf":
            downloadQrCodePdf(canvasElement, type);
            break;
        default:
            downloadQrCodeImage(canvasElement, type )  ;
            break;
    }
}
function getSvgElement(SvgElementId: string) {
    return document.getElementById(SvgElementId) as unknown as SVGAElement;
}

function downloadQrCodeImage(canvasElement: HTMLElement, imageType: string) {
        const canvas = canvasElement as unknown as HTMLCanvasElement;
        const dataURL = canvas.toDataURL('image/' + imageType,);
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = nameofthefile + imageType;
        link.click();   
    }
     
function downloadQrCodePdf(canvasElement: HTMLElement, type: string) {
    const canvas = canvasElement as unknown as HTMLCanvasElement;

    const image = canvas.toDataURL("image/png", 1.0);
    const doc = new jsPDF("p", "px", "a4");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const widthRatio = pageWidth / canvas.width;
    const heightRatio = pageHeight / canvas.height;
    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    const canvasWidth = canvas.width * ratio;
    const canvasHeight = canvas.height * ratio;

    const marginX = (pageWidth - canvasWidth) / 2;
    const marginY = (pageHeight - canvasHeight) / 2;

    doc.addImage(image, "PNG", marginX, marginY, canvasWidth, canvasHeight);

    doc.save(nameofthefile + type);
}
function downloadQrCodeSvg(imagesrc: string, type: string) {
    const anchor = document.createElement("a");
    anchor.href = imagesrc;
    anchor.download = nameofthefile + type;
    anchor.click();
}