import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { DefaultVCardProps } from "../../Props/VCardProps";
import { useForm, Controller } from "react-hook-form";
import { addVcard, CheckDuplicateVcard } from "../../Services/Vcard";
import { useTranslation } from "react-i18next";
import TemplateSlideBar from "./TemplateSlideBar";
import { DefaultTemplateProps, TemplateProps } from "../../Props/TemplateProps";
import { isValidNumber } from 'libphonenumber-js';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    TextField,
} from "@mui/material";
import QrCodeDisplayable from "../QrCodeUtils/QrCodeDisplayable";
import { downloadQrCodecAsBase64, getTempDivId } from "../../Utils/DownLoad";
import {
    getLoggedInUser,
    getMemberFirm,
    getUserEmail,
    getUserEntityName,
    getUserFirstName,
    getUserLastName,
    getUserTitle,
    getMobilePhone,
    getWorkPhone
} from "../Authentication/Auth";
import { DefaultQRCodeDetailsDefaultProps } from "../../Props/QRDeatailsProps";
import { addQrCode } from "../../Services/Qrcode";
import { addQrDetail } from "../../Services/QrDetail";
import PhoneInput from "react-phone-input-2";
import ConfirmPopupVcard from "../../Notifications/ConfirmPopupVcard";
import {
    showErrorMessage,
    showWarningMessage,
} from "../../Notifications/Message";
import { constructVCard, constructBCard } from "../../Utils/VcfGenerator";
import { Update } from "@mui/icons-material";
type Props = {
    setPreviewData: Dispatch<SetStateAction<TemplateProps>>;
    previewData: TemplateProps;
    value: number;
    handleChange: (event: React.SyntheticEvent, newValue: number) => void;
};

export default function VCardComponent({
    setPreviewData,
    previewData,
    value,
    handleChange,
}: Props) {
    const { t, i18n } = useTranslation();

    const UserRole = getUserTitle();
    const UserlastName = getUserLastName();
    const UserfirstName = getUserFirstName();
    const Userdeloitteentity = getUserEntityName();
    const UserEmail = getUserEmail();
    const [templateID, setTemplateID] = useState("");
    const [filebase64, setFileBase64] = useState<string>("");
    const [country, setCountry] = useState("" as any);
    const [Userentity, setUserentity] = useState<any>(Userdeloitteentity);
    // const [Userentity, setUserentity] = useState('');
    
    const [updateFirstname, setFirstName] = useState(UserfirstName as any); //useState<any>(UserfirstName);
    
    const [updateLastname, setupdateLastname] = useState(UserlastName as any); //useState<any>(UserlastName);
   
    const [Designation, setDesignation] = useState(UserRole);
  


    const aRef = useRef("" as any);
    const changeTemplateID = (value: string) => {
        setTemplateID(value);
    };
    const [QRDetails] = useState<DefaultQRCodeDetailsDefaultProps>({
        QRCodeId: "",
        QRName: "",
        QRImage: "",
    });
    const opmmobileorigin = getMobilePhone();
    const opmmobile = `${opmmobileorigin}`.replace("+","");

    const [mobile, setMobile] = useState<any>(opmmobile);

    const userofficephno = getWorkPhone();
    const userofficenum = `${userofficephno}`.replace("+","")
    const [Amobile, setAMobile] = useState<any>(userofficenum);

    const [vcard, setVcard] = useState<DefaultVCardProps>({
        QRCodeId: "",
        Title: "",
        EmployeeCode: "",
        FirstName: updateFirstname,
        LastName: updateLastname,
        UploadImage: "",
        Designation: Designation,
        MobileNo: mobile,
        AlternateMobileNo: Amobile,
        DeloitteEntity: Userdeloitteentity,
        EmailId: UserEmail,
        CompanyName: "",
        Website: "",
        PersonalLinks: "",
    });
    const handleReset = () => {
        reset();
        debugger;
        setMobile(opmmobile);
        setAMobile(userofficephno);
        setFirstName(UserfirstName);
        setupdateLastname(UserlastName);
        setDesignation(UserRole);
        setUserentity(Userdeloitteentity);

        resetField("AlternateMobileNo");
        resetField("MobileNo");
        resetField("FirstName");
        resetField("LastName");
        resetField("Designation");
        resetField("DeloitteEntity");
    };


    const {
        register,
        handleSubmit,
        control,
        resetField,
        reset,
        formState: { errors, isValid, isDirty  },
    } = useForm<DefaultVCardProps>({
        

    });

   
    function constructQR(props: DefaultVCardProps) {
        return constructVCard(
            updateFirstname,
            updateLastname,
            Designation,
            Userdeloitteentity,
            UserEmail,
            mobile,
            Amobile,
            undefined,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            ""
        );
        debugger;
    }
    function convertFile(files: FileList | null) {
        if (files) {
            const fileRef = files[0] || "";
            const fileType: string = fileRef.type || "";
            const reader = new FileReader();
            reader.readAsBinaryString(fileRef);
            reader.onload = (ev: any) => {
                setFileBase64(`data:${fileType};base64,${btoa(ev.target.result)}`);
            };
        }
    }
    const [template, setTemplate] = useState<DefaultTemplateProps>({
        Height: 160,
        Width: 160,
    });



    const [email] = useState("");
    const [open, setOpen] = React.useState(false);

    const CreateNewVcard = (data: any) => {
        data.UploadImage = filebase64;
        debugger;
        console.log(data);
        data.FirstName = updateFirstname.trim();
        data.DeloitteEntity = Userentity.trim();
        data.EmailId = UserEmail;
        data.LastName = updateLastname.trim();
        data.MobileNo = data.MobileNo.replace("+","");
        data.AlternateMobileNo = data.AlternateMobileNo.replace("+","");
        setMobile(data.MobileNo);
        data.Designation = Designation.trim();
        setAMobile(data.AlternateMobileNo);
       
        const qrCode = {
            TemplateId: templateID,
            QRType: "Vcard",
            CreatedBy: getLoggedInUser(),
            MemberFirmId: getMemberFirm(),
        };

        const checkvcardexists = {
            TemplateId: templateID,
            FirstName: data.FirstName,
            LastName: data.LastName,
            EmailId: data.EmailId,
            MobileNo: data.MobileNo,
        };
        CheckDuplicateVcard(checkvcardexists)
            .then(function (response) {
                addQrCode(qrCode)
                    .then(function (response) {
                        if (response.data != "" && response.status == 201) {
                            data.QRCodeId = response.data;

                            addVcard(data)
                                .then(function (response) {
                                    if (response.status == 201) {
                                        QRDetails.QRCodeId = data.QRCodeId;
                                        QRDetails.QRName = data.FirstName;
                                        QRDetails.QRImage = downloadQrCodecAsBase64(getTempDivId());
                                        addQrDetail(QRDetails)
                                            .then(function (response) {

                                                setOpen(true);
                                            })
                                            .catch(function (error) { });
                                    }
                                })

                                .catch(function (error) {
                                    showErrorMessage(t("FailedtocreateQRcode"));
                                });
                        }
                    })

                    .catch(function (error) {
                        showErrorMessage(`${t("FailedtocreateQRcodeforContactcard")}`);
                    });
            })
            .catch(function (error) {
                if (error.response.status == 409)
                    showWarningMessage(`${t("Qrcodewithsimilarparameteralreadyexists")}`);
                if (error.response.status == 404)
                    showErrorMessage(`${t("Failedtocreatetemplate")}`);
            });
    };




    return (
        <>
            <div className="rightside">

                <TemplateSlideBar
                    changeTemplateID={changeTemplateID}
                    setPreviewData={setPreviewData}
                    previewData={previewData}
                />

                <Box sx={{ position: "relative" }}>
                    <h5 style={{ color: "black" }}>{t("ContactCardInformation")}</h5>

                    <form>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="flex-start"
                            spacing={{ xs: 3, lg: 3 }}
                            rowSpacing={2}
                            columnSpacing={4}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                            <Grid item xs={4} md={4}>
                                <TextField 
                                    className="FirstName"
                                    label={t("FirstName")}
                                    size="medium"
                                    variant="filled"
                                    //placeholder={`${t("EnteryourFirstName")}`}
                                    //{...register("FirstName")}
                                    value={updateFirstname}
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: 14, top: 2, fontWeight: "normal" },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField 
                                    className="LastName"
                                    label={t("LastName")}
                                    size="medium"
                                    variant="filled"
                                    //placeholder={`${t("EnteryourLastName")}`}
                                    //{...register("LastName")}
                                    value={updateLastname}
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: 14, top: 2, fontWeight: "normal" },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField 
                                    className="JobTitle"
                                    label={t("JobTitle")}
                                    size="medium"
                                    variant="filled"
                                    //placeholder={`${t("EnteryourJobTitle")}`}
                                    //{...register("Designation")}
                                    value={Designation}
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: 14, top: 2, fontWeight: "normal" },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Controller 
                                name={"DeloitteEntity"} 
                                control={control} 
                                defaultValue={Userentity} 
                                rules={{ required: true }}
                                render={({ field: { onChange, value },
                                fieldState: { error }, }) => (
                                    <TextField required
                                        className="deloitteEntity"
                                        placeholder={`${t("EnterYourDeloitteEntity")}`}
                                        size="medium"
                                        sx={{ textTransform: "none", border: "1px solid #ccc" }}
                                        label={t("DeloitteEntity")}
                                        variant="filled"
                                        value={Userentity}
                                        onChange={(e) => {
                                            setUserentity(e.target.value.trimStart());                                           
                                            onChange(e.target.value.trim());
                                        }}
                                        InputProps={{                                            
                                            style: { fontSize: 14, top: 2, fontWeight: "normal", backgroundColor: "white" },
                                        }}
                                    />
                                )}
                                />
                                {errors?.DeloitteEntity?.type == "required" && (
                                    <span className="alertColor">{t("Please fill this field!")}</span>
                                )}
                            </Grid>

                            <Grid item xs={4} md={4}>
                                <TextField required
                                    className="emailId"
                                    label={t("EmailId")}
                                    size="medium"
                                    variant="filled"
                                    placeholder={`${t("EnteryourEmailAddress")}`}
                                    {...register("EmailId")}
                                    value={UserEmail}
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: 14, top: 2, fontWeight: "normal" },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={4} md={4} className='mobilenumbervcard'>
                                <label htmlFor="phone-input1"  >{t("MobileNo")} *</label>
                                <Controller
                                    name="MobileNo"
                                    control={control}
                                    defaultValue={mobile}
                                    rules={{ required: true, minLength: 7 }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <PhoneInput containerClass="my-container-class"
                                            disableSearchIcon
                                            enableSearch
                                            country={country}
                                            placeholder={`${t("MobileNoPlaceholder")}`}
                                            dropdownStyle={{
                                                position: "absolute",
                                                top: "-215px",
                                            }}
                                            inputProps={{
                                                id: "phone-input",
                                                style: {
                                                    fontSize: "12px !important",
                                                    fontWeight: "bold",
                                                    width: "150px !important",
                                                },
                                            }}
                                            value={mobile}
                                            onChange={(e: any, data, event, formattedValue) => {

                                                const valid = isValidNumber(formattedValue);
                                                onChange(formattedValue.replace("+","")),
                                                setMobile(formattedValue.replace("+",""));
                                                // Handle the phone number change here
                                                //console.log("New phone number value:", value);
                                                //console.log("Selected country data:", data);
                                                //console.log("Change event:", event);
                                                //console.log("Formatted phone number:", formattedValue);

                                            }}
                                        />
                                    )}
                                />
                                {errors?.MobileNo?.type == "required" && (
                                    <span className="alertColor">{t("Thisfieldisrequired")}</span>
                                )}

                                {errors?.MobileNo?.type == "minLength" && (
                                    <span className="alertColor">
                                        {t("PhoneNumberisnotValid")}
                                    </span>
                                )}
                            </Grid>
                            <Grid item xs={4} md={12} sx={{ position: 'relative', bottom: "10px" }} className='vcardoffice'>
                                <label htmlFor="AlternateMobileNo">{t("OfficeNo")}</label>
                                <Controller
                                    name="AlternateMobileNo"
                                    defaultValue={Amobile}
                                    control={control}
                                    rules={{ minLength: 7 }}
                                    render={({ field: { value, onChange } }) => (
                                        <PhoneInput
                                            disableSearchIcon
                                            enableSearch
                                            country={country}
                                            placeholder={`${t("MobileNoPlaceholder")}`}
                                            dropdownStyle={{
                                                position: "absolute",
                                                top: "-215px",
                                            }}
                                            value={Amobile}
                                            autoFormat
                                            //onChange={AlterNateMobileOnChange}
                                            onChange={(e: any, data, event, formattedValue) => {
                                                onChange(formattedValue.replace("+","")),
                                                setAMobile(formattedValue.replace("+",""));
                                                //const valid = isValidNumber(formattedValue);
                                                //if (valid) {
                                                //onChange(e),setAMobile(formattedValue);                                                  
                                                //}
                                            }}
                                            //onChange={(value, data, event, formattedValue) => {    
                                            //const valid = isValidNumber(formattedValue);
                                            // Handle the validation result
                                            //if (valid) {
                                            // You can also update your form state with the new value
                                            //setAMobile(formattedValue);
                                            //onChange(formattedValue);                                                    
                                            //}

                                            //}}
                                            inputProps={{
                                                style: { width: "150px !important", fontSize: 12, fontWeight: "bold" },
                                            }}
                                        />
                                    )}
                                />
                                {errors?.AlternateMobileNo?.type == "minLength" && (
                                    <span className="alertColor">
                                        {t("PhoneNumberisnotValid")}
                                    </span>
                                )}
                            </Grid>
                            </Grid>

                            <div style={{ display:'flex', justifyContent:'center' }}>
                                <div className="buttonvcard">
                                    <Button
                                        className="fontfamily resetvcard"
                                        style={{
                                            backgroundColor: " #0076A8",
                                            color: "white",
                                            textTransform: "none"
                                        }}
                                        type="button"
                                        variant="contained"
                                        onClick={handleReset}
                                    >
                                        {t("Reset")}
                                    </Button>
                                </div>
                                <div className="buttonvcard">
                                    <ConfirmPopupVcard
                                        handleSubmit={handleSubmit}
                                        CreateNewVcard={CreateNewVcard}
                                        open={open}
                                        setOpen={setOpen}
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>

                        

                    </form>
                </Box>
            </div>
            <Box className="leftside ">
                <div className="reviewcontactcard">
                    <h6 style={{ marginLeft: "15px", marginTop: "10px", fontSize: "20px" }}>
                        <b>{t("ReviewYourQR")}</b>
                    </h6>
                    <br></br>
                    <p
                        className="reviewvcardtext"
                        style={{ marginLeft: "5px", fontSize: "13px", width: "98%" }}
                    >
                        {t("ReviewofQRcode1")}
                        <br></br>
                        {t("ReviewofQRcode2")}
                    </p>

                    <div className='reviewqrvcard'
                        style={{
                            width: "100%",
                            position: "relative",
                            justifyItems: "center",
                            float: "left",
                            marginLeft: "5px",
                        }}
                    >
                        <QrCodeDisplayable
                            TargetUrl={constructQR(vcard)}
                            RenderType={"svg"}
                            level={"L"}
                            marginRequired={true}
                            DivId={getTempDivId()}
                            ForeColor={previewData.ForeColor}
                            BackgroundColor={previewData.BackgroundColor}
                            Height={template.Height}
                            Width={template.Width}
                            Logo={previewData.Logo}
                            CreatedBy={previewData.CreatedBy}
                            TemplateName={previewData.TemplateName}
                            TemplateId={""}
                            CreatedDate={new Date()}
                            ModifiedBy={""}
                            ModifiedDate={new Date()}
                            ActiveStatus={""}
                            TemplatePreview={""}
                            Shape={previewData.Shape}
                        />
                    </div>

                    <Card sx={{ width: "100%" }}>

                        <CardContent style={{ marginTop: "10px" }}>
                            <h6 style={{ fontSize: "14px" }}>
                                {t("EmailId")}: <a href={`mailto:${email}`}>{UserEmail}</a>{" "}
                            </h6>

                            <h6 style={{ fontSize: "14px" }}>
                                {t("MobileNo")}: <a href={`tel:${mobile}`}>{mobile}</a>{" "}
                            </h6>

                            <h6 style={{ fontSize: "14px" }}>
                                {t("OfficeNo")}: <a href={`tel:${Amobile}`}>{Amobile}</a>{" "}
                            </h6>
                        </CardContent>
                        <CardActions></CardActions>

                    </Card>
                </div>
            </Box>
        </>
    );
}