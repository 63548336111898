import React, { Dispatch, SetStateAction, useState } from "react";
import { DefaultQRCodeDetailsDefaultProps } from "../../Props/QRDeatailsProps";
import { DefaultURLProps } from "../../Props/Url";
import { addQrCode } from "../../Services/Qrcode";
import { addQrDetail } from "../../Services/QrDetail";
import { addUrlQrCode, CheckDuplicateUrl } from "../../Services/Url";
import CircularStatic from "./../Headers/ProgressBar";
import TemplateSlideBar from "../../Components/QrCodeGenerator/TemplateSlideBar";
import { TemplateProps } from "../../Props/TemplateProps";
import { getLoggedInUser, getMemberFirm } from "../Authentication/Auth";
import { Box, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { downloadQrCodecAsBase64, getTempDivId } from "../../Utils/DownLoad";
import ConfirmPopupurl from "../../Notifications/ConfirmPopupurl";
import { useTranslation } from "react-i18next";
import {
    showErrorMessage,
    showWarningMessage,
} from "../../Notifications/Message";
type Props = {
    handleURLChanges: (event: any) => void;
    url: DefaultURLProps;
    setSource: Dispatch<any>;
    setPreviewData: Dispatch<SetStateAction<TemplateProps>>;
    value: number;
    handleChange: (event: React.SyntheticEvent, newValue: number) => void;
    previewData: TemplateProps;
    isButtonDisabled: boolean;
};

function URLComponent({
    handleURLChanges,
    url,
    setSource,
    setPreviewData,
    value,
    handleChange,
    previewData,
    isButtonDisabled,
}: Props) {
    const [templateID, setTemplateID] = useState("");
    const { t, i18n } = useTranslation();
    const changeTemplateID = (value: string) => {
        setTemplateID(value);
    };
    const CHARACTER_LIMIT = 100;
    const CHARACTER_LIMIT1 = 200;
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<DefaultURLProps>();

    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const CreateNewURLQRCode = () => {
        setIsLoading(false);
        const qrCode = {
            TemplateId: templateID,
            QRType: "URL",
            CreatedBy: getLoggedInUser(),
            MemberFirmId: getMemberFirm(),
        };
        debugger;
        const checkurlexists = {
            TemplateId: templateID,
            Url: url.Url,
            Title: url.Title,
        };

        CheckDuplicateUrl(checkurlexists)
            .then(function (response) {
                addQrCode(qrCode)
                    .then(function (response) {
                        if (response.data != "" && response.status == 201) {
                            url.QRCodeId = response.data;
                            addUrlQrCode(url)
                                .then(function (response) {
                                    if (response.status == 201) {
                                        QRDetails.QRCodeId = url.QRCodeId;
                                        QRDetails.QRName = url.Title;
                                        QRDetails.QRImage = downloadQrCodecAsBase64(getTempDivId());
                                        addQrDetail(QRDetails)
                                            .then(function (response) {
                                                setOpen(true);
                                            })
                                            .catch(function (error) {
                                                showErrorMessage(`${t("FailedtocreateQRcode")}`);
                                            });
                                    }
                                })
                                .catch(function (error) {
                                    showErrorMessage(`${t("FailedtocreateQRcode")}`);
                                });
                        }
                    })
                    .catch(function (error) {
                        showErrorMessage(`${t("FailedtocreateQRcodeforURLtype")}`);
                    });
            })
            .catch(function (error) {
                if (error.response.status == 409)
                    showWarningMessage(`${t("Qrcodewithsimilarparameteralreadyexists")}`);
                if (error.response.status == 404)
                    showErrorMessage(`${t("Failedtocreatetemplate")}`);
                setIsLoading(false);
            });
    };

    const [QRDetails] = useState<DefaultQRCodeDetailsDefaultProps>({
        QRCodeId: "",
        QRName: "",
        QRImage: "",
    });

    const { Title, Url } = url;
    //const [isValidUrl, setisUrlValid] = useState<boolean>(true); 
    return (
        <Box>
            <div className="urltempSlide">
                <TemplateSlideBar
                    changeTemplateID={changeTemplateID}
                    setPreviewData={setPreviewData}
                    previewData={previewData}
                />
            </div>{" "}
            <div>
                <div className="formInputs">
                    {isLoading ? <CircularStatic /> : null}
                    <form>
                        <h5 style={{ color: "black" }}>{t("URL/LinkInformation")}</h5>
                        <Grid
                            container
                            spacing={{ xs: 1, md: 1 }}
                            columns={{ xs: 1, sm: 1, md: 1 }}
                        >
                            <Grid item xs={4} md={6}>
                                <Controller
                                    name="Title"
                                    control={control}
                                    rules={{
                                        required: true,
                                        maxLength: 100,
                                        minLength: 3,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            className="urlfield1"
                                            required
                                            placeholder={`${t("Enteryourtitlehere")}`}
                                            name="Title"
                                            sx={{ width: "100%", height: "67px" }}
                                            variant="standard"
                                            fullWidth
                                            label={t("TitleofURL")}
                                            inputProps={{ maxlength: CHARACTER_LIMIT }}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e), handleURLChanges(e);
                                            }}
                                            error={!!error}
                                            helperText={
                                                error?.type == "required"
                                                    ? `${t("Thisfieldisrequired")}`
                                                    : `${url.Title?.length}/${CHARACTER_LIMIT}`
                                            }
                                        />
                                    )}
                                />

                                {errors.Title?.type == "maxLength" && (
                                    <span className="alertColor errormsgminurl">
                                        {" "}
                                        {t("maxLengthrequired")}
                                    </span>
                                )}
                                {errors.Title?.type == "minLength" && (
                                    <span className="alertColor errormsgminurl">
                                        {t("minLengthrequired")}
                                    </span>
                                )}
                                {errors?.Title?.type === "pattern" && (
                                    <span className="alertColor errormsgminurl">{`${t(
                                        "spacevalidation"
                                    )}`}</span>
                                )}
                            </Grid>
                            <Grid item xs={4} md={12}>
                                <Controller
                                    name="Url"
                                    control={control}
                                    rules={{
                                        required: true,
                                        maxLength: 200,
                                        minLength: 10,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value, onBlur },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            className="urlfield1"
                                            required
                                            placeholder={`${t("YourLinkHere")}`}
                                            name="Url"
                                            sx={{ width: "100%" }}
                                            variant="standard"
                                            fullWidth
                                            inputProps={{ maxlength: CHARACTER_LIMIT1 }}
                                            label={t("PasteUrl/Linkhere")}
                                            value={value}
                                            onBlur={(e) => {
                                                setSource(e.target.value);
                                            }}
                                            onChange={(e) => {
                                                onChange(e), handleURLChanges(e);
                                            }}
                                            error={!!error}
                                            helperText={
                                                error?.type == "required"
                                                    ? `${t("Thisfieldisrequired")}`
                                                    : `${url.Url?.length}/${CHARACTER_LIMIT1}`
                                            }
                                        />
                                    )}
                                />
                                {errors.Url?.type == "maxLength" && (
                                    <span
                                        style={{
                                            position: "relative",
                                            top: "1px",
                                            width: "300px",
                                            left: "3px",
                                        }}
                                        className="alertColor errormsgminpasteurl"
                                    >
                                        {" "}
                                        {t("maxLengthrequiredUrllink")}
                                    </span>
                                )}
                                {errors.Url?.type == "minLength" && (
                                    <span className="alertColor errormsgminpasteurl">
                                        {t("minLengthrequiredUrllink")}
                                    </span>
                                )}
                                {errors?.Url?.type === "pattern" && (
                                    <span className="alertColor errormsgminpasteurl">
                                        {`${t("spacevalidation")}`}
                                    </span>
                                )}
                            </Grid>
                        </Grid>


                        <ConfirmPopupurl
                            open={open}
                            setOpen={setOpen}
                            handleSubmit={handleSubmit}
                            CreateNewURLQRCode={CreateNewURLQRCode}
                            value={value}
                            handleChange={handleChange}
                            isUrlValid={isButtonDisabled}
                        />
                    </form>                 

                    <p>
                    <b>URL - DO's and DONT's</b>
                        <p style={{ width: "100%", fontSize: "11px" }}>
                            DOs-
                            <br />
                            1)Keep the URL simple
                            <br />
                            2)Use descriptive and localized words in URL.
                            <br />
                            For example- https://www.example.com./
                            <br />
                                DONTs-
                                <br />
                                1) URL should not contain unreadable long ID numbers
                                <br /> For example- https://www.example.com/index.php?id_sezione=360&amp;sid=3a5ebc944f41daa6f849f730f1
                                <br />2) URL should not contain non-ASCII character
                                <br />For example- https://www.example.com/gem%C3%BCse
                            
                        </p>



                    </p>
                </div>
            </div>
        </Box>
    );
}
export default URLComponent;