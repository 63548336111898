import axios from "axios";
import { AdminGroupName, GetCrpytKey, GetGraphUrlforGroup, IsCookieEncrypt, msalConfig } from "../../authConfig";
import CryptoJS from "crypto-js";
const designation = 'designation';
const entity = 'entity';
const isadmin = 'role';
const email = 'email';
const phone = 'phone';
const workphone = 'workphone';
const firstname = 'firstname';
const lastname = 'lastname';
const displayname = 'displayname';
const photo = 'displayname';
const tokenName = 'ApplicationAuthToken';
const memberfirm = "memberfirm";
const IsCryptRequired: boolean = IsCookieEncrypt();

const IncludePhaseII: boolean = false;

export function IsTranslationRequired(): boolean {
    return IncludePhaseII;
}
export function IsMeetingBackGroundRequired(): boolean {
    return IncludePhaseII;
}
export function IsBusinessCardRequired(): boolean {
    return IncludePhaseII;
}

export function getLoggedInUser() {
    return getUserFirstAndLastName() + "<" + getUserEmail() + ">";
}

export function getUserTitle() {
    return GetStorage(designation, '');
}

export function getUserEntityName() {
    return GetStorage(entity, '');
}
export function getUserFirstName() {
    return GetStorage(firstname, '');
}
export function getUserLastName() {
    return GetStorage(lastname, '');
}
export function getUserEmail() {
    return GetStorage(email, '');
}
export function getLoggedInDisplayName() {
    return GetStorage(displayname, '');
}

export function getMobilePhone() {
    return GetStorage(phone, '');
}
export function getWorkPhone() {
    return GetStorage(workphone, '');
}


function getUserFirstAndLastName() {
    let surname = getUserLastName();
    if (surname.length > 0)
        surname = ', ' + surname;
    return getUserFirstName() + surname;
}
export function GetPhoto() {
    return GetStorage(photo, '');
}
function encrypt(value: string) {
    if (!IsCryptRequired) return value;
    return CryptoJS.AES.encrypt(value, GetCrpytKey()).toString();
}
function decrypt(value: string) {
    if (!IsCryptRequired) return value;
    const bytes = CryptoJS.AES.decrypt(value, GetCrpytKey());
    return bytes.toString(CryptoJS.enc.Utf8);
}

export function setAuthDetails(data: any, token: string) {
    var businessPhone = '';
    if (data.businessPhones != null) {
        if (data.businessPhones.length > 0) {
            businessPhone = data.businessPhones[0];
        }
    }

    axios.defaults.headers.common[tokenName] = token;
    axios.defaults.headers.common['ApplicationUser'] = data.mail;
    axios.defaults.headers.common['X-Forwarded-Host'] = msalConfig.auth.redirectUri;
    setUser(businessPhone, data.mobilePhone, (data.mail == "" || data.mail == null) ? data.userPrincipalName : data.mail, data.givenName, data.surname, data.displayName, data.jobTitle);
}
export function SetAdminRole(_IsAdmin: string) {
    SetStorage(isadmin, _IsAdmin);
}
export function SetDepartment(_entity: string) {
    SetStorage(entity, _entity);
}
export function SetPhoto(_photo: any) {
    SetStorage(photo, _photo);
}

export function IsAdministrator() {
    return GetStorage(isadmin, 'N') == "Y"
}

export function setMemberFirm(_memberfirm: string) {
    SetStorage(memberfirm, _memberfirm);
}
export function getMemberFirm() {
    return GetStorage(memberfirm, '')
}

function setUser(_workphone: string, _phone: string, _email: string, _firstName: string, _lastname: string, _displayname: string, _designation: string) {
    SetStorage(designation, _designation);
    SetStorage(phone, _phone);
    SetStorage(workphone, _workphone);
    SetStorage(email, _email);
    SetStorage(firstname, _firstName);
    SetStorage(lastname, _lastname);
    SetStorage(displayname, _displayname);
}

function SetStorage(key: string, value: any) {
    sessionStorage.setItem(key, encrypt(value));
}
function GetStorage(key: string, defaultvalue: any) {
    var val = sessionStorage.getItem(key);

    if (val == null) return defaultvalue;

    return decrypt(val);
}

const AdminSearch = '\'' + AdminGroupName() + '\'';

export async function getGraphUserRole(token: any) {
    const url = GetGraphUrlforGroup() + 'startswith(displayName,' + AdminSearch + ')';

    const response = await fetch(url,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'ConsistencyLevel': 'eventual'
            },
        });
    const data = await response.json();
    var group = data.value.find((obj: { displayName: any; }) => {
        return obj.displayName === AdminGroupName()
    })

    if (group == undefined) {
        SetAdminRole('N');
    }
    else
        SetAdminRole('Y');
}