import axios from "axios";

export const getMeetingTemplateList = () => {
  return axios.get(
    process.env.REACT_APP_API_ENDPOINT + "/api/MeetingBackg/GetMeetingBackgList"
  );
};
export const getMeetingTemplateById = (MeetingId: string) => {
  return axios.get(
    process.env.REACT_APP_API_ENDPOINT +
      "/api/MeetingBackg/GetMeetingBackgById/" +
      MeetingId +
      ""
  );
};
export const addMeetingTemplate = (data: any) => {
  return axios.post(
    process.env.REACT_APP_API_ENDPOINT + "/api/MeetingBackg/AddMeetingBackg",
    data
  );
};
export const UpdateMeetingTemplate = (data: any) => {
  return axios.put(
    process.env.REACT_APP_API_ENDPOINT + "/api/MeetingBackg/UpdateMeetingBackg",
    data
  );
};
export const addMeetingBGdetails = (data: any) => {
  return axios.post(
    process.env.REACT_APP_API_ENDPOINT + "/api/MeetingBackg/AddMeetingBackgdetails",
    data
  );
};
export const GetMeetingBackgListByUser = (

    UserName: any
) => {
    return axios.get(
        process.env.REACT_APP_API_ENDPOINT + "/api/MeetingBackg/GetMeetingBackgListByUser",
        {
            params: {
                UserName: UserName,
            },
        }
    );
};
export const getMeetingBackdetailsList = () => {
    return axios.get(
        process.env.REACT_APP_API_ENDPOINT + "/api/MeetingBackg/GetMeetingBackgDetailsList"
    );
};
export const UpdateMeetingTemplateDetails = (data: any) => {
    return axios.put(
        process.env.REACT_APP_API_ENDPOINT + "/api/MeetingBackg/UpdateMeetingBackgdetails",
        data
    );
};
export const getMeetingBackgDetailsById = (MeetingId: string) => {
    return axios.get(
        process.env.REACT_APP_API_ENDPOINT +
        "/api/MeetingBackg/GetMeetingBackgDetailsById/" +
        MeetingId +
        ""
    );
};