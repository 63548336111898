import React from "react";
import { useEffect, useState } from "react";
import {
    PublicClientApplication, InteractionStatus,
    InteractionType, InteractionRequiredAuthError
} from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { msalConfig, GetGraphUrl, GetGraphUrlForDepartment } from "../../authConfig";
import { setAuthDetails, SetDepartment, getGraphUserRole, getUserEmail, setMemberFirm } from "../Authentication/Auth";
import { getOnePeopleMasterBCard } from "../../Services/BusinessCard"
const msalInstance = new PublicClientApplication(msalConfig);
export const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
    const [userData, setUserData] = useState({});
    const [token, setToken] = useState(null);
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None) {
            instance.acquireTokenSilent({
                scopes: ["User.Read"],
                account: accounts[0]
            }).then((response) => {
                graphCall(response.accessToken);
                setToken(response.accessToken);
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenPopup({
                        scopes: ["User.Read"],
                        account: accounts[0]
                    }).then((response) => {
                        graphCall(response.accessToken);
                        setToken(response.accessToken);
                    }).catch((error) => {
                        console.error('token error ', error);
                    });
                }
            });
        }
    }, [isAuthenticated, inProgress]);

    const graphCall = async (token) => {
        await getGraphUserRole(token)
        await getGraphUserProfile(token);
        await getGraphUserDepartment(token);
    }
    const getOpmData = () => {
        getOnePeopleMasterBCard(getUserEmail())
            .then(function (response) {              
                setMemberFirm(response.data.MEMBER_FIRM_ID);
            }).catch((error) => {
                console.error('Error getting OPM Data ', error);
            });
    }
    const getGraphUserProfile = (token) => {
        fetch(GetGraphUrl(), {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => response.json()
        ).then((data) => {
            setAuthDetails(data, token);
            
            setUserData(data);
            getOpmData();
        }
        ).catch((error) => {
            console.error('graph error getting default values ', error);
        });
    }

    const getGraphUserDepartment = (token) => {
        fetch(GetGraphUrlForDepartment(),
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => response.json()
            ).then((data) => {
                SetDepartment(data.value);
            }
            ).catch((error) => {
                console.error('graph error getting department values  ', error);
            });
    }

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <AuthContext.Provider value={{ userData, setUserData, accessToken: token }}>
                {userData.displayName && children}
            </AuthContext.Provider>
        </MsalAuthenticationTemplate>
    );
}

const MsalWrapper = ({ children }) => {
    return <MsalProvider instance={msalInstance}>
        <AuthProvider>
            {children}
        </AuthProvider>
    </MsalProvider>
}

export default MsalWrapper;