import QRCode from "qrcode.react";
import { useEffect, useState } from "react";
import { QRCodeDisplayableProps } from "../../Props/QRCodeDisplayableProps";
import { qrCodeDisplay } from "../../Services/Qrcode";
import Parser from "html-react-parser";

export function QrCodeDisplayable(displayProps: QRCodeDisplayableProps) {
  const [qrImage, setqrImage] = useState("Loading");

  useEffect(() => {
    const params = {
      RenderType: "svg",
      level: displayProps.level,
      DivId: displayProps.DivId + "_qr",
      Height: displayProps.Height,
      TargetUrl: displayProps.TargetUrl,
      ForeColor: displayProps.ForeColor,
      BackgroundColor: displayProps.BackgroundColor,
      marginRequired: displayProps.marginRequired,
      Logo: displayProps.Logo,
      Shape: displayProps.Shape,
    };

    qrCodeDisplay(params).then(function (response) {
      setqrImage(response.data);
    });
  }, [displayProps]);

  return (
    <div className="qrCodeDisplayStyle">
      <svg
        id={displayProps.DivId}
        width={displayProps.Height}
        height={displayProps.Height}
      >
        {Parser(qrImage)}
      </svg>
    </div>
  );
}

export default QrCodeDisplayable;

//import QRCode from "qrcode.react";
//import { QRCodeDisplayableProps } from "../../Props/QRCodeDisplayableProps";

//export function QrCodeDisplayable(displayProps: QRCodeDisplayableProps) {
//	let iconAvailble = displayProps.Logo != "" && displayProps.Logo != null;
//	let radius =
//		displayProps.Height != undefined
//			? (displayProps.Height * Math.SQRT2) / 2
//			: 0;
//	radius = radius + radius / 20;
//	let centre = displayProps.Height != undefined ? displayProps.Height : 0;
//	return (
//		<div>
//			<svg id={displayProps.DivId} width={displayProps.Width} height={displayProps.Height}>
//				{/* <circle cx={centre} cy={centre} r={radius} stroke='green' stroke-width='1' fill={displayProps.BackgroundColor}></circle>*/}

//				<QRCode
//					value={displayProps.TargetUrl}
//					renderAs={"svg"}
//					size={displayProps.Height}
//					bgColor={displayProps.BackgroundColor}
//					fgColor={displayProps.ForeColor}
//					level={displayProps.level}
//					includeMargin={displayProps.marginRequired}
//					imageSettings={{
//						src: iconAvailble ? displayProps.Logo : undefined,
//						height:
//							iconAvailble && displayProps.Height != undefined
//								? (displayProps.Height * 20) / 100
//								: 0,
//						width:
//							iconAvailble && displayProps.Width != undefined
//								? (displayProps.Width * 20) / 100
//								: 0,
//						excavate: true,
//					}}
//				/>
//			</svg>
//		</div>
//	);
//}

//export default QrCodeDisplayable;