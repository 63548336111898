import axios from "axios";
import { DefaultBusinessCardProps } from "../Props/BusinessCardProps";

let cancelToken: any;
export const getBusinessCardList = () => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/BusinessCard/GetBusinessCardList")
}

export const getBusinessCardListById = (qrcodeid: any) => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/BusinessCard/GetBusinessCardById/" + qrcodeid + "")
}

export const addBusinessCard = (data: any) => {
    return axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/BusinessCard/AddBusinessCard", data)
}

export const updateBusinessCard = (data: DefaultBusinessCardProps) => {
    return axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/BusinessCard/UpdateBusinessCard", data)
}

export const getOnePeopleMasterBCard = (email: any) => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/BusinessCard/GetOnePeopleMasterAsBusinessCard?email=" + email + "")
}

export const GetBusinessCardDoct = async (data: any) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()

    var result = await axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/BusinessCard/GetBusinessCardDoct", data, { cancelToken: cancelToken.token })

    return result;
    
}