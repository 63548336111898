import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
    filebase64: string;
    topLeft: boolean;
    topRight: boolean;
    bottomLeft: boolean;
    bottomRight: boolean;
    bottomCenter: boolean;
    topCenter: boolean;
}

function PreviewMeeting({ filebase64, topLeft, topRight, bottomLeft, bottomRight,topCenter,bottomCenter }: Props) {
    const { t, i18n } = useTranslation();

    return (
        <div className="addTemplate-left-Side" style={{ top: "-110px", right: '20', width: "600px",height:"470px",borderStyle: " ridge"}}>
            <Typography className="fontfamily" style={{ textAlign: "center" }}>{t('PreviewOfMeetingBackGround')}</Typography>
            <hr></hr>  
            
            {filebase64 ? (
                <>
                   
                    {((filebase64.indexOf("image/jpeg") > -1 ) || (filebase64.indexOf("image/jpeg") - 1)) && (
                        <><img style={{
                            width: "584px", marginLeft: "6px", height: "344px", marginTop: "10px", top: "20px"                        }} src={filebase64} ></img></>)}
                    {topLeft ? <img className="imgwidth" style={{ position: 'absolute', top: 60, left: 7, marginTop: "10px" }} src="QRPosition.png" />:null}
                    {topRight ? <img className="imgwidth" style={{ position: 'absolute', left: 488, marginTop: "10px" }} src="QRPosition.png" />:null}
                    {bottomLeft ? <img className="imgwidth" style={{ position: 'absolute', left: 7, top: 307 }} src="QRPosition.png" />:null}
                    {bottomRight ? <img className="imgwidth" style={{ position: 'absolute', left: 488, top: 307 }} src="QRPosition.png" /> : null}
                    {bottomCenter ? <img className="imgwidth" style={{ position: 'absolute', left: 233, top: 307 }} src="QRPosition.png" /> : null}
                    {topCenter ? <img className="imgwidth" style={{ position: 'absolute', left: 233, top: 60, marginTop: "10px" }} src="QRPosition.png" /> : null}
                </>) : ""}
        </div>)
}
export default PreviewMeeting;