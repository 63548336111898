import axios from "axios";

export const getPkPass = async (qrcodeid: any) => {
    return await axios.get(
        process.env.REACT_APP_API_ENDPOINT + "/api/GetPkpass/Getpkpassdata/" + qrcodeid + "", {
        responseType: 'blob',
        headers: {
        }
    },
    );
};