import axios from "axios";

export const getQrTemplateList = () => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/QRTemplate/GetQRTemplateList")
}

export const getQrTemplateById = (templateId: any) => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/QRTemplate/GetQRTemplateListById/", {
        params: {
            Id: templateId
        }
    })
}

export const addQrTemplate = (data: any) => {
    return axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/QRTemplate/AddQRTemplate", data)
}

export const updateQrTemplate = (data: any) => {
    return axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/QRTemplate/UpdateQRTemplate", data)
}