import { DataGrid, getGridDateOperators, GridColDef, GridCsvExportMenuItem, GridToolbarExportContainer } from "@mui/x-data-grid";
import { t } from "i18next";
import moment from "moment";
import StatusChangeMeetingBackground from "../../Notifications/StatusChangeMeetingBackground";
import { MeetingTemplateProps } from "../../Props/MeetingTemplateProps";
import { GetTemplateGridDateFormat } from "../../Utils/DateDisplay";
import { getStatusWorkFlow } from "./TemplateWorkFlow";
import MeetingBGAdminView from "../QrCodeAdmin/AddMeetingTemplate/MeetingBGAdminView";


type Props = {
    items: MeetingTemplateProps[];
    fetchMeetingTemplate: () => void;
   
};



export const MeetingBackGroundList = ({items, fetchMeetingTemplate, }:Props) => {
    const columns: GridColDef[] = [
        {
            field: "MeetingPreview",
            headerName: `${t('TemplateIcon')}`,
            flex: 1,
            sortable: false,
            filterable: false,
            headerAlign: "left",
            align: "left",
            renderCell: (row: any) => {
                return <img src={row.row.MeetingIcon} width="100" height="50"></img>;
            },
        },

        {
            field: "MeetingName",
            headerName: `${t('TemplateName')}`,
            flex: 1,
            headerAlign: "left",
            align: "left",
        },
       
       

        {
            field: "ActiveStatus",
            headerName: `${t('Status')}`,
            headerAlign: "left",
            align: "left",
            flex: 1,
         

           valueGetter: (params) => getStatusWorkFlow(params.value),
        },

        {
            field: "CreatedBy",
            headerName: `${t('CreatedBy')}`,
         
            headerAlign: "left",
            align: "left",
        },
        {
            field: "CreatedDate",
            headerName: `${t('CreatedDate')}`,
            type: "date",
          
            flex:1,
            align: "left",
            valueFormatter: (params) =>
                moment(params?.value).format(GetTemplateGridDateFormat()),
            filterOperators: getGridDateOperators().filter(
                    (operator) => operator.value !== 'before' && operator.value !== 'after',
                ), 
            headerAlign: "left",
        },

        {
            field: "ModifiedBy",
            headerName: `${t('ModifiedBy')}`,
            align: "left",
            flex: 1,
           
            headerAlign: "left",
        },

        {
            field: "ModifiedDate",
            headerName: `${t('ModifiedDate')}`,
            type: "date",
          
            flex: 1,
            align: "left",
            valueFormatter: (params) =>
                params?.value != null
                    ? moment(params?.value).format(GetTemplateGridDateFormat())
                    : "",
            headerAlign: "left",
            filterOperators: getGridDateOperators().filter(
                (operator) => operator.value !== 'before' && operator.value !== 'after',
            ), 
        },
        {
            field: "MeetingId",
            headerName: `${t('Action')}`,
         
            sortable: false,
            filterable: false,
            headerAlign: "left",
            align: "left",
            flex: 1.5, width:220,
            renderCell: (row: any) => {
                return (
                    <StatusChangeMeetingBackground
                        template={row.row}
                        StatusNumber={row.row.ActiveStatus.toString()}
                        fetchMeetingTemplate={fetchMeetingTemplate}
                    />

                );
            },
        },

        {
            field: "Template",
            headerName: `${t('Preview')}`,
        
            sortable: false,
            filterable: false,
            headerAlign: "left",
            align: "left",
            flex: 1.5,  
            renderCell: (row: any) => {
               
                return (
                    <MeetingBGAdminView
                        MeetingId={row.row.MeetingId}                    />)
            }
        },
    ];
    const GridToolbarExport = ({ csvOptions }: any) => (
        <GridToolbarExportContainer
            sx={{ textTransform: "capitalize" }}
            className="fontfamily"
        >
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    return (
        <div >

            <div>
                <DataGrid
                    sx={{ width: "100%" }}
                   
                    className="vvvvsss"
                    getRowId={(row) => row.MeetingId}
                    rows={items}
                    pageSizeOptions={[5, 10, 15]}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                  
                   
                    columns={columns}
                    components={{
                        Toolbar: GridToolbarExport,
                    }}
                 
                />
            </div>
        </div>
    );
};