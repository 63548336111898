export function constructVCard(
    UserfirstName: string,
    UserlastName: string,
    UserRole: string,
    Userdeloitteentity: string,
    UserEmail: string,
    mobile: string,
    Amobile: string,
    fax: string | undefined,
    organisation: string,
    memberFirmUrl: string,
    address1: string,
    address2: string,
    city: string,
    address3: string,
    country: string,
    postalcode: string,
    memberFirmurl: string
) {
    const str_vcard = "BEGIN:VCARD\nVERSION:2.1";
    const str_end = "\nEND:VCARD";

    let vcard = str_vcard;

    vcard += setProperty("N", UserlastName + ";" + UserfirstName);
    vcard += setProperty("FN", UserfirstName + " " + UserlastName);
    vcard += setProperty("ORG", organisation);
    vcard += setProperty("TITLE", UserRole + " | " + Userdeloitteentity);

    vcard += setPropertyWithPrefix("TEL;WORK;VOICE", "+", Amobile);
    vcard += setPropertyWithPrefix("TEL;HOME;VOICE", "+", mobile);
    vcard += setPropertyWithPrefix("TEL;WORK;FAX", "+", fax);
    vcard += setProperty("EMAIL;TYPE=internet,work", UserEmail);

    var address =
        ";;" + ValidateProperty(address1) +
        ";" +
        ValidateProperty(address2) +
        ";" +

        ValidateProperty(city) +
        ";" +
        ValidateProperty(address3) +
        ";" +
        ValidateProperty(country) +
        " " +
        ValidateProperty(postalcode);

    if (ValidateProperty(memberFirmurl) != "") address += " | " + memberFirmurl;

    vcard += setPropertyLastValue("ADR;type=WORK:", address);

    vcard += str_end;

    return vcard;
}
export function constructBCard(UserfirstName: string,
    UserlastName: string,
    UserRole: string,
    Userdeloitteentity: string,
    UserEmail: string,
    mobile: string,
    Amobile: string,
    fax: string | undefined,
    organisation: string,
    memberFirmUrl: string,
    address1: string,
    address2: string,
    city: string,
    address3: string,
    country: string,
    postalcode: string,
    memberFirmurl: string) {

    let vcard = "";

    vcard += "Name: " + UserfirstName + " " + UserlastName;
    vcard += "\nOrganisation: " + organisation;
    vcard += "\nJob Title: " + UserRole + " | " + Userdeloitteentity;
    vcard += "\nHome Phone: " + mobile;
    vcard += "\nWork Phone: " + Amobile
    vcard += "\nEmail: " + UserEmail
    var address =
        ValidateProperty(address1) +
        "," +
        (ValidateProperty(address2) == "" ? "" : ValidateProperty(address2) + ",") +

        ValidateProperty(city) +
        "," +
        ValidateProperty(address3) +
        "," +
        ValidateProperty(country) +
        "," +
        ValidateProperty(postalcode);

    if (ValidateProperty(memberFirmurl) != "") address += " | " + memberFirmurl;
    vcard += "\nAddress: " + address;
    return vcard;
}

function ValidateProperty(value: string) {
    if (value == undefined) return "";
    return value;
}

function setPropertyWithPrefix(
    key: string,
    prefix: string,
    value: string | undefined
) {
    if (value == undefined || value == '') {
        prefix = value = "";
    }
    return setProperty(key, prefix + value);
}
function setProperty(key: string, value: string) {
    if (value == undefined) value = "";
    return "\n" + key + ":" + value;
}
function setPropertyLastValue(key: string, value: string) {
    if (value == undefined) value = "";
    return "\n" + key + "" + value;
}