import axios from "axios";
import { DefaultVCardProps } from "../Props/VCardProps";

export const getVcardList = () => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/VCard/GetVCardList")
}

export const getVcardListById = (qrcodeid: any) => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/VCard/GetVCardById/" + qrcodeid + "")
}

export const addVcard = (data: any) => {
    return axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/VCard/AddVCard", data)
}

export const updateVcard = (data: DefaultVCardProps) => {
    return axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/VCard/UpdateVCard", data)
}

export const CheckDuplicateVcard = (data: any) => {
    return axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/QRCode/CheckQrCodeVcard ", data)
}