import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { getLoggedInUser } from "../Components/Authentication/Auth";
import { updateQrTemplate } from "../Services/QrTemplate";
import { showErrorMessage, showSuccessMessage } from "./Message";
import {
    getStatusWorkFlow,
    StatusWorkFlow,
} from "../Components/Template/TemplateWorkFlow";
import { TemplateProps } from "../Props/TemplateProps";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
type Props = {
    template: any;
    StatusNumber: any;
    fetchTemplate: () => void;
};
export default function StatusChangeDialogue({
    template,
    StatusNumber,
    fetchTemplate,
}: Props) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)
    const { t, i18n } = useTranslation();

    function handleStatus(template: TemplateProps, newStatus: number) {
        setIsLoading(true);
        template.ActiveStatus = newStatus;
        template.ModifiedBy = getLoggedInUser();
        template.ModifiedDate = new Date();
        updateQrTemplate(template)
            .then(function (response: any) {
                setIsLoading(false);
                fetchTemplate();
                showSuccessMessage(
                    template.TemplateName +
                    `${t("Statuschangedto")}` +
                    getStatusWorkFlow(newStatus)
                );
               
            })
            .catch(function (error: any) {
                setIsLoading(false);
                showErrorMessage(t('Failedtoupdate'));
            });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                className="fontfamily fontresize buttonsize"
                variant="outlined"
                sx={{
           
                    Color: " #0076A8",
                    backgroundColor: "white",
                    textTransform: 'none',
                }}
                onClick={handleClickOpen}
            >
                {template.ActiveStatus.toString() === StatusWorkFlow.Active.toString()
                    ? `${t("Deactivate")}`
                    : `${t("Activate")}`}
            </Button>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted

                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="fontfamily">{t('ConfirmAction')}</DialogTitle>
                <DialogContent>
                    <DialogContentText className="fontfamily" id="alert-dialog-slide-description">
                        {t('Actionpopup')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        className="fontfamily"
                        variant="outlined"
                        sx={{
                            Color: " #0076A8",
                            backgroundColor: "white",
                            textTransform: 'none',
                        }}
                    >
                        {`${t('Cancel')}`}
                    </Button>
                    <Button
                        className="fontfamily"
                        variant="outlined"
                        sx={{
                            Color: " #0076A8",
                            backgroundColor: "white",
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            handleStatus(
                                template,
                                template.ActiveStatus.toString() ===
                                    StatusWorkFlow.Active.toString()
                                    ? StatusWorkFlow.Inactive
                                    : StatusWorkFlow.Active
                            ),
                                handleClose();
                        }}
                    >
                        {`${t('Confirm')}`}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}