import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import QrCodeAdmin from "../QrCodeAdmin/QrCodeAdmin";
import QrCodeGenerator from "../QrCodeGenerator/QrCodeGenerator";
import { useTranslation } from "react-i18next";


function Outbar() {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="topnav">
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{ fontSize: 16 }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: "#86BC25",
              height: 3,
            },
          }}
          textColor="inherit"
        >
          <Tab
            className="fontfamily"
            label={t("QRCodeAdmin")}
            value={0}
            sx={{ textTransform: "none" }}
          />
          <Tab
            className="fontfamily"
            label={t("QrCodeGenerator")}
            value={1}
            sx={{ textTransform: "none" }}
          />
        </Tabs>
      </div>

      {value === 0 && (
        <div>
          <QrCodeAdmin />
        </div>
      )}
      {value === 1 && (
        <div>
          <QrCodeGenerator />
        </div>
      )}

    
    </>
  );
}
export default Outbar;
