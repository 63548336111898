import React, { Dispatch, SetStateAction } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { StepLabel, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { Typography, Grid } from "@mui/material";
import { addQrTemplate } from "../../Services/QrTemplate";
import { DefaultTemplateProps, TemplateProps } from "../../Props/TemplateProps";
import QrCodeDisplayable from "./../QrCodeUtils/QrCodeDisplayable";
import { downloadQrCodecAsIconPreview, getTempDivId } from "../../Utils/DownLoad";
import { showErrorMessage, showWarningMessage } from "../../Notifications/Message";
import CircularStatic from "./../Headers/ProgressBar";
import { Controller, useForm } from "react-hook-form";
import { getLoggedInUser } from "./../Authentication/Auth";
import { useTranslation } from "react-i18next";
type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    fetchTemplates: () => void;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TemplateComponent: React.FC<Props> = ({
    setOpen,
    setShow,
    fetchTemplates,
}: Props) => {
    const { t, i18n } = useTranslation();

    const steps = [
        `${t("Size&Dimensions")}`,
        `${t("Color")}`,
        `${t("DeloitteLogo")}`,
       /* `${t("Shape")}`,*/
        `${t("Preview")}`,
    ];
    const [activeStep, setActiveStep] = useState(0);
    const [number, setNumber] = useState<number>(11);
    const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
    const [qrLogo, setQrLogo] = useState<any | null>(null);
    const [fgColor, setFgColor] = useState<string>("#000000");
    const [BgColor, setBgColor] = useState<string>("#86BC25");
    const [checking, setChecking] = useState<number>(1);
    const [shape, setShape] = useState<string>()
    const CHARACTER_LIMIT = 30;
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<DefaultTemplateProps>();

    const totalSteps = () => {
        return steps.length;
    };
    const completedSteps = () => {
        return Object.keys(completed).length;
    };
    const isFirstStep = () => {
        return activeStep === 0;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);

        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
    };
    const handleBack = () => {
        const newCompleted = completed;
        newCompleted[activeStep - 1] = false;
        setCompleted(newCompleted);
        setActiveStep(activeStep - 1);
    };

    const [template, setTemplate] = useState<DefaultTemplateProps>({
        ForeColor: "#f9f0f0",
        BackgroundColor: "#201d1d",
        Height: 200,
        Width: 200,
        Logo: "",
        TemplateName: "",
        CreatedBy: getLoggedInUser(),
        TemplatePreview: "",
    });

    const [isLoading, setIsLoading] = useState(false);

    const [checked, setCheck] = useState(false);

    const onChangeEvent = () => {
        setCheck(!checked);
    };

    const handleTemplateChanges = (event: any) => {
        setTemplate((prevState: any) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value.trim(),
            };
        });
    };
    const circle = "circle"
    const CreateNewTemplate = async () => {
        setIsLoading(true);

        template.TemplatePreview = await downloadQrCodecAsIconPreview(
            getTempDivId(),
            115,
            115
        );

        template.Logo = qrLogo;
        template.Shape = shape == undefined ? 'square' : shape
        template.ForeColor = fgColor;
        template.BackgroundColor = BgColor;
        debugger;
        addQrTemplate(template)
            .then(function (response) {
                setIsLoading(false);
                setShow(false);
                fetchTemplates();
            })

            .catch(function (error) {
                if (error.response.status == 409)
                    setIsLoading(false);
                    showWarningMessage(
                        `${t("Templatewithsimilarparametersalreadyexists")}`
                    );
            })
            .catch(function (error) {
                if (error.response.status == 404)
                    setIsLoading(false);
                showErrorMessage(`${t("Failedtocreatetemplate")}`);
            })
               
             ;    
           
        
       
    };

    function ShowDimensionSelection() {
        return (
            <Box
                sx={{
                    flexGrow: 1,
                    backgroundColor: "#F5FCFF",
                    position: "absolute",
                    top: "70px",
                    left: "40px",
                    width: "184p",
                    height: "19px",
                }}
            >
                <Typography className="fontfamily" sx={{ color: "#0076A8", fontSize: "18px" }}>
                    {t("Nameofthetemplate")}
                </Typography>
                <Grid container spacing={3} columnGap={1}>
                    <Grid item xs={12}>
                        <Controller
                            name="TemplateName"
                            control={control}
                            rules={{
                                maxLength: 30,
                                required: true,
                                pattern: /^\s*(\S(.*\S)?)\s*$/
                            }}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <TextField

                                    required
                                    autoFocus
                                    placeholder={`${t("placeHolderTemplateName")}`}
                                    name="TemplateName"
                                    variant="standard"

                                    inputProps={{ maxlength: CHARACTER_LIMIT }}
                                    fullWidth
                                    label={t("TemplateName")}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e), handleTemplateChanges(e);
                                    }}
                                    error={!!error}
                                    helperText={error ? error.message : `${template.TemplateName?.length}/${CHARACTER_LIMIT}`}

                                />
                            )}
                        />
                        {errors?.TemplateName?.type === "required" && (
                            <p className="alertColor">{t("Thisfieldisrequired")}</p>
                        )}
                        {errors?.TemplateName?.type === "maxLength" && (
                            <p className="alertColor">{`${t("maximum_30_characters")}`}</p>
                        )}
                        {errors?.TemplateName?.type === "pattern" && (
                            <p className="alertColor">{`${t("spacevalidation")}`}</p>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            className="fontfamily"
                            sx={{ color: "#0076A8", fontSize: "18px", paddingTop: "50px" }}
                        >
                            {t("Size&DimensionsoftheQR")}
                        </Typography>
                    </Grid>

                    <Grid item xs={5.8}>
                        <label>{t("Width(px)")}</label>
                        <input
                            className="fontfamily"
                            type="number"
                            {...register("Width", { required: true, min: "150", max: "256" })}
                            onChange={handleTemplateChanges}
                            value={Width}
                            name="Width"
                            min="150"
                            max="256"
                        ></input>
                        {errors?.Width?.type === "min" && (
                            <p className="alertColor">{t("Widthcannotbebelow150pixels")}</p>
                        )}
                        {errors?.Width?.type === "max" && (
                            <p className="alertColor">{t("Widthcannotbeabove250pixels")}</p>
                        )}
                    </Grid>

                    <Grid item xs={6}>
                        <label>{t("Height(px)")}</label>
                        <input
                            className="fontfamily"
                            type="number"
                            {...register("Height", {
                                required: true,
                                min: "150",
                                max: "256",
                            })}
                            name="Height"
                            onChange={handleTemplateChanges}
                            value={Height}
                            min="150"
                            max="256"
                        ></input>
                        {errors?.Height?.type === "min" && (
                            <p className="alertColor">{t("Heightcannotbebelow150pixels")}</p>
                        )}
                        {errors?.Height?.type === "max" && (
                            <p className="alertColor">{t("Heightcannotbebelow250pixels")}</p>
                        )}
                    </Grid>
                </Grid>
            </Box>
        );
    }

    function ShowColorSelection() {
        return (
            <Box sx={{ backgroundColor: "#F5FCFF" }}>
                <Typography
                    variant="h5"
                    className="showSelection-Typogrpahy-FG fontfamily"
                    sx={{ marginTop: -6 }}
                >
                    {t("ForegroundColor")}{" "}
                    <p style={{ fontSize: "12px", color: "black", width: "240px" }}>
                        {t("FCDetail1")} <br></br> {t("FCDetail2")}
                        <br></br> {t("FCDetail3")}
                    </p>{" "}
                </Typography>

                <Grid
                    container
                    className="showSelection-Grid-Style"
                    sx={{ marginLeft: -3, marginTop: 2 }}
                >
                    <input
                        type="radio"
                        name="radio"
                        id="olive"
                        value="#000000"
                        defaultChecked
                        checked={fgColor == "#000000" ? true : false}
                        onClick={() => {
                            setFgColor("#000000");
                        }}
                    />
                    <label className="label1" htmlFor="olive">
                        <span className="olive"></span>
                    </label>
                    <input
                        type="radio"
                        name="radio"
                        id="green"
                        checked={fgColor == "#FFFFFF" ? true : false}
                        value="#FFFFFF"
                        onClick={() => {
                            setFgColor("#FFFFFF");
                        }}
                    />
                    <label className="label1" htmlFor="green">
                        <span className="green"></span>
                    </label>

                    <input
                        type="radio"
                        name="radio"
                        id="teal"
                        value="#D0D0CE"
                        checked={fgColor == "#D0D0CE" ? true : false}
                        onClick={() => {
                            setFgColor("#D0D0CE");
                        }}
                    />
                    <label className="label1" htmlFor="teal">
                        <span className="teal"></span>
                    </label>
                </Grid>

                <Typography
                    variant="h5"
                    sx={{ marginTop: 3 }}
                    className="showSelection-Typogrpahy-BG fontfamily"
                >
                    {t("BackgroundColor")}
                    <p style={{ fontSize: "12px", width: "240px", color: "black" }}>
                        {t("BCDetail1")} <br></br> {t("BCDetail2")} <br></br>{" "}
                        {t("BCDetail3")}{" "}
                    </p>
                </Typography>

                <Grid
                    container
                    spacing={-1}
                    className="showSelection-Grid-Style widthincrease"
                    sx={{ marginTop: 4, marginLeft: -3 }}
                >

                    <input
                        type="radio"
                        name="color"
                        id="yellow"
                        defaultChecked
                        checked={BgColor == "#86BC25" ? true : false}
                        value="#86BC25"
                        onClick={() => {
                            setBgColor("#86BC25");
                        }}
                    />
                    <label className="label1" htmlFor="yellow">
                        <span className="yellow"></span>
                    </label>


                    <input
                        type="radio"
                        name="color"
                        id="white"
                        value="#FFFFFF"
                        checked={BgColor == "#FFFFFF" ? true : false}
                        onClick={() => {
                            setBgColor("#FFFFFF");
                        }}
                    />
                    <label className="label1" htmlFor="white">
                        <span className="white"></span>
                    </label>
                    <input
                        type="radio"
                        name="color"
                        id="red1"
                        value="#000000"
                        checked={BgColor == "#000000" ? true : false}
                        onClick={() => {
                            setBgColor("#000000");
                        }}
                    />
                    <label className="label1" htmlFor="red1">
                        <span className="red1"></span>
                    </label>
                    <input
                        type="radio"
                        name="color"
                        id="succesgreen"
                        value="#26890D"
                        checked={BgColor == "#26890D" ? true : false}
                        onClick={() => {
                            setBgColor("#26890D");
                        }}
                    />
                    <label className="label1" htmlFor="succesgreen">
                        <span className="succesgreen"></span>
                    </label>

                </Grid>
            </Box>
        );
    }

  
    const SelectQRLogoGrid = async (e: any, index: number) => {
       
        const data = await fetch(e.target.src);
        const blob = await data.blob();
        setNumber(index);
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
              
                if (index === 11) {
                    setQrLogo(null);
                }
                else {
                    setQrLogo(base64data);
                }
              
            }
        });
       
    }
    function ShowLogoSelection() {
        return (
            <>
                <Typography>
                    <h5 style={{ color: "#0076A8", fontWeight: "500" }}>{t("Logo")}</h5>
                    <p style={{ fontSize: "13px", width: "210px" }}>{t("LogoDetail1")}</p>
                </Typography>
                <Box className="logoselection-Template-Step2">
                    <Grid
                        container

                        rowSpacing={10}
                        columnSpacing={2}
                    >
                        {Array.from(Array(12)).map((_, index) => (

                            <Grid item xs={3} sm={4} md={3} key={index}>
                                <label>

                                    <input
                                        id="logos"
                                        type="radio"
                                        name="radio"
                                        className="logoSelectionimages-radio-Feild"
                                        checked={index == number ? true : false}
                                    ></input>
                                    
                                    <img width="50px" height="50px"
                                        className="logoSelectionimages-ImgFeild"

                                        src={require(`..///.././../public/HighResolutions/${index}.png`)}
                                        onClick={(e) => {
                                           
                                            
                                            SelectQRLogoGrid(e, index)
                                        }}
                                    />
                                </label>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </>
        );
    }
    //function ShowShapeSelection() {
    //    return (<div>
    //        <Typography>
    //            <h5 style={{ color: "#0076A8", fontWeight: "500" }}>{t("Shape")}</h5>
    //            <p style={{ fontSize: "13px", width: "210px" }}>{t("ShapeSelection")}</p>
    //        </Typography>
    //        <label className="fontfamily textline">{t("Circular")}</label>
    //        <input
    //            className="shape"
    //            type='radio'
    //            id='shapes'
    //            name='circle'
    //            value=""
    //            checked={checking == 0 ? true : false}

    //        />

    //        <div className="shapeSelection">


    //            <img id='shapes' src='circular.png' alt='circle' onClick={() => {
    //                setShape("circle"), setChecking(0)
    //            }} className='radiusparam' />
    //        </div>
    //        <label className="fontfamily textline" style={{ left: "450px" }} >{t("Square")}</label>
    //        <input
    //            className="shape"
    //            type='radio'
    //            id='shapes'
    //            name='square'
    //            value=""

    //            checked={checking == 1 ? true : false}

    //        />

    //        <div id='shapes' className="shapeSelection squareSelection">
    //            <img id='shapes' src='square.png' alt='squre' onClick={() => {
    //                setShape("square"), setChecking(1)
    //            }} className='radiusparam' />



    //        </div>


    //    </div>)
    //}
    function ShowPreview(scale: number) {
        if (Height == undefined) return;
        if (Width == undefined) return;

        const size = Height < Width ? Height : Width;

        const height = (size * scale) / 100;
        const width = (size * scale) / 100;

        return (
            <>
                <div
                    className="qrCodePreviewStyle"
                    style={{ marginTop: "-40px", marginLeft: "10px" }}
                >
                    <QrCodeDisplayable
                        TargetUrl={"www2.deloitte.com"}
                        RenderType={"svg"}
                        level={"H"}
                        marginRequired={true}
                        DivId={getTempDivId()}
                        ForeColor={fgColor}
                        BackgroundColor={BgColor}
                        Height={height}
                        Width={width}
                        Logo={qrLogo}
                        CreatedBy={CreatedBy}
                        TemplateName={TemplateName}
                        TemplateId={""}
                        CreatedDate={new Date()}
                        ModifiedBy={""}
                        ModifiedDate={new Date()}
                        ActiveStatus={""}
                        TemplatePreview={""}
                        Shape={shape}
                    />
                </div>
                <Grid container spacing={3} columnGap={3}>
                    <Typography
                        sx={{
                            position: "absolute",
                            /*     width:'100px'*/
                            left: "15px",
                            top: "40px",
                            fontSize: "20px ",
                            color: "#0076A8",
                        }}
                    >
                        <b>{t("PreviewoftheQR")}</b>
                        <p style={{ width: "250px", color: "black", fontSize: "12px" }}>
                            {t("PreviewQrDetail")}
                        </p>
                    </Typography>
                    <Grid item xs={4}>
                        <label
                            className="showSelection-Typogrpahy-Width-label"
                            style={{ top: "200px", left: "15px" }}
                        >
                            <b>{t("TemplateName")}</b>
                            <Typography
                                sx={{ top: "30px", color: "#0076A8", fontSize: "20px" }}
                            >
                                {TemplateName}
                            </Typography>
                        </label>
                    </Grid>

                    <Grid item xs={4}>
                        <label className="showSelection-Typogrpahy-Width-label">
                            <b className="fontfamily">{t("Width(px)")}</b>
                        </label>
                        <Typography
                            className="showSelection-Typogrpahy-Width-label fontfamily"
                            sx={{
                                color: " #0076A8",
                                top: "320px",
                                left: "20",
                                fontSize: "20px",
                            }}
                        >
                            {Width}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <label className="showSelection-Typogrpahy-Height-label">
                            <b>{t("Height(px)")}</b>
                        </label>
                        <Typography
                            className="showSelection-Typogrpahy-BG"
                            sx={{ fontSize: "20px", top: "320px", left: "220" }}
                        >
                            {Height}
                        </Typography>
                    </Grid>
                </Grid>
            </>
        );
    }
    function showStep(activeStep: number) {
        var steps = [];
        switch (activeStep) {
            case 1:
                steps.push(ShowDimensionSelection());

                break;

            case 2:
                steps.push(ShowColorSelection());

                break;

            case 3:
                steps.push(ShowLogoSelection());

                break;
            //case 4:
            //    steps.push(ShowShapeSelection())
            //    break;

            case 4:
                steps.push(ShowPreview(100));
                break;
        }

        return steps;
    }

    const { TemplateName, CreatedBy, Height, Width, Logo } = template;
    return (
        <Box
            className="addTemplate-right-Side fontfamily"
            sx={{ marginLeft: "20px", backgroundColor: "#F5FCFF" }}
        >
            {isLoading ? <CircularStatic /> : null}

            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepLabel color="inherit">{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {allStepsCompleted() ? (
                <>
                    <h2 className="fontfamily">{t("SucessFullyCompletedTemplate")}</h2>
                </>
            ) : (
                <>
                    <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        {showStep(activeStep + 1)}
                    </Typography>
                    <Box className="addTemplate-Buttons">
                        <Button
                            className="fontfamily"
                            variant="contained"
                            disabled={isFirstStep()}
                            onClick={handleBack}
                            sx={{
                                mr: 1,
                                color: "white",
                                backgroundColor: "#0076A8",
                                textTransform: "none",
                            }}
                        >
                            {t("BackBotton")}
                        </Button>

                        {activeStep === steps.length - 1 ? (
                            <Button
                                className="fontfamily"
                                variant="contained"
                                onClick={CreateNewTemplate}
                                sx={{
                                    mr: 1,
                                    color: "white",
                                    backgroundColor: "#0076A8",
                                    textTransform: "none",
                                }}
                            >
                                {t("SaveBotton")}
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                className="fontfamily"
                                onClick={handleSubmit(handleNext)}
                                sx={{
                                    mr: 1,
                                    color: "white",
                                    backgroundColor: "#0076A8",
                                    textTransform: "none",
                                }}
                            >
                                {t("NextBotton")}
                            </Button>
                        )}

                        <Button
                            className="fontfamily"
                            variant="contained"
                            disabled={activeStep !== 0 && activeStep !== steps.length - 1}
                            onClick={() => {
                                setOpen(false);
                            }}
                            sx={{
                                mr: 1,
                                color: "white",
                                backgroundColor: "#0076A8",
                                textTransform: "none",
                            }}
                        >
                            {t("Close")}
                        </Button>

                        <Box sx={{ flex: "1 1 auto" }} />
                    </Box>
                </>
            )}
        </Box>
    );
};
export default TemplateComponent;