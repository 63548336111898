import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    Grid,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import moment from "moment";
import react, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DefaultBusinessCardProps } from "../../../Props/BusinessCardProps";
import { QRCodeProps } from "../../../Props/QRCodeProps";
import { getBusinessCardListById, getOnePeopleMasterBCard } from "../../../Services/BusinessCard";
import { getQrCodeListByUser } from "../../../Services/Qrcode";
import { GetTemplateGridDateFormat } from "../../../Utils/DateDisplay";
import {
    getLoggedInUser,
    getUserEmail,
    getUserFirstName,
    getUserLastName,
    getMobilePhone,
    getWorkPhone,
} from "../../Authentication/Auth";
import { getStatusWorkFlow } from "../../Template/TemplateWorkFlow";
import CloseIcon from "@mui/icons-material/Close";
import { constructVCard, constructBCard } from "../../../Utils/VcfGenerator";
import { MeetingTemplateProps } from "../../../Props/MeetingTemplateProps";
import { addMeetingBGdetails, getMeetingBackgDetailsById } from "../../../Services/MeetingTemplate";
import { showErrorMessage, showWarningMessage } from "../../../Notifications/Message";
type Props = {
    setQRTarget: React.Dispatch<React.SetStateAction<string>>;
    setImagefinale: React.Dispatch<React.SetStateAction<string | undefined>>;
    positionqr: string;
    qrdata: string;
    MeetingID: string | undefined;
    setsucessmsg: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};
function BusinessCardMeetingBg({
    setQRTarget,
    positionqr,
    setImagefinale,
    qrdata,
    MeetingID,
    setsucessmsg, setActiveStep
}: Props) {
    const [open, setOpen] = useState(false);
    const [load, setload] = useState(false)
    const [datalist, setDatalist] = useState<QRCodeProps[]>([]);
    
    const UserEmail = getUserEmail();
    const [bcarddata, setBcardData] = useState({
        QRCodeId: "",
        FIRST_NAME: getUserFirstName(),
        LAST_NAME: getUserLastName(),
        EMAIL_ADDRESS: getUserEmail(),
        ORGANIZATION_DESCRIPTION: "",
        RELATED_EMPLOYEE_TELEPHONE_NUMBER: getMobilePhone(),
        JOB_TITLE_DESCRIPTION: "",
        DESIGNATION_DESCRIPTION: "",
        MAILING_ADDRESS_CITY_DESCRIPTION: "",
        MAILING_ADDRESS_COUNTRY_DESCRIPTION: "",
        MAILING_ADDRESS_LINE_1: "",
        MAILING_ADDRESS_LINE_2: "",
        MAILING_ADDRESS_LINE_3: "",
        MAILING_ADDRESS_POSTAL_CODE: "",
        TELEPHONE_NUMBER: getWorkPhone(),
        CreatedDate: new Date(),
    });
    const _UserName = getLoggedInUser();
    function getDataFromlistvcard(QRCodeId: any) {
        getBusinessCardListById(QRCodeId)
            .then(function (response) {
                setBcardData(response.data);
               
                setValue("FIRST_NAME", `${response.data.FIRST_NAME}`);
                setValue("LAST_NAME", `${response.data.LAST_NAME}`);
                setValue(
                    "ORGANIZATION_DESCRIPTION",
                    `${response.data.ORGANIZATION_DESCRIPTION}`
                );
                setValue(
                    "RELATED_EMPLOYEE_TELEPHONE_NUMBER",
                    `${response.data.RELATED_EMPLOYEE_TELEPHONE_NUMBER}`
                );
                setValue(
                    "JOB_TITLE_DESCRIPTION",
                    `${response.data.JOB_TITLE_DESCRIPTION}`
                );
                setValue(
                    "DESIGNATION_DESCRIPTION",
                    `${response.data.DESIGNATION_DESCRIPTION}`
                );
                setValue(
                    "TELEPHONE_NUMBER",
                    `${response.data.TELEPHONE_NUMBER}`
                );
                setValue(
                    "MAILING_ADDRESS_CITY_DESCRIPTION",
                    `${response.data.MAILING_ADDRESS_CITY_DESCRIPTION}`
                );
                setValue(
                    "MAILING_ADDRESS_COUNTRY_DESCRIPTION",
                    `${response.data.MAILING_ADDRESS_COUNTRY_DESCRIPTION}`
                );

                setValue(
                    "MAILING_ADDRESS_LINE_1",
                    `${response.data.MAILING_ADDRESS_LINE_1}`
                );

                setValue(
                    "MAILING_ADDRESS_LINE_2",
                    `${response.data.MAILING_ADDRESS_LINE_2}`
                );

                setValue(
                    "MAILING_ADDRESS_LINE_3",
                    `${response.data.MAILING_ADDRESS_LINE_3}`
                );

                setValue(
                    "MAILING_ADDRESS_POSTAL_CODE",
                    `${response.data.MAILING_ADDRESS_POSTAL_CODE}`
                );
                setValue("EMAIL_ADDRESS", `${response.data.EMAIL_ADDRESS}`);
               
            })
            .catch(function (response) {
                
            });
    }

    useEffect(() => {
        getOnePeopleMasterBCard(UserEmail)
            .then(function (response) {
               
                setValue(
                    "ORGANIZATION_DESCRIPTION",
                    `${response.data.ORGANIZATION_DESCRIPTION}`
                );
                
                setValue(
                    "JOB_TITLE_DESCRIPTION",
                    `${response.data.JOB_TITLE_DESCRIPTION}`
                );
                setValue(
                    "DESIGNATION_DESCRIPTION",
                    `${response.data.DESIGNATION_DESCRIPTION}`
                );
                setValue(
                    "MAILING_ADDRESS_CITY_DESCRIPTION",
                    `${response.data.MAILING_ADDRESS_CITY_DESCRIPTION}`
                );
                setValue(
                    "MAILING_ADDRESS_COUNTRY_DESCRIPTION",
                    `${response.data.MAILING_ADDRESS_COUNTRY_DESCRIPTION}`
                );

                setValue(
                    "MAILING_ADDRESS_LINE_1",
                    `${response.data.MAILING_ADDRESS_LINE_1}`
                );

                setValue(
                    "MAILING_ADDRESS_LINE_2",
                    `${response.data.MAILING_ADDRESS_LINE_2}`
                );

                setValue(
                    "MAILING_ADDRESS_LINE_3",
                    `${response.data.MAILING_ADDRESS_LINE_3}`
                );

                setValue(
                    "MAILING_ADDRESS_POSTAL_CODE",
                    `${response.data.MAILING_ADDRESS_POSTAL_CODE}`
                );
                setValue("EMAIL_ADDRESS", `${response.data.EMAIL_ADDRESS}`);
                let a = response.data.RELATED_EMPLOYEE_TELEPHONE_NUMBER;
                if (a != null) { 
                    setValue(
                        "RELATED_EMPLOYEE_TELEPHONE_NUMBER",
                        `${response.data.RELATED_EMPLOYEE_TELEPHONE_NUMBER}`
                    );
                   
                }
                let b = response.data.TELEPHONE_NUMBER
                if (b!=null) {
                    setValue(
                        "TELEPHONE_NUMBER",
                        `${response.data.TELEPHONE_NUMBER}`
                    );
                }
                    
              
            })
            .catch(function (error) {
                
            }
            );
    }, [UserEmail]);

    type SimpleDialogProps = {
        open: boolean;
        onClose: () => void;
    };
    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm<DefaultBusinessCardProps>();
    const [selectedTab, setSelectedTab] = useState(0);
    const handleNext = (data: any) => {
        setSelectedTab(selectedTab + 1);
       
        (bcarddata.LAST_NAME = data.LAST_NAME),
            (bcarddata.JOB_TITLE_DESCRIPTION = data.JOB_TITLE_DESCRIPTION),
            (bcarddata.DESIGNATION_DESCRIPTION = data.DESIGNATION_DESCRIPTION),
            (bcarddata.EMAIL_ADDRESS = data.EMAIL_ADDRESS),
            (bcarddata.RELATED_EMPLOYEE_TELEPHONE_NUMBER =
                data.RELATED_EMPLOYEE_TELEPHONE_NUMBER),
            (bcarddata.TELEPHONE_NUMBER = data.TELEPHONE_NUMBER),
            "",
            (bcarddata.ORGANIZATION_DESCRIPTION = data.ORGANIZATION_DESCRIPTION),
            "",
            (bcarddata.MAILING_ADDRESS_LINE_1 = data.MAILING_ADDRESS_LINE_1),
            (bcarddata.MAILING_ADDRESS_LINE_2 = data.MAILING_ADDRESS_LINE_2),
            (bcarddata.MAILING_ADDRESS_LINE_3 = data.MAILING_ADDRESS_LINE_3),
            (bcarddata.MAILING_ADDRESS_CITY_DESCRIPTION =
                data.MAILING_ADDRESS_CITY_DESCRIPTION),
            (bcarddata.MAILING_ADDRESS_COUNTRY_DESCRIPTION =
                data.MAILING_ADDRESS_COUNTRY_DESCRIPTION),
            (bcarddata.MAILING_ADDRESS_POSTAL_CODE =
                data.MAILING_ADDRESS_POSTAL_CODE),
            constructQR();
       
    };
    const handlesubmit = (data: any) => {
        bcarddata.FIRST_NAME = data.FIRST_NAME;
        (bcarddata.LAST_NAME = data.LAST_NAME),
            (bcarddata.JOB_TITLE_DESCRIPTION = data.JOB_TITLE_DESCRIPTION),
            (bcarddata.DESIGNATION_DESCRIPTION = data.DESIGNATION_DESCRIPTION),
            (bcarddata.EMAIL_ADDRESS = data.EMAIL_ADDRESS),
            (bcarddata.RELATED_EMPLOYEE_TELEPHONE_NUMBER =
                data.RELATED_EMPLOYEE_TELEPHONE_NUMBER),
            (bcarddata.TELEPHONE_NUMBER = data.TELEPHONE_NUMBER),
            "",
            (bcarddata.ORGANIZATION_DESCRIPTION = data.ORGANIZATION_DESCRIPTION),
            "",
            (bcarddata.MAILING_ADDRESS_LINE_1 = data.MAILING_ADDRESS_LINE_1),
            (bcarddata.MAILING_ADDRESS_LINE_2 = data.MAILING_ADDRESS_LINE_2),
            (bcarddata.MAILING_ADDRESS_LINE_3 = data.MAILING_ADDRESS_LINE_3),
            (bcarddata.MAILING_ADDRESS_CITY_DESCRIPTION =
                data.MAILING_ADDRESS_CITY_DESCRIPTION),
            (bcarddata.MAILING_ADDRESS_COUNTRY_DESCRIPTION =
                data.MAILING_ADDRESS_COUNTRY_DESCRIPTION),
            (bcarddata.MAILING_ADDRESS_POSTAL_CODE =
                data.MAILING_ADDRESS_POSTAL_CODE),
            constructQR();
        let a = qrdata.split('href="');
        let b = a.at(1)?.trimEnd().split("/> </svg>").at(0);
        let c = b?.split(" ").at(1);
        const params = {
            MeetingId: MeetingID,
            Position: positionqr,
            MeetingQRImage: c,
            CreatedBy: getLoggedInUser(),
        };

        addMeetingBGdetails(params)
            .then(function (response) {
                setsucessmsg(true);
                
                getMeetingBackgDetailsById(response.data).then(function (response) {
                  
                    setImagefinale(response.data.MeetingBgWithQr)
                })
            })
            .catch(function (response: any) {

                if (response.response.status == 500) {
                    showErrorMessage(
                        `${t("SelectinganyoftheallowedPositiontoplaceQRisrequired")}`
                    );

                    return (
                        setActiveStep((prevActiveStep) => prevActiveStep - 2)
                    )

                }
                if (response.response.status == 409) {
                    showWarningMessage(
                        `${t("MeetingBackgroundwithsimilarparametersalreadyexists")}`
                    );
                }

                
            });
    };
    const handletab1 = () => {
        setSelectedTab(0)
    }
    const handletab2 = () => {
        setSelectedTab(1)
    }
    const handletab3 = () => {
        setSelectedTab(2)
    }


    const handleBack = () => {
        setSelectedTab(selectedTab - 1);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handChange = (event: any) => {
        const { name, value } = event.target;

        setBcardData((prevState: any) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };
    const HandleTelKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedChars = ['+', '.',' ', ...Array.from({ length: 10 }, (_, i) => i.toString())];
        const pressedKey = event.key;
    
     
    
        if (!allowedChars.includes(pressedKey)) {
          event.preventDefault();
        }
      };

    useEffect(() => {
        constructQR();
    }, [handChange]);
    function constructQR() {
        const a = constructBCard(
            bcarddata.FIRST_NAME,
            bcarddata.LAST_NAME,
            bcarddata.JOB_TITLE_DESCRIPTION,
            bcarddata.DESIGNATION_DESCRIPTION,
            bcarddata.EMAIL_ADDRESS,
            bcarddata.RELATED_EMPLOYEE_TELEPHONE_NUMBER,
            bcarddata.TELEPHONE_NUMBER,
            "",
            bcarddata.ORGANIZATION_DESCRIPTION,
            "",

            bcarddata.MAILING_ADDRESS_LINE_1,
            bcarddata.MAILING_ADDRESS_LINE_2,
            bcarddata.MAILING_ADDRESS_LINE_3,
            bcarddata.MAILING_ADDRESS_CITY_DESCRIPTION,
            bcarddata.MAILING_ADDRESS_COUNTRY_DESCRIPTION,
            bcarddata.MAILING_ADDRESS_POSTAL_CODE,
            ""
        );

        setQRTarget(a);
        return a;
    }

    useEffect(() => {
        setload(true)
        getQrCodeListByUser(_UserName)
            .then(function (response: any) {

                {
                    response.data.map((item: any) => {
                        if (item.QRType === "Business Card") {
                            if (item.ActiveStatus !== 5) {
                                datalist.push(item);
                            }

                        }
                        setload(false)
                    });
                }
            })
            .catch(function (error: any) { });
    }, [_UserName]);
    function SimpleDialog(props: SimpleDialogProps) {
        const { onClose, open } = props;

        const handleClose = () => {
            onClose();
        };

        const columns: GridColDef[] = [
            {
                field: "Title",
                headerName: `${t("NameofQRcode")}`,
                width: 180,
                flex: 1,
                headerAlign: "left",
                align: "left",
            },

            {
                field: "QRType",
                headerName: `${t("TypesofQRCode")}`,
                width: 180,
                flex: 1,
                headerAlign: "left",
                align: "left",
            },

            {
                field: "CreatedDate",
                headerName: `${t("Processedon")}`,
                type: "date",
                width: 160,
                headerAlign: "left",
                align: "left",
                flex: 1,
                valueFormatter: (params) =>
                    moment(params?.value).format(GetTemplateGridDateFormat()),
            },

            {
                field: "ExpiryDate",
                headerName: `${t("Activetill")}`,
                type: "date",
                width: 120,
                headerAlign: "left",
                align: "left",
                flex: 1,
                valueFormatter: (params) =>
                    params?.value != null
                        ? moment(params?.value).format(GetTemplateGridDateFormat())
                        : "",
                renderCell: (cellVallues: any) => {
                    if (
                        cellVallues.row.QRType == "Vcard" ||
                        cellVallues.row.QRType == "Business Card"
                    ) {
                        return "N/A";
                    }
                },
            },

            {
                field: "ActiveStatus",
                headerName: `${t("ActiveStatus")}`,
                width: 100,
                headerAlign: "left",
                align: "left",
                flex: 1,
                valueGetter: (params) => getStatusWorkFlow(params.value),
            },

            {
                field: "",
                
                width: 180,
                sortable: false,
                filterable: false,
                flex: 1,
                renderCell: (row: any) => {
                    return (
                        <Button sx={{ textTransform: "none", }} className='buttonSize'
                            onClick={() => {
                                getDataFromlistvcard(row.row.QRCodeId),
                                    handleClose(), 
                                   
                                    constructQR();
                            }}
                        >
                            {t("Select") }
                        </Button>
                    );
                },
            },
        ];
        return (
            <Dialog fullWidth={true}  onClose={handleClose} open={open}>
                <DialogTitle>
                    {t("ChooseFromExistingQR")}
                    <CloseIcon onClick={handleClose} className="closeiconinMBG" />
                </DialogTitle>
                <div style={{ height: 400, width: "100%" }}>
                    {load ?
                        <CircularProgress
                            value={50}
                            sx={{
                                position: "relative",

                                left: "20%",
                                height: "100%",
                                marginTop: "20%",
                                marginBottom: "20%",
                                marginLeft: "30%",
                            }}
                            disableShrink
                        /> : <DataGrid
                            getRowId={(datalist) => datalist.QRCodeId}
                            rows={datalist}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15]}
                        />}

                </div>
            </Dialog>
        );
    }
    return (
        <>
            <div>
                <Button variant="outlined" 
                    className="buttoninurlinmeetingbg1"
                    onClick={() => {
                        handleClickOpen();
                    }} disabled={datalist.length == 0}
                >
                    {t("ChooseFromExisting")}
                </Button>
                <SimpleDialog open={open} onClose={handleClose} />
                <Tabs
                    value={selectedTab}
                  
                    TabIndicatorProps={{
                        sx: {
                            backgroundColor: "#86BC25",
                            height: 3,
                          
                        },
                    }}
                    textColor="inherit"
                >
                    <Tab label={t("PersonalDetails")} className='fontbuttontab' sx={{ textTransform: "none", }} onClick={handleSubmit(handletab1)} />
                    <Tab label={t("BusinessDetails")} className='fontbuttontab' sx={{ textTransform: "none", }} onClick={handleSubmit(handletab2)} />
                    <Tab label={t("LocationDetails")} className='fontbuttontab' sx={{ textTransform: "none", }} onClick={handleSubmit(handletab3)} disabled={selectedTab === 0} />
                </Tabs>{" "}
                <div className="contentBcard">
                    {selectedTab === 0 && (
                        <>
                            <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                sx={{ marginTop: '10px' }}
                            >
                                <Grid item xs={12} md={6 } >
                           
                                <label className="fontbusinessCardMBG">
                                    {t("FirstName")}*
                                </label>

                                <Controller
                                    name={"FIRST_NAME"}
                                    control={control}
                                    defaultValue={bcarddata?.FIRST_NAME}
                                    rules={{
                                        maxLength: 100,
                                        required: true,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            defaultValue={bcarddata?.FIRST_NAME}
                                            required
                                            name={"FIRST_NAME"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                            value={value}
                                            variant="standard"
                                            placeholder={`${t("EnteryourFirstName")}`}
                                            style={{
                                                textTransform: "none",
                                                width: "100%",
                                            }}
                                            inputProps={{ maxlength: 100 }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />

                                {errors?.FIRST_NAME?.type == "required" && (
                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                )}
                                {errors?.FIRST_NAME?.type == "maxLength" && (
                                    <p className="alertColor">{`${t(
                                        "maximum_30_characters"
                                    )}`}</p>
                                )}
                                {errors?.FIRST_NAME?.type === "pattern" && (
                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                )}
                            </Grid>
                                <Grid item xs={12} md={6}  >
                                <label className="fontbusinessCardMBG">{t("LastName")}*</label>
                                <Controller
                                    name={"LAST_NAME"}
                                    control={control}
                                    defaultValue={bcarddata?.LAST_NAME}
                                    rules={{
                                        maxLength: 100,
                                        required: true,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            required
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                            name={"LAST_NAME"}
                                            value={value}
                                            variant="standard"
                                            placeholder={`${t("EnteryourLastName")}`}
                                            style={{
                                                textTransform: "none",
                                                width: "100%",
                                            }}
                                            inputProps={{ maxlength: 100 }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />
                                {errors?.LAST_NAME?.type === "required" && (
                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                )}
                                {errors?.LAST_NAME?.type === "maxLength" && (
                                    <p className="alertColor">{`${t(
                                        "maximum_30_characters"
                                    )}`}</p>
                                )}
                                {errors?.LAST_NAME?.type === "pattern" && (
                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                )}
                           </Grid>
                                <Grid item xs={12} md={6} >
                                <label className="fontbusinessCardMBG">
                                    {t('EmailId')}*
                                </label>
                                <Controller
                                    name={"EMAIL_ADDRESS"}
                                    control={control}
                                    defaultValue={bcarddata?.EMAIL_ADDRESS}
                                    rules={{
                                        maxLength: 100,
                                        required: true,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            required
                                            name={"EMAIL_ADDRESS"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                            value={value}
                                            variant="standard"
                                            placeholder={`${t("EnteryourEmailAddress")}`}
                                            style={{
                                                textTransform: "none",
                                                width: "100%",
                                            }}
                                            inputProps={{ maxlength: 100, readOnly: true }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />
                                {errors?.EMAIL_ADDRESS?.type === "required" && (
                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                )}
                                {errors?.EMAIL_ADDRESS?.type === "maxLength" && (
                                    <p className="alertColor">{`${t(
                                        "maximum_30_characters"
                                    )}`}</p>
                                )}
                                {errors?.EMAIL_ADDRESS?.type === "pattern" && (
                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                )}
                           </Grid>
                                <Grid item xs={12} md={6} >
                                <label className="fontbusinessCardMBG">

                                    {t("MobileNo")}*

                                   

                                </label>
                                <Controller
                                        name={"RELATED_EMPLOYEE_TELEPHONE_NUMBER"}
                                        defaultValue={bcarddata?.RELATED_EMPLOYEE_TELEPHONE_NUMBER}
                                    control={control}
                                    rules={{
                                        maxLength: 50,
                                        required: true,
                                        min: 0
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            required
                                            name={"RELATED_EMPLOYEE_TELEPHONE_NUMBER"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}

                                            onKeyPress={HandleTelKeyPress}
                                            placeholder={`${t("EnteryourMobilenumber")}`}
                                            value={value}
                                           

                                            variant="standard"

                                            style={{
                                                textTransform: "none",
                                                width: "100%",                                             
                                            }}
                                            inputProps={{
                                                maxlength: 50, inputMode: 'numeric',
                                                pattern: '/^-?\d+(?:\.\d+)?$/g'
                                            }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />
                                {errors?.RELATED_EMPLOYEE_TELEPHONE_NUMBER?.type ===
                                    "required" && (
                                        <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                    )}
                                {errors?.RELATED_EMPLOYEE_TELEPHONE_NUMBER?.type ===
                                    "maxLength" && (
                                        <p className="alertColor">{`${t(
                                            "maximum_30_characters"
                                        )}`}</p>
                                    )}
                                {errors?.RELATED_EMPLOYEE_TELEPHONE_NUMBER?.type ===
                                    "pattern" && (
                                        <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                    )}

                              
                                </Grid>
                                </Grid>
                            
                        </>
                    )}
                    {selectedTab === 1 && (
                        <>
                            <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                sx={{ marginTop: '10px' }}
                            >
                                <Grid item xs={12} md={6}  >
                                <label className="fontbusinessCardMBG">
                                    {t("Organization")}*
                                </label>
                                <Controller
                                    name={"ORGANIZATION_DESCRIPTION"}
                                    control={control}
                                    rules={{
                                        maxLength: 100,
                                        required: true,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            required
                                            name={"ORGANIZATION_DESCRIPTION"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                            value={value}
                                            variant="standard"
                                            placeholder={`${t("Enteryourorganization")}`}
                                            style={{
                                                textTransform: "none",
                                                width: "100%",
                                            }}
                                            inputProps={{ maxlength: 100 }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />
                                {errors?.ORGANIZATION_DESCRIPTION?.type === "required" && (
                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                )}
                                {errors?.ORGANIZATION_DESCRIPTION?.type === "maxLength" && (
                                    <p className="alertColor">{`${t(
                                        "maximum_30_characters"
                                    )}`}</p>
                                )}
                                {errors?.ORGANIZATION_DESCRIPTION?.type === "pattern" && (
                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                )}
                          </Grid>
                                <Grid item xs={12} md={6}  >
                                <label className="fontbusinessCardMBG">{t("JobTitle")}*</label>
                                <Controller
                                    name={"JOB_TITLE_DESCRIPTION"}
                                    control={control}
                                    rules={{
                                        maxLength: 100,
                                        required: true,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            required
                                            name={"JOB_TITLE_DESCRIPTION"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                            value={value}
                                            variant="standard"
                                            placeholder={`${t("EnterYourJobTitle")}`}
                                            style={{
                                                textTransform: "none",
                                                width: "100%",
                                            }}
                                            inputProps={{ maxlength: 100 }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />
                                {errors?.JOB_TITLE_DESCRIPTION?.type === "required" && (
                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                )}
                                {errors?.JOB_TITLE_DESCRIPTION?.type === "maxLength" && (
                                    <p className="alertColor">{`${t(
                                        "maximum_30_characters"
                                    )}`}</p>
                                )}
                                {errors?.JOB_TITLE_DESCRIPTION?.type === "pattern" && (
                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                )}
                           </Grid>
                                <Grid item xs={12} md={6}  >
                                <label className="fontbusinessCardMBG">
                                    {t("Designation")}*
                                </label>
                                <Controller
                                    name={"DESIGNATION_DESCRIPTION"}
                                    control={control}
                                    defaultValue={bcarddata?.DESIGNATION_DESCRIPTION}
                                    rules={{
                                        maxLength: 100,
                                        required: true,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            required
                                            name={"DESIGNATION_DESCRIPTION"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                            value={value}
                                            variant="standard"
                                            placeholder={`${t("EnteryourDesignation")}`}
                                            style={{
                                                textTransform: "none",
                                                width: "100%",
                                            }}
                                            inputProps={{ maxlength: 100 }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />
                                {errors?.DESIGNATION_DESCRIPTION?.type == "required" && (
                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                )}
                                {errors?.DESIGNATION_DESCRIPTION?.type == "maxLength" && (
                                    <p className="alertColor">{`${t(
                                        "maximum_30_characters"
                                    )}`}</p>
                                )}
                                {errors?.DESIGNATION_DESCRIPTION?.type === "pattern" && (
                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                )}
                          </Grid>
                                <Grid item xs={12} md={6} >
                                <label className="fontbusinessCardMBG">

                                    {t('Workphone')}*

                                  

                                </label>
                                <Controller
                                        name={"TELEPHONE_NUMBER"}
                                        defaultValue={bcarddata?.TELEPHONE_NUMBER}
                                    control={control}
                                    rules={{
                                        maxLength: 50,
                                        required: true,
                                        min: 0
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            required
                                            name={"TELEPHONE_NUMBER"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                           
                                            onKeyPress={HandleTelKeyPress}
                                            value={value}
                                            

                                            variant="standard"

                                            placeholder={`${t("EnteryourPhonenumber")}`}
                                            style={{
                                                textTransform: "none",
                                                width: "100%",
                                                
                                            }}
                                            inputProps={{
                                                maxlength: 50, inputMode: 'numeric', min: 0,
                                                pattern: '/^-?\d+(?:\.\d+)?$/g'
                                            }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />

                                {errors?.TELEPHONE_NUMBER?.type === "required" && (
                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                )}
                                {errors?.TELEPHONE_NUMBER?.type === "maxLength" && (
                                    <p className="alertColor">{`${t("maximum_30_characters")}`}</p>
                                )}
                                {errors?.TELEPHONE_NUMBER?.type === "pattern" && (
                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                )}
                                
                          </Grid>
                                </Grid>
                           
                        </>
                    )}

                    {selectedTab === 2 && (
                        <>
                             <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                sx={{ marginTop: '10px' }}
                            >
                                <Grid item xs={12} md={6}  >
                                <label className="fontbusinessCardMBG">
                                    {t("MailingAddress1")}*
                                </label>
                                <Controller
                                    name={"MAILING_ADDRESS_LINE_1"}
                                    control={control}
                                    defaultValue={bcarddata?.MAILING_ADDRESS_LINE_1}
                                    rules={{
                                        maxLength: 100,
                                        required: true,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            required
                                            name={"MAILING_ADDRESS_LINE_1"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                            value={value}
                                            variant="standard"
                                            placeholder={`${t("EnterMailingAddress")}`}
                                            style={{
                                                textTransform: "none",
                                                width: "100%",
                                            }}
                                            inputProps={{ maxlength: 100 }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />

                                {errors?.MAILING_ADDRESS_LINE_1?.type === "required" && (
                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                )}
                                {errors?.MAILING_ADDRESS_LINE_1?.type === "maxLength" && (
                                    <p className="alertColor">{`${t(
                                        "maximum_30_characters"
                                    )}`}</p>
                                )}
                                {errors?.MAILING_ADDRESS_LINE_1?.type === "pattern" && (
                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                )}
                            </Grid>
                                <Grid item xs={12} md={6} >
                                <label className="fontbusinessCardMBG">
                                    {t("MailingAddress2")}
                                </label>
                                <Controller
                                    name={"MAILING_ADDRESS_LINE_2"}
                                    control={control}
                                    defaultValue={bcarddata?.MAILING_ADDRESS_LINE_2}
                                    rules={{
                                        maxLength: 100,
                                        required: false,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                           
                                            name={"MAILING_ADDRESS_LINE_2"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                            value={value}
                                            variant="standard"
                                            placeholder={`${t("EnterMailingAddress")}`}
                                            style={{
                                                textTransform: "none",

                                                width: "100%",
                                            }}
                                            inputProps={{ maxlength: 100 }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />

                                {errors?.MAILING_ADDRESS_LINE_2?.type === "required" && (
                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                )}
                                {errors?.MAILING_ADDRESS_LINE_2?.type === "maxLength" && (
                                    <p className="alertColor">{`${t(
                                        "maximum_30_characters"
                                    )}`}</p>
                                )}
                                {errors?.MAILING_ADDRESS_LINE_2?.type === "pattern" && (
                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                )}
                            </Grid>
                                <Grid item xs={12} md={6} >
                                <label className="fontbusinessCardMBG">{t("City")}*</label>
                                <Controller
                                    name={"MAILING_ADDRESS_CITY_DESCRIPTION"}
                                    control={control}
                                    defaultValue={bcarddata?.MAILING_ADDRESS_CITY_DESCRIPTION}
                                    rules={{
                                        maxLength: 100,
                                        required: true,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            required
                                            name={"MAILING_ADDRESS_CITY_DESCRIPTION"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                            value={value}
                                            variant="standard"
                                            placeholder={`${t("Enteryourcityname")}`}
                                            style={{
                                                textTransform: "none",
                                                width: "100%",
                                            }}
                                            inputProps={{ maxlength: 100 }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />

                                {errors?.MAILING_ADDRESS_CITY_DESCRIPTION?.type ===
                                    "required" && (
                                        <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                    )}
                                {errors?.MAILING_ADDRESS_CITY_DESCRIPTION?.type ===
                                    "maxLength" && (
                                        <p className="alertColor">{`${t(
                                            "maximum_30_characters"
                                        )}`}</p>
                                    )}
                                {errors?.MAILING_ADDRESS_CITY_DESCRIPTION?.type ===
                                    "pattern" && (
                                        <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                    )}
                           </Grid>
                                <Grid item xs={12} md={6} >
                                <label className="fontbusinessCardMBG" >
                                    {t("MailingAddress3")}*
                                </label>
                                <Controller
                                    name={"MAILING_ADDRESS_LINE_3"}
                                    control={control}
                                    defaultValue={bcarddata?.MAILING_ADDRESS_LINE_3}
                                    rules={{
                                        maxLength: 100,
                                        required: true,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            required
                                            name={"MAILING_ADDRESS_LINE_3"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                            value={value}
                                            variant="standard"
                                            style={{
                                                textTransform: "none",
                                                width: "100%", 
                                            }} placeholder={`${t("EnterStateProvince")}`}
                                            inputProps={{ maxlength: 100 }} 
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />
                                {errors?.MAILING_ADDRESS_LINE_3?.type === "required" && (
                                    <p  className="alertColor">{t("Thisfieldisrequired")}</p>
                                )}
                                {errors?.MAILING_ADDRESS_LINE_3?.type === "maxLength" && (
                                    <p  className="alertColor">{`${t(
                                        "maximum_30_characters"
                                    )}`}</p>
                                )}
                                {errors?.MAILING_ADDRESS_LINE_3?.type === "pattern" && (
                                    <p  className="alertColor">{`${t("spacevalidation")}`}</p>
                                )}
                          </Grid>

                                <Grid item xs={12} md={6} >
                                <label className="fontbusinessCardMBG">
                                    {t("PostalCode")}*
                                </label>
                                <Controller
                                    name={"MAILING_ADDRESS_POSTAL_CODE"}
                                    control={control}
                                    defaultValue={bcarddata?.MAILING_ADDRESS_POSTAL_CODE}
                                    rules={{
                                        maxLength: 20,
                                        required: true,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            required
                                            name={"MAILING_ADDRESS_POSTAL_CODE"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                            value={value}
                                            variant="standard"
                                            placeholder={`${t("EnteryourPostalcode")}`}
                                            style={{
                                                textTransform: "none",
                                                width: "100%",
                                            }}
                                            inputProps={{ maxlength: 20 }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />

                                {errors?.MAILING_ADDRESS_POSTAL_CODE?.type === "required" && (
                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                )}
                                {errors?.MAILING_ADDRESS_POSTAL_CODE?.type === "maxLength" && (
                                    <p className="alertColor">{`${t(
                                        "maximum_30_characters"
                                    )}`}</p>
                                )}
                                {errors?.MAILING_ADDRESS_POSTAL_CODE?.type === "pattern" && (
                                    <p className="alertColor">{`${t("spacevalidation")}`}</p>
                                )}
                           </Grid>
                           
                                <Grid item xs={12} md={6}  >
                                <label className="fontbusinessCardMBG">{t("Country")}*</label>
                                <Controller
                                    name={"MAILING_ADDRESS_COUNTRY_DESCRIPTION"}
                                    control={control}
                                    defaultValue={bcarddata?.MAILING_ADDRESS_COUNTRY_DESCRIPTION}
                                    rules={{
                                        maxLength: 100,
                                        required: true,
                                        pattern: /^\s*(\S(.*\S)?)\s*$/,
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            required
                                            name={"MAILING_ADDRESS_COUNTRY_DESCRIPTION"}
                                            onChange={(e) => {
                                                onChange(e), handChange(e);
                                            }}
                                            value={value}
                                            variant="standard"
                                            placeholder={`${t("Enteryourcountryname")}`}
                                            style={{
                                                textTransform: "none",
                                                width: "100%",
                                            }}
                                            inputProps={{ maxlength: 100 }}
                                            error={!!error}
                                            helperText={error ? error.message : ""}
                                        />
                                    )}
                                />

                                {errors?.MAILING_ADDRESS_COUNTRY_DESCRIPTION?.type ===
                                    "required" && (
                                    <p className="alertColor">{t("Thisfieldisrequired")}</p>
                                    )}
                                {errors?.MAILING_ADDRESS_COUNTRY_DESCRIPTION?.type ===
                                    "maxLength" && (
                                    <p  className="alertColor">{`${t(
                                            "maximum_30_characters"
                                        )}`}</p>
                                    )}
                                {errors?.MAILING_ADDRESS_COUNTRY_DESCRIPTION?.type ===
                                    "pattern" && (
                                    <p  className="alertColor">{`${t("spacevalidation")}`}</p>
                                    )}
                        </Grid>
                                </Grid>
                        </>
                    )}
                </div>
                <Button
                    variant="outlined"
                    disabled={selectedTab === 0 ? true : false}
                    onClick={handleBack}
                    sx={{
                        color: "#0076A8",
                        backgroundColor: "white",
                        textTransform: "none",
                    }}
                >
                    {t("PreviousTab")}
     
                </Button>
                {selectedTab !== 2 ? (
                    <Button
                        variant="contained"
                        disabled={selectedTab === 2 ? true : false}
                        onClick={handleSubmit(handleNext)}
                        sx={{
                            color: "white",
                            backgroundColor: "#0076A8",
                            textTransform: "none",
                            left: 20,
                        }}
                    >
                        {t("NextBotton")}
                    </Button>
                ) : (
                        <Button className='fontfamily'
                        variant="contained"
                        onClick={handleSubmit(handlesubmit)}
                        sx={{
                            color: "white",
                            backgroundColor: "#0076A8",
                            textTransform: "none",
                            left: 20,
                        }}
                    >
                            {t("Submit")}
                    </Button>
                )}
          </div>
        </>
    );
}
export default BusinessCardMeetingBg;
