import { Box, Typography, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

function SucessMeetingTemplate({ setOpen, setShow }: Props) {
    const { t, i18n } = useTranslation();

    return (
        <Box className="succesComponentstyle" style={{ width: "1160px"}}>
            <Box className="succesComponentstyle-circleIcon">

                <CheckIcon
                    className="succesComponentstyle-tick"
                    sx={{ fontSize: "70px" }}
                />
            </Box>
            <Typography
                className="succesComponentstyleText fontfamily"
                sx={{ fontSize: "25px", position: "absolute", left: '323px', top: '174px' }}
            >
                {t("MeetingTemplateCreatedSuccesfully")}

                <p
                    style={{
                        color: "black",
                        marginLeft: "-12px",
                        width: "600px",
                        fontSize: "14px",
                    }}
                >
                    {t("MeetingSuccesfullyAlert")}
                </p>
            </Typography>
            <Button
                variant="contained"
                className="fontfamily"
                sx={{
                    width: '120px',
                    height: '50px',
                    background: "#0076A8",
                    color: "#FFFF",
                    position: "absolute",
                    left: "44%",
                    top: "70%",
                    fontSize: '17px', textTransform: "capitalize"
                }}
                onClick={() => {
                    setShow(true), setOpen(false);
                }}
            >
                {t("DoneBotton")}
            </Button>
        </Box>
    );
}

export default SucessMeetingTemplate;