import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import react, { useState, useEffect } from "react";
import {
  CircularProgress,
  Typography,
} from "@mui/material";
import { MeetingTemplateProps } from "../../../Props/MeetingTemplateProps";
import { t } from "i18next";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import {  useForm } from "react-hook-form";
import UrlMeetingBg from "./UrlMeetingBg";
import VcardMeetingbg from "./VcardMeetingbg";
import BusinessCardMeetingBg from "./BusinessCardMeetingBg";
import { qrCodeDisplay } from "../../../Services/Qrcode";
import Parser from "html-react-parser";
import SucestemplateinMeetingBGUser from "./SucestemplateinMeetingBGUser";

type Props = {
  loader: boolean;
  meetingPreview: MeetingTemplateProps;
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
};
function UserMeetingCreation({
  meetingPreview,
  loader,
  value,
  handleChange,
}: Props) {
  const {
    register,
      reset,
      resetField,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      QRplace: "",
    },
  });
  const [qrdata, setQRData] = useState("www.deloitte.com");
  const [qrTarget, setQRTarget] = useState("www.deloitte.com");
  const [contentType, setContentType] = useState(0);
  const Height = 256;
  const shape = "square";
  const level = "L";
  const ForeColor = "#FFFFFF";
  const BackgroundColor = "#000000";
  const DivId = "qrcodeidfortemplate";
  const [positionqr, setPositionQR] = useState("");
  const [imagefinale, setImagefinale] = useState<string>();
  const [sucessmsg, setsucessmsg] = useState(false);
  const [reviewImage, setReveiwImage] = useState<string>();
  const [MeetingID, setMeetingID] = useState<string>();
  useEffect(() => {
      const params = {
          RenderType: "svg",
          level: level,
          DivId: DivId,
          Height: Height,
          TargetUrl: qrTarget,
          ForeColor: ForeColor,
          BackgroundColor: BackgroundColor,
          marginRequired: true,
          Logo: null,
          Shape: shape,
      };
    qrCodeDisplay(params).then(function (response) {
      setQRData(response.data);

    });
  }, [qrTarget, setQRTarget]);
 
  const steps = [
    `${t("QRPlacement")}`,
    `${t("InformationType")}`,
    `${t("QRContent")}`,
  ];
  const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const isStepOptional = (step: number) => {

        return step === 1;

    };

    const isStepSkipped = (step: number) => {

        return skipped.has(step);

    };
    const handleNext = () => {

        let newSkipped = skipped;

        if (isStepSkipped(activeStep)) {

            newSkipped = new Set(newSkipped.values());

            newSkipped.delete(activeStep);

        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        setSkipped(newSkipped);

    };

    const handleBack = () => {

        setActiveStep((prevActiveStep) => prevActiveStep - 1);

    };


    const handleButton = (index : any) => {

        setActiveStep(index)

    }
  const isFirstStep = () => {
    return activeStep === 0;
    };
    useEffect(() => {
        handleconst();   
        reset();

    }, [meetingPreview.MeetingId])

 
    const handleconst = () => {
        resetField("QRplace");
    }

  function ShowQRPlacement() {
    let array: string | undefined = meetingPreview.Position;
    let TL = array?.includes("TL");
    let TR = array?.includes("TR");
    let BL = array?.includes("BL");
    let BR = array?.includes("BR");
    let BC = array?.includes("BC");
    let TC = array?.includes("TC");
    

    return (
      <div className="QRPlacement">
        {loader ? (
                <div className="QRPlacement">
                          <CircularProgress
              value={50}
              sx={{
                position: "relative",

                left: "20%",
                height: "100%",
                marginTop: "20%",
                marginBottom: "20%",
                marginLeft: "30%",
              }}
              disableShrink
                    />
                          </div>
        ) : (
          <form>
            <div className="QrimagepositioninUc">
              <img
                src={meetingPreview.MeetingPreview}
                width="80%"
                height="80%"
              ></img>

              <div
                className={
                  TR
                    ? "displaying Qrsingleposition1"
                    : "hiddening Qrsingleposition1"
                }
              >
                <label className="container">
                  <input
                    value={"TR"}
                    {...register("QRplace", { required: true })}
                    type="radio"
                    hidden={!TR}
                    disabled={!TR}
                    onClick={() => {
                      setPositionQR("TR");
                      setMeetingID(meetingPreview?.MeetingId);
                      setReveiwImage(meetingPreview?.MeetingPreview);
                    }}
                  />
                  <span className="checkmark"></span>
                  
                </label>
              </div>
                       
                           <div
                className={
                  TC
                    ? "displaying Qrsingleposition5"
                    : "hiddening Qrsingleposition5"
                }
              >
                <label className="container">
                  <input
                    value={"TC"}
                    {...register("QRplace", { required: true })}
                    type="radio"
                    
                   
                    onClick={() => {
                      setPositionQR("TC");
                      setMeetingID(meetingPreview?.MeetingId);
                      setReveiwImage(meetingPreview?.MeetingPreview);
                    }}
                  />
                  <span className="checkmark"></span>
                 
                </label>
              </div>
                      
                        <div
                className={
                  BC
                    ? "displaying Qrsingleposition6"
                    : "hiddening Qrsingleposition6"
                }
              >
                <label className="container">
                  <input
                    value={"BC"}
                    {...register("QRplace", { required: true })}
                    type="radio"
                    
                   
                    onClick={() => {
                      setPositionQR("BC");
                      setMeetingID(meetingPreview?.MeetingId);
                      setReveiwImage(meetingPreview?.MeetingPreview);
                    }}
                  />
                  <span className="checkmark"></span>
                 
                </label>
              </div>
       

            <div
              className={
                TL
                  ? "displaying Qrsingleposition2"
                  : "hiddening Qrsingleposition2"
              }
            >
              <label className="container">
                <input
                  type="radio"
                  value="TR"
                  {...register("QRplace", { required: true })}
                  onClick={() => {
                    setPositionQR("TL");
                    setMeetingID(meetingPreview?.MeetingId);
                    setReveiwImage(meetingPreview?.MeetingPreview);
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>

            <div
              className={
                BL
                  ? "displaying Qrsingleposition3"
                  : "hiddening Qrsingleposition3"
              }
            >
              <label className="container">
                <input
                  className={TL ? "displaying" : "hiddening"}
                  {...register("QRplace", { required: true })}
                  value={"BL"}
                  type="radio"
                  onClick={() => {
                    setPositionQR("BL");
                    setMeetingID(meetingPreview?.MeetingId);
                    setReveiwImage(meetingPreview?.MeetingPreview);
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>

            <div
              className={
                BR
                  ? "displaying Qrsingleposition4"
                  : "hiddening Qrsingleposition4"
              }
            >
              {" "}
              <label className="container">
                <input
                  {...register("QRplace", { required: true })}
                  value={"BR"}
                  type="radio"
                  onClick={() => {
                    setPositionQR("BR");
                    setMeetingID(meetingPreview?.MeetingId);
                    setReveiwImage(meetingPreview?.MeetingPreview);565
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>
                        </div>
            {errors.QRplace?.type === "required" && (
              <span className="fontfamily textpose alertcolor">
                {t("SelectinganyoftheallowedPositiontoplaceQRisrequired")}
              </span>
                            )}
                  
          </form>
        )}
      </div>
    );
  }
  function ShowCards() {
    return (
      <div className="showCards">
        <div className="informationtypecss">
          <div className="linkcard">
            <label className="container1">
              <input
                            type="radio"
                            onClick={() => {
                                setContentType(0);
                            }}
                            name="information" checked={ contentType===0?true:false}
              />
              <span className="cardsMB fontfamily fontresize ">
                <InsertLinkIcon className="insertlinkicon" />{" "}
                            <span className="fontresize">{t("UrlUser")}</span>
               
              </span>
            </label>
          </div>
          <div className="linkcard">
            <label className="container1">
              <input
                type="radio"
                onClick={() => {
                  setContentType(1);
                            }}
                            checked={contentType === 1? true : false}
                name="information"
              />
              <span className="cardsMB fontfamily fontresize ">
                <ContactEmergencyIcon className="insertlinkicon" />
                            <span className="fontresize">{t("ContactCard")}</span>                
              </span>
            </label>
          </div>
          <div className="linkcard">
            <label className="container1">
              <input
                type="radio"
                onClick={() => {
                  setContentType(2);
                            }}
                            checked={contentType === 2 ? true : false}
                name="information"
              />
              <span className="cardsMB fontfamily fontresize">
                <ContactMailIcon className="insertlinkicon" />
                            <span className="fontresize">{t("Businesscard")}</span>
              </span>
            </label>
          </div>
        </div>
      </div>
    );
  }

  function ShowContent() {
   
    return (
      <div>
        {contentType == 0 ? (
          <UrlMeetingBg
                    qrdata={qrdata}
                    setQRTarget={setQRTarget}
                    positionqr={positionqr}
                    setsucessmsg={setsucessmsg}
                    setImagefinale={setImagefinale}
                    MeetingID={MeetingID} setActiveStep={setActiveStep }
          />
        ) : null}
        {contentType == 1 ? (
          <>
            <VcardMeetingbg
              setQRTarget={setQRTarget}
              positionqr={positionqr}
                        qrdata={qrdata}
                        setImagefinale={setImagefinale}
              setsucessmsg={setsucessmsg}
            
                        MeetingID={MeetingID} setActiveStep={setActiveStep}
            />
          </>
        ) : null}
        {contentType == 2 ? (
          <BusinessCardMeetingBg
            setQRTarget={setQRTarget}
            positionqr={positionqr}
                    qrdata={qrdata}
                    setImagefinale={setImagefinale}
            setsucessmsg={setsucessmsg}
           
                    MeetingID={MeetingID} setActiveStep={setActiveStep}
          />
        ) : null}
      </div>
    );
  }
  function showStep(activeStep: number) {
    var steps = [];
    switch (activeStep) {
      case 1:
        steps.push(ShowQRPlacement());

        break;

      case 2:
        steps.push(ShowCards());

        break;

      case 3:
        steps.push(ShowContent());

        break;
     
    }

    return steps;
  }

  return (
    <div className="bottomMeetingBG">
      <div className="StepperInMeetingBg">
        {sucessmsg ? (
          <Box className="StepperInBg">
            <SucestemplateinMeetingBGUser
              setActiveStep={setActiveStep}
              setsucessmsg={setsucessmsg}
              value={value}
              handleChange={handleChange}
            />
          </Box>
        ) : (
          <>
            <h5>{t("QRPlacementandContent")}</h5>
            <Box className="StepperInBg">
                              <Stepper className="fontfamily"  activeStep={activeStep}>

                                  {steps.map((label, index) => {

                                      const stepProps: { completed?: boolean } = {};

                                      const labelProps: {

                                          optional?: React.ReactNode;

                                      } = {};

                                      if (isStepOptional(index)) {

                                          labelProps.optional = (

                                              <Typography variant="caption">Optional</Typography>

                                          );

                                      }

                                      if (isStepSkipped(index)) {

                                          stepProps.completed = false;

                                      }

                                      return (

                                          <Step key={label} {...stepProps}>

                                              <StepLabel sx={{ marginLeft: -1.5 }} ><Button onClick={()=> handleButton(index)} sx={{ textTransform: "none", }} color="inherit"> <p className="fontfamily fontmobileee1">{label}</p></Button></StepLabel>

                                          </Step>

                                      );

                                  })}

                              </Stepper>
              <div>
                <React.Fragment>
                 
                    
                    <>{showStep(activeStep + 1)}</>
                  
                </React.Fragment>
              </div>
            </Box>
          </>
        )}
        {sucessmsg ? null : (
          <>
            <Button
              className="fontfamily fontbuttonback"
              variant="contained"
              disabled={isFirstStep()}
              onClick={handleBack}
              sx={{
                mr: 1,
                color: "white",
                backgroundColor: "#0076A8",
                textTransform: "none", marginTop:'12px',
              }}
            >
              {t("BackBotton")}
            </Button>
            {activeStep !== 2 ? (
              <Button
                variant="contained"
                              className="fontfamily fontbuttonback"
                onClick={handleSubmit(handleNext)}
                disabled={loader}
                sx={{
                  mr: 1,
                  color: "white",
                  backgroundColor: "#0076A8",
                    textTransform: "none", marginTop: '12px',
                }}
              >
                {t("NextBotton")}
              </Button>
            ) : null}
          </>
        )}
      </div>

      <div className="reviewQrandMeetingbg">
        <div className="QrtextinBG">
                  {sucessmsg ? <h5 className="fontfamily textcolor">
                      {t("ReviewOfMeetingBackGround")}
                  </h5> : <h5 className="fontfamily textcolor">
                          {t("ReviewYourQR")}
                  </h5>}
        </div>
              {sucessmsg ? (<div className="QrimagepositioninReview">
                  {imagefinale == undefined ? <CircularProgress
                      value={50}
                      sx={{
                          position: "relative",

                          left: "10%",
                          height: "100%",
                          marginTop: "20%",
                          marginBottom: "20%",
                          marginLeft: "30%",
                      }}
                      disableShrink
                  /> : <div>
                      <img src={`data:image/png;base64,${imagefinale}`} width="80%" height="100%"></img>
                  </div>}
              </div>
        ) : (
          <div className="qrcodeimageinbg">
            {" "}
            <svg id={DivId} width={Height} height={Height}>
              {Parser(qrdata)}
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
export default UserMeetingCreation;