import { Button, Grid, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MeetingTemplateProps } from "../../../Props/MeetingTemplateProps";
import { addMeetingTemplate } from "../../../Services/MeetingTemplate";
import { getLoggedInUser } from "../../Authentication/Auth";
import { showWarningMessage } from "../../../Notifications/Message";
type Props = {
    open: boolean
    setTopCenter: React.Dispatch<React.SetStateAction<boolean>>;
    setBottomCenter: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    filebase64: string;
    setFileBase64: Dispatch<SetStateAction<string>>
    setTopLeft: React.Dispatch<React.SetStateAction<boolean>>
    setTopRight: React.Dispatch<React.SetStateAction<boolean>>
    setBottomLeft: React.Dispatch<React.SetStateAction<boolean>>
    setBottomRight: React.Dispatch<React.SetStateAction<boolean>>
    fetchMeetingTemplate: () => void;

}

function AddMeeting({ setOpen, open, filebase64, setFileBase64, setShow, setTopCenter, setBottomCenter, setTopLeft, setTopRight, setBottomLeft, setBottomRight, fetchMeetingTemplate, setIsLoading, }: Props) {
    const CHARACTER_LIMIT = 100;
    const { reset, register, handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            MeetingName: "",
            MeetingIcon: "",
            Qrposition: "BR",
        }
    });

    const Width = 130;
    const [tempName, setTempName] = useState<string>("")
    const [positionBC, setPositionBC] = useState("" as any)
    const [positionTC, setPositionTC] = useState("" as any)
    const [positionTL, setPositionTL] = useState("" as any)
    const [positionBL, setPositionBL] = useState("" as any)
    const [positionTR, setPositionTR] = useState("" as any)
    const [positionBR, setPositionBR] = useState<string>("BR")
    const [resizeIMG, setResizeImg] = useState<string>()
    const [filebool,setFilebool]=useState<boolean>(false)
    const [lenght, setlength] = useState(0)
    const aRef = useRef("" as any);
    const [meetingBG, setMeetingBG] = useState<MeetingTemplateProps>({})
    function convertFile(files: FileList | null) {
        if (files) {
            const fileRef = files[0] || "";
            const fileType: string = fileRef.type || "";
            if (fileRef.type === "image/jpeg") {
                setFilebool(true)
            }
            else {
                setFilebool(false)
            }
          
            const reader = new FileReader();
            reader.readAsBinaryString(fileRef);
            reader.onload = (ev: any) => {
                setFileBase64(`data:${fileType};base64,${btoa(ev.target.result)}`);

            }
            const read = new FileReader();
            read.readAsDataURL(fileRef);
            read.onload = (event: any) => {
                const image = document.createElement("img");
                image.src = event.target.result;
                image.onload = (e: any) => {

                    let canvas = document.createElement("canvas")
                    canvas.width = Width;
                    let ratio = Width / (e.target.width);
                    canvas.height = (e.target.height) * ratio
                    const context = canvas.getContext("2d");
                    context?.drawImage(image, 0, 0, canvas.width, canvas.height)
                    let new_url_data = context?.canvas.toDataURL("image/png")
                    setResizeImg(new_url_data)
                    const new_image = document.createElement("img");
                }
                
            };
        }
    }
    const handleChangeTL = (e: any) => {
        if (e.target.checked) {
            setPositionTL(e.target.value)
            setTopLeft(true)

        }
        else {

            setPositionTL(null)
            setTopLeft(false)
        }
    }

    const handleChangeBL = (e: any) => {
        if (e.target.checked) {
            setPositionBL(e.target.value)
            setBottomLeft(true)

        }
        else {
            setPositionBL(null)
            setBottomLeft(false)

        }

    }
    const handleChangeTR = (e: any) => {
        if ((e.target.checked)) {
            setPositionTR(e.target.value)
            setTopRight(true)

        }
        else {
            setPositionTR(null)
            setTopRight(false)

        }

    }
    const handleChangeTC = (e:any) => {
        if ((e.target.checked)) {
            setPositionTC(e.target.value)
            setTopCenter(true)
        }
        else {
            setPositionTC(null)
            setTopCenter(false)
        }
    }
    const handleChangeBC = (e:any) => {
        if ((e.target.checked)) {
            setPositionBC(e.target.value)
            setBottomCenter(true)
        }
        else {
            setPositionBC(null)
            setBottomCenter(false)
        }
    }
    const handleChangeBR = (e: any) => {
        if (e.target.checked) {
            setPositionBR(e.target.value)
            setBottomRight(true)
           

        }
        else {
            setPositionBR("")
            setBottomRight(false)

        }

    }

    const CreateMeetingTemplate = async (data: any) => {
        setIsLoading(true)
        meetingBG.MeetingName = tempName;
        meetingBG.MeetingIcon = resizeIMG;
        meetingBG.Position = `${positionTL},${positionTR},${positionBL},${positionBR}`
        meetingBG.MeetingPreview = filebase64;
        meetingBG.CreatedBy = getLoggedInUser();
        meetingBG.ActiveStatus = 1;
       

        addMeetingTemplate(meetingBG).then(function (response) {
           
           
            setIsLoading(false)
            setShow(false)
            fetchMeetingTemplate()
            setFileBase64("")
            handleReset()

        }).catch(function (response) {
    
            
          
            if (response.response.status === 409) {
                showWarningMessage(
                    `${t("Templatewithsimilarparametersalreadyexists")}`
                );

            }
               
          
            setIsLoading(false);
           
        })
    }

    const handleReset = () => {
        reset(),
        setFileBase64("");
        setPositionTL(null)
        setTopLeft(false)
        setPositionBL(null)
        setBottomLeft(false)
        setPositionTR(null)
        setPositionBC(null)
        setPositionTC(null)
        setTopRight(false)
        setBottomCenter(false)
        setTopCenter(false)
        setTempName("")
        aRef.current.value = null;


    }

    useEffect(() => {
        handleReset();
        setPositionBR("BR"),setBottomRight(true)
    }, [open ])
    useEffect(() => {
        handleReset();
        setPositionBR("BR"),setBottomRight(true)
    }, [setOpen])
  
    return (<>

        <div className="addTemplate-right-Side" style={{ top: "-110px", width: "545px",height:"470px", left: "15px" }}>


            <div className="meetingFieldCss"    >
                <Typography className="fontfamily" sx={{ color: "#0076A8", fontSize: "18px" }}>
                    {t("Meetingbackgroundtemplatedetails")}
                </Typography>
                <Grid container spacing={3} columnGap={1}>
                    <Grid item xs={5}>
                        <label className="textForMeetingBGround" style={{ fontSize: "14px", left: "15px", top: "30px" }}>{t('TemplateName')}*</label>

                        <Controller
                            name="MeetingName"
                            control={control}
                            rules={{
                                maxLength: 100,
                                minLength: 3,
                                required: true,
                                pattern: /^\s*(\S(.*\S)?)\s*$/
                            }}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },

                            }) => (
                                <TextField
                                    size="small"
                                    required
                                    autoFocus
                                    placeholder={`${t("placeHolderTemplateName")}`}
                                    name="MeetingName"
                                    variant="outlined"
                                    style={{ top: "33px", left: '10px' }}
                                    inputProps={{ maxlength: CHARACTER_LIMIT }}
                                    fullWidth

                                    value={value}
                                    onChange={(e) => {
                                        onChange(e), setTempName(e.target.value);
                                    }}
                                    error={!!error}
                                    helperText={error ? error.message : `${tempName?.length}/${CHARACTER_LIMIT}`}

                                />
                            )}
                        />
                                {errors?.MeetingName?.type === "required" && (
                            <p className="alertColor error-msg-meetingname-inmeetignbg">{t("Thisfieldisrequired")}</p>
                        )}
                                {errors?.MeetingName?.type === "maxLength" && (
                            <p className="alertColor error-msg-meetingname-inmeetignbg">{`${t("maximum_30_characters")}`}</p>
                        )}
                                {errors?.MeetingName?.type === "pattern" && (
                            <p className="alertColor error-msg-meetingname-inmeetignbg">{`${t("spacevalidation")}`}</p>
                        )}
                        {errors?.MeetingName?.type === "minLength" && (
                            <p className="alertColor error-msg-meetingname-inmeetignbg">{`${t("minmum_3_characters")}`}</p>
                        )}
                    </Grid>
                    <Grid item xs={6}>

                        <label className="textForMeetingBGround" style={{ fontSize: "14px", left: "300px", top: "30px" }}>{t('UploadImage')}*</label>
                        <Controller
                            name="MeetingIcon"
                            control={control}
                            rules={{
                                required: true,
                               
                                validate: {
                                    lessthan10mb: (value) => {
                                        if (lenght > 10000000) return `${t("Themaximumsizeforimageuploadis10MB")}`;
                                        if (!filebool) return `${t("Youcanuploadonlyjpegextensionfile")}`;
                                    }
                                }
                            }
                            }
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (<TextField
                                inputRef={aRef}
                                name="MeetingIcon"
                                type="file"
                                size="small"
                                value={value}
                                inputProps={{
                                    accept:".jpg" 
                                }}
                                style={{ top: "35px", left: '50px' }}
                                onChange={(e: any) => { onChange(e), convertFile(e.target.files), setlength(e.target.files[0].size) }}
                            />)} />
                        {errors?.MeetingIcon?.type === "required" && (<p className="alertColor error-msg-upload-inmeetignbg">{t("Thisfieldisrequired")}</p>)}
                        {errors.MeetingIcon && <span className="alertColor error-msg-upload-inmeetignbg">{errors.MeetingIcon.message}</span>}
                       

                    </Grid>
                </Grid>
                <Typography
                    className="fontfamily"
                    sx={{ color: "#0076A8", fontSize: "17px", paddingTop: "65px" }}
                >
                    {t("QRPosition")}
                </Typography>
                <Typography>

                    <p style={{ fontSize: "13px", width: "210px" }}>{t("QRallowedpositionsText")}</p>
                </Typography>
                <div className="positionofQrcodeContainer">
                    <form>
                    <Grid container spacing={1}>


                        <Grid item xs={6}>
                            <input type="checkbox" {...register("Qrposition")} style={{ width: '120px', height:"20px", visibility: "hidden"  }} id="position1" value="TL" onClick={(e) => handleChangeTL(e)} checked={positionTL ? true : false} ></input>
                            <label className="positionOfButtons" htmlFor="position1">{t("TopLeft")}</label>
                            </Grid>
                           
                        <Grid item xs={6}>
                                <input type="checkbox" {...register("Qrposition")} style={{ width: '120px', visibility: "hidden", height: "20px" }} id="position2" value="TR" onClick={(e) => handleChangeTR(e)} checked={positionTR ? true : false} ></input>
                            <label className="positionOfButtons" htmlFor="position2">{t("TopRight")}</label>

                        </Grid>
                        <Grid item xs={6}>
                                <input type="checkbox" {...register("Qrposition")} style={{ width: '120px', visibility: "hidden", height: "20px" }} id="position3" value="BL" onClick={(e) => handleChangeBL(e)} checked={positionBL ? true : false}></input>
                            <label  className="positionOfButtons boxstyle" htmlFor="position3">{t("BottomLeft")}</label>

                            </Grid>
                            
                           
                        <Grid item xs={6}>
                                <input required type="checkbox" {...register("Qrposition", { required: true })} style={{ width: '120px', visibility: "hidden", height: "20px" }} id="position4" value="BR" onClick={(e) => handleChangeBR(e)} checked={positionBR ? true : false} ></input>
                            <label className="positionOfButtons" htmlFor="position4">{t("BottomRight")}</label>
                            </Grid>
                    
                          

                        {errors.Qrposition?.type === "required" && (
                                <span className="fontfamily textposition  alertcolor">
                                    {t("SelectinganyoftheallowedPositiontoplaceQRisrequired")}
                            </span>
                        )}
                    </Grid>
                    </form>
                </div>
                <div className="buttonInmeeting" style={{ left: 5, width: '390px' }}>
                    <Button
                        className="fontfamily"
                        variant="contained"

                        onClick={() => { handleReset() }}
                        sx={{
                            mr: 1,
                            color: "white",
                            backgroundColor: "#0076A8",
                            textTransform: "none",
                        }}
                    >
                        {t("Reset")}
                    </Button>
                    <Button

                        className="fontfamily"
                        variant="contained"
                        onClick={handleSubmit(CreateMeetingTemplate)}
                        sx={{
                            mr: 1,
                            color: "white",
                            backgroundColor: "#0076A8",
                            textTransform: "none",
                        }}
                    >
                        {t("SaveBotton")}
                    </Button>
                    <Button
                        className="fontfamily"
                        variant="contained"

                        onClick={() => { setOpen(false), setFileBase64(""), handleReset(), setPositionBR("BR"), setBottomRight(true) }}
                        sx={{
                            mr: 1,
                            color: "white",
                            backgroundColor: "#0076A8",
                            textTransform: "none",
                        }}
                    >
                        {t("Close")}
                    </Button>
                </div>
            </div>
        </div>


    </>)
}
export default AddMeeting;

