import { Box, Typography } from "@mui/material";
import StepperBar from "../Headers/StepperBar";
import TemplateList from "../Template/TemplateList";
import { getQrTemplateList } from "../../Services/QrTemplate";
import { TemplateProps } from "../../Props/TemplateProps";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { showErrorMessage } from "../../Notifications/Message";

function QrCodeTemplates() {
    const [items, setItems] = useState<TemplateProps[]>([]);
    const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();

    useEffect(() => fetchTemplates(), []);

    function fetchTemplates() {
       
    getQrTemplateList()
      .then(function (response: any) {       
          setItems(response.data);         
      })
        .catch(function (error: any) {
           
        showErrorMessage(t("Failedtogettemplates"));
      });
  }

  return (
    <>
      <Box>
        <h3>{t("QRTemplates")}</h3>
        <p className="QrcodeTemplateTextp">{t("detailsshownbelow")}</p>
              <StepperBar fetchTemplates={fetchTemplates} setItems={setItems} />
              <TemplateList items={items} fetchTemplate={fetchTemplates} loading={isLoading} />
      </Box>
    </>
  );
}
export default QrCodeTemplates;
