import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Button, CircularProgress, Dialog, DialogTitle, TextField } from "@mui/material";
import { t } from "i18next";
import react, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DefaultURLProps } from "../../../Props/Url";
import { getStatusWorkFlow } from "../../Template/TemplateWorkFlow";
import { GetTemplateGridDateFormat } from "../../../Utils/DateDisplay";
import moment from "moment";
import { QRCodeProps } from "../../../Props/QRCodeProps";
import { getLoggedInUser } from "../../Authentication/Auth";
import { getQrCodeListByUser } from "../../../Services/Qrcode";
import { getUrlQrCodeListById } from "../../../Services/Url";
import CloseIcon from "@mui/icons-material/Close";
import { MeetingTemplateProps } from "../../../Props/MeetingTemplateProps";
import { addMeetingBGdetails, getMeetingBackgDetailsById } from "../../../Services/MeetingTemplate";
import { showErrorMessage, showWarningMessage } from "../../../Notifications/Message";

type Props = {
    setQRTarget: React.Dispatch<React.SetStateAction<string>>;
    setImagefinale: React.Dispatch<React.SetStateAction<string|undefined>>;
    positionqr: string;
    qrdata: string;
    setsucessmsg: React.Dispatch<React.SetStateAction<boolean>>;
    MeetingID: string | undefined;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>; 
};

function UrlMeetingBg({
    setQRTarget,
    setImagefinale,
    MeetingID,
    positionqr,
    qrdata,
    setsucessmsg, setActiveStep
}: Props) {
  const {
    register,
    setValue,
    reset, control,
    handleSubmit,
    formState: { errors },
  } = useForm<DefaultURLProps>();
  const _UserName = getLoggedInUser();
  const handleUrlchanges = (data: any) => {
    setQRTarget(data.Url);
    let a = qrdata.split('href="');
    let b = a.at(1)?.trimEnd().split("/> </svg>").at(0);
    let c = b?.split(" ").at(1);
    const params = {
        MeetingId: MeetingID,
      Position: positionqr,
      MeetingQRImage: c,
      CreatedBy: getLoggedInUser(),
    };

    addMeetingBGdetails(params)
      .then(function (response) {
          setsucessmsg(true);
         
          getMeetingBackgDetailsById(response.data).then(function (response) {
             
              setImagefinale(response.data.MeetingBgWithQr)
          })


         
      })
        .catch(function (response: any) {
           

            if (response.response?.status === 500) {

                showErrorMessage(
                    `${t("SelectinganyoftheallowedPositiontoplaceQRisrequired")}`
                );
                return (
                    setActiveStep((prevActiveStep) => prevActiveStep - 2)
                )

            }

           
          if (response.response.status === 409) {
              showWarningMessage(
                  `${t("MeetingBackgroundwithsimilarparametersalreadyexists")}`
              );
          }
         
        });


     

     

   
  };
    const [loads,setloads]=useState(false)
  type SimpleDialogProps = {
    open: boolean;
    onClose: () => void;
  };
  const [items, setItems] = useState<QRCodeProps[]>([]);
  const [choose, setChoose] = useState(false);
  const [urlDetails, setURLDetails] = useState<DefaultURLProps>();
  const getDataFromlist = (QRCodeId: string) => {
    getUrlQrCodeListById(QRCodeId)
      .then(function (response: any) {
        setURLDetails(response.data);
        setValue("Title", `${response.data.Title}`);
          setValue("Url", `${response.data.Url}`);
          setQRTarget(response.data.Url)
      })
      .catch(function (error: any) {});
  };

    useEffect(() => {
        setloads(true)
    getQrCodeListByUser(_UserName)
      .then(function (response: any) {
        {
          response.data.map((item: any) => {
              if (item.QRType === "URL") {
                  if (item.ActiveStatus !== 5) {
                      items.push(item);
                     
                  }
             
              }
              setloads(false)
          });
        }
      })
      .catch(function (error: any) {});
  }, [_UserName]);
  function SimpleDialog(props: SimpleDialogProps) {
    const { onClose, open } = props;

    const handleClose = () => {
      onClose();
    };

    const columns: GridColDef[] = [
      {
        field: "Title",
        headerName: `${t("NameofQRcode")}`,
        width: 180,
        flex: 1,
        headerAlign: "left",
        align: "left",
      },

      {
        field: "QRType",
        headerName: `${t("TypesofQRCode")}`,
        width: 180,
        flex: 1,
        headerAlign: "left",
        align: "left",
      },

      {
        field: "CreatedDate",
        headerName: `${t("Processedon")}`,
        type: "date",
        width: 160,
        headerAlign: "left",
        align: "left",
        flex: 1,
        valueFormatter: (params) =>
          moment(params?.value).format(GetTemplateGridDateFormat()),
      },

      {
        field: "ExpiryDate",
        headerName: `${t("Activetill")}`,
        type: "date",
        width: 120,
        headerAlign: "left",
        align: "left",
        flex: 1,
        valueFormatter: (params) =>
          params?.value != null
            ? moment(params?.value).format(GetTemplateGridDateFormat())
            : "",
        renderCell: (cellVallues: any) => {
          if (
            cellVallues.row.QRType == "Vcard" ||
            cellVallues.row.QRType == "Business Card"
          ) {
            return "N/A";
          }
        },
      },

      {
        field: "ActiveStatus",
        headerName: `${t("ActiveStatus")}`,
        width: 100,
        headerAlign: "left",
        align: "left",
        flex: 1,
        valueGetter: (params) => getStatusWorkFlow(params.value),
      },

      {
        field: "",
        
        width: 180,
        sortable: false,
        filterable: false,
        flex: 1,
        renderCell: (row: any) => {
          return (
              <Button sx={{textTransform:"none"}} className='buttonSize'
              onClick={() => {
                getDataFromlist(row.row.QRCodeId), handleClose();
              }}
            >
                  {t("Select") }
            </Button>
          );
        },
      },
    ];
    

    return (
      <Dialog fullWidth={true} onClose={handleClose} open={open}>
        <DialogTitle>
                {t("ChooseFromExistingQR") }
          <CloseIcon onClick={handleClose} className="closeiconinMBG" />
            </DialogTitle>

            <div style={{ height: 400, width: "100%" }}>
                {loads ?
                    <CircularProgress
                        value={50}
                        sx={{
                            position: "relative",

                            left: "20%",
                            height: "100%",
                            marginTop: "20%",
                            marginBottom: "20%",
                            marginLeft: "30%",
                        }}
                        disableShrink
                    /> :
                    <DataGrid
                        getRowId={(items) => items.QRCodeId}
                        rows={items}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 15]}
                    />}
        </div>
      </Dialog>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  return (
    <>
          <Button variant="outlined"
              className="buttoninurlinmeetingbg"
              onClick={() => {
                  handleClickOpen(), reset();
              }} disabled={items.length ==0}
      >
              {t("ChooseFromExisting")}
      </Button>
      <div className="ContentView">
        <SimpleDialog open={open} onClose={handleClose} />
              <div className="boxfortitle">
                  <label className="labelinmeetingUrl">{t("TitleofURL")}*</label>
                  <Controller
                      name="Title"
                      control={control}
                      rules={{
                          required: true,
                          maxLength: 100,
                          minLength: 3,
                          pattern: /^\s*(\S(.*\S)?)\s*$/,
                      }}
                      defaultValue={urlDetails?.Title}
                      render={({
                          field: { onChange, value },
                          fieldState: { error },
                      }) => (
                          <TextField
                              className="urlinmeeting"
                              required
                              placeholder={`${t("Enteryourtitlehere")}`}
                              name="Title"
                              sx={{ width: "100%", height: "67px", marginLeft: "-35px" }}
                              variant="standard"
                              fullWidth

                              inputProps={{ maxlength: 100 }}
                              value={value}

                              onChange={(e) => {
                                  onChange(e)
                              }}

                              error={!!error}
                              helperText={
                                  error?.type == "required"
                                      ? `${t("Thisfieldisrequired")}`
                                      : ''
                              }
                          />
                      )}
                  />

                  {errors.Title?.type == "maxLength" && (
                      <p className="alertColor">
                          {" "}
                          {t("maxLengthrequired")}
                      </p>
                  )}
                  {errors.Title?.type == "minLength" && (
                      <p className="alertColor" style={{marginTop:'-25px', marginLeft:'-30px'} }>
                          {t("minLengthrequired")}
                      </p>
                  )}
                  {errors?.Title?.type === "pattern" && (
                      <p className="alertColor" style={{ marginTop: '-25px', marginLeft: '-30px' }}>{`${t(
                          "spacevalidation"
                      )}`}</p>
                  )}
              </div>
              &nbsp;
              <div className="boxfortitle">
                  <label className="labelinmeetingUrl">{t("PasteUrl/Linkhere")}*</label>
                  <Controller
                      name="Url"
                      control={control}
                      rules={{
                          required: true,
                          maxLength: 200,
                          minLength: 10,
                          pattern: /^\s*(\S(.*\S)?)\s*$/,
                      }}
                      defaultValue={urlDetails?.Url}
                      render={({
                          field: { onChange, value, onBlur },
                          fieldState: { error },
                      }) => (
                          <TextField
                              className="urlinmeeting"
                              required
                              placeholder={`${t("YourLinkHere")}`}
                              name="Url"
                              sx={{ width: "100%", marginLeft:'-35px'  }}
                              variant="standard"
                              fullWidth
                              inputProps={{ maxlength: 200 }}

                              value={value}

                              onChange={(e) => {
                                  onChange(e), setQRTarget(e.target.value);
                              }}
                              error={!!error}
                              helperText={
                                  error?.type == "required"
                                      ? `${t("Thisfieldisrequired")}`
                                      : ''
                              }
                          />
                      )}
                  />
                  {errors.Url?.type == "maxLength" && (
                      <p
                          style={{
                              position: "relative",
                              top: "1px",
                              width: "300px",
                              left: "3px",
                          }}
                          className="alertColor"
                      >
                          {" "}
                          {t("maxLengthrequiredUrllink")}
                      </p>
                  )}
                  {errors.Url?.type == "minLength" && (
                      <p className="alertColor" style={{ marginTop: '-10px', marginLeft: '-30px' }}>
                          {t("minLengthrequiredUrllink")}
                      </p>
                  )}
                  {errors?.Url?.type === "pattern" && (
                      <p className="alertColor" style={{ marginTop: '-10px', marginLeft: '-30px' }}>
                          {`${t("spacevalidation")}`}
                      </p>
                  )}

              </div>

          </div>
      <Button
        variant="contained"
              className="submitinMBG fontbutton fontfamily" sx={{
                  color: "white",
                  backgroundColor: "#0076A8",
} }
        onClick={handleSubmit(handleUrlchanges)}
      >
              {t("Submit")}
      </Button>
    </>
  );
}
export default UrlMeetingBg;
